// utils/replicateEquipo.js (por ejemplo)
import { collection, doc, getDocs, query, where, updateDoc } from "firebase/firestore";
import { db } from "../firebase";

export async function replicateEquipoToAll(updatedEquipo, accessCode) {
  if (!accessCode) return; // si no existe accessCode, no hacemos nada

  try {
    // Tomamos todos los usuarios con ese accessCode
    const usersRef = collection(db, "users");
    const q = query(usersRef, where("accessCode", "==", accessCode));
    const snapshot = await getDocs(q);

    // Actualizamos en cada uno la misma lista equipo
    for (const docSnap of snapshot.docs) {
      const userDocRef = doc(db, "users", docSnap.id);
      await updateDoc(userDocRef, {
        equipo: updatedEquipo,
      });
    }
    console.log("Replicado equipo a todos los usuarios con accessCode =", accessCode);
  } catch (error) {
    console.error("Error replicando equipo:", error);
  }
}
