import React, { useState } from 'react';
import { getAuth } from 'firebase/auth';

function TiendaNubeConnectForm({ onClose }) {
  const [storeId, setStoreId] = useState('');
  const [accessToken, setAccessToken] = useState('');

  const handleConnect = async () => {
    try {
      // 1) Obtener ID token de Firebase Auth (si estás logueado)
      const auth = getAuth(); 
      const currentUser = auth.currentUser;
      if (!currentUser) {
        alert('No estás autenticado.');
        return;
      }
      const token = await currentUser.getIdToken(/* forceRefresh */ true);

      // 2) Llamas a tu servidor para guardar estos datos en Firestore
      const response = await fetch('https://backend.thethingapp.com/api/tiendanube-connect', {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Aquí envías el Bearer
        },
        body: JSON.stringify({ storeId, accessToken }),
      });
      const data = await response.json();
      if (data.success) {
        alert('¡TiendaNube conectada con éxito!');
        onClose();
      } else {
        alert('Error al conectar tienda: ' + data.error);
      }
    } catch (error) {
      console.error('Error al conectar TiendaNube:', error);
      alert('Hubo un error.');
    }
  };

  return (
    <div style={{ padding: '1em' }}>
      <h3>Conectar Mi Tienda Nube</h3>
      <label>Store ID:</label>
      <input 
        type="text" 
        value={storeId} 
        onChange={(e) => setStoreId(e.target.value)} 
      />
      <label>X-Access-Token:</label>
      <input 
        type="text" 
        value={accessToken} 
        onChange={(e) => setAccessToken(e.target.value)} 
      />
      <button onClick={handleConnect}>Conectar</button>
    </div>
  );
}

export default TiendaNubeConnectForm;
