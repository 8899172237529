import React, { useEffect, useState } from 'react';
import './Profile.css';
import { useAuth } from '../context/AuthContext';
import {
  collection,
  doc,
  onSnapshot,
  getDocs,
  query,
  where,
  setDoc,
  updateDoc
} from 'firebase/firestore';
import { db } from '../firebase';
import { auth } from '../firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { replicateEquipoToAll } from "../utils/replicateEquipo.js";

const Profile = () => {
  const { currentUser } = useAuth();
  
  // Estados
  const [userData, setUserData] = useState(null);
  const [isEditingName, setIsEditingName] = useState(false);
  const [newName, setNewName] = useState('');

  // Conversaciones
  const [totalThreadsUsed, setTotalThreadsUsed] = useState(0);
  const [purchasedThreadsAvailable, setPurchasedThreadsAvailable] = useState(0);
  const [remainingFreeConversations, setRemainingFreeConversations] = useState(200);

  // Equipo
  const [newMemberFirstName, setNewMemberFirstName] = useState('');
  const [newMemberLastName, setNewMemberLastName] = useState('');
  const [newMemberRole, setNewMemberRole] = useState('');
  const [newMemberEmail, setNewMemberEmail] = useState('');
  const [isAddingFictitiousMember, setIsAddingFictitiousMember] = useState(false);
  
  const [fictitiousTeamMembers, setFictitiousTeamMembers] = useState([]);
  const [accessCodeTeamMembers, setAccessCodeTeamMembers] = useState([]);

  // Justo donde tienes tus otros useState:
const [daysLeftSub, setDaysLeftSub] = useState(0);


  // Cargando
  const [isLoading, setIsLoading] = useState(true);

  // ============================
  // Función para formatear fecha
  // ============================
  const formatAccessExpiresAt = (timestamp) => {
    if (!timestamp) return 'No definido';

    // Convertir a objeto Date
    const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);

    // Forzar la zona horaria de Argentina
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
      timeZone: 'America/Argentina/Buenos_Aires',
      timeZoneName: 'short', // normal: “GMT-3” o “ART”
    };
    let formatted = new Intl.DateTimeFormat('es-AR', options).format(date);
    // Reemplazar “GMT-3” o “ART” por “UTC-3”
    formatted = formatted.replace('GMT-3', 'UTC-3').replace('ART', 'UTC-3');
    return formatted;
  };

  // ============================
  // useEffect con onSnapshot
  // ============================
  useEffect(() => {
    if (!currentUser) {
      setIsLoading(false);
      return;
    }

    const userDocRef = doc(db, 'users', currentUser.uid);

    // Suscribirse con onSnapshot
    const unsubscribe = onSnapshot(
      userDocRef,
      async (docSnap) => {
        if (!docSnap.exists()) {
          // Crear doc si no existe
          const displayName = auth.currentUser.displayName || 'Usuario de the thing';
          const newUserData = {
            name: displayName,
            email: currentUser.email,
            API_KEY: '',
            totalThreads: 0,
            purchasedThreads: 0,
            equipo: [],
          };
          await setDoc(userDocRef, newUserData);
          setUserData(newUserData);
          setIsLoading(false);
          return;
        }

        // Leer datos
        const fetchedUserData = docSnap.data();

        // Asegurar name
        if (!fetchedUserData.name) {
          const displayName = auth.currentUser.displayName || 'Usuario de the thing';
          await updateDoc(userDocRef, { name: displayName });
          fetchedUserData.name = displayName;
        }

        // Calcular días restantes de la suscripción
if (fetchedUserData.accessExpiresAt) {
  // accessExpiresAt es un Timestamp => convertirlo a Date
  const expiresDate = fetchedUserData.accessExpiresAt.toDate();

  // calcular la diferencia en ms
  const now = new Date();
  const diffMs = expiresDate - now;

  // Pasar a días, redondeando hacia arriba
  const diffDays = Math.ceil(diffMs / (1000 * 60 * 60 * 24));

  // Si ya expiró o faltan horas, forzamos 0
  setDaysLeftSub(diffDays > 0 ? diffDays : 0);
} else {
  // Si no hay fecha, asumimos 0
  setDaysLeftSub(0);
}

        // Estados de conversaciones
        if (
          typeof fetchedUserData.totalThreads === 'number' &&
          typeof fetchedUserData.purchasedThreads === 'number'
        ) {
          const used = fetchedUserData.totalThreads;
          const purchased = fetchedUserData.purchasedThreads;
          setTotalThreadsUsed(used);
          setPurchasedThreadsAvailable(purchased);
          setRemainingFreeConversations(Math.max(0, 200 - used));
        }

        // Asegurar que 'equipo' esté definido
        if (!fetchedUserData.equipo) {
          await updateDoc(userDocRef, { equipo: [] });
          fetchedUserData.equipo = [];
        }

        // Guardar en state
        setUserData(fetchedUserData);

        // Manejar miembros ficticios
        if (fetchedUserData.equipo && fetchedUserData.equipo.length > 0) {
          setFictitiousTeamMembers(fetchedUserData.equipo);
        } else {
          setFictitiousTeamMembers([]);
        }

        // Miembros Reales (AccessCode)
        const accessCode = fetchedUserData.accessCode;
        if (accessCode) {
          try {
            const usersRef = collection(db, 'users');
            const q = query(usersRef, where('accessCode', '==', accessCode));
            const usersSnapshot = await getDocs(q);

            const teamMembersData = usersSnapshot.docs
              .filter((docSnapshot) => docSnapshot.id !== currentUser.uid)
              .map((docSnapshot) => ({
                id: docSnapshot.id,
                ...docSnapshot.data(),
              }));

            setAccessCodeTeamMembers(teamMembersData);
          } catch (error) {
            console.error('Error al obtener miembros MKT:', error);
            setAccessCodeTeamMembers([]);
          }
        } else {
          setAccessCodeTeamMembers([]);
        }

        setIsLoading(false);
      },
      (error) => {
        console.error('onSnapshot error en Profile:', error);
        setIsLoading(false);
      }
    );

    return () => unsubscribe(); 
  }, [currentUser]);

  // =====================================
  // Añadir miembro ficticio
  // =====================================
// Al añadir miembro:
const handleAddFictitiousMember = async () => {
  // Validaciones:
  if (!newMemberFirstName || !newMemberLastName || !newMemberRole) {
    alert('Por favor, completa los campos (Nombre, Apellido y Cargo).');
    return;
  }

  try {
    if (!currentUser?.uid) return; // <--- ya no bloqueamos si no hay accessCode

    const newMember = {
      nombre: newMemberFirstName,
      apellido: newMemberLastName,
      cargo: newMemberRole,
      email: newMemberEmail || '',
    };
    const updatedEquipo = [...fictitiousTeamMembers, newMember];

    // 1) Siempre actualizar en Firestore SOLO en el doc del usuario actual:
    const userDocRef = doc(db, 'users', currentUser.uid);
    await updateDoc(userDocRef, { equipo: updatedEquipo });

    // 2) Si existe un accessCode, replicar al resto de usuarios:
    if (userData?.accessCode) {
      await replicateEquipoToAll(updatedEquipo, userData.accessCode);
      alert('Miembro ficticio añadido con éxito. Se replicará en todo el equipo.');
    } else {
      alert('Miembro ficticio añadido con éxito (solo en tu cuenta).');
    }

    // Actualizamos estado local
    setFictitiousTeamMembers(updatedEquipo);
    setNewMemberFirstName('');
    setNewMemberLastName('');
    setNewMemberRole('');
    setNewMemberEmail('');
    setIsAddingFictitiousMember(false);

  } catch (error) {
    console.error('Error al añadir miembro ficticio:', error);
    alert('Error al añadir miembro ficticio.');
  }
};



  // =====================================
  // Eliminar miembro ficticio
  // =====================================
  // Al eliminar miembro:
const handleDeleteFictitiousMember = async (index) => {
  const confirmDelete = window.confirm('¿Estás seguro de que quieres eliminar este miembro?');
  if (!confirmDelete) return;

  const updatedEquipo = [...fictitiousTeamMembers];
  updatedEquipo.splice(index, 1);

  try {
    if (!currentUser?.uid) return;

    // 1) Siempre actualizar localmente
    const userDocRef = doc(db, 'users', currentUser.uid);
    await updateDoc(userDocRef, { equipo: updatedEquipo });

    // 2) Replicar sólo si hay accessCode
    if (userData?.accessCode) {
      await replicateEquipoToAll(updatedEquipo, userData.accessCode);
      alert('Miembro eliminado con éxito. Se replicará en todo el equipo.');
    } else {
      alert('Miembro eliminado con éxito (solo en tu cuenta).');
    }

    setFictitiousTeamMembers(updatedEquipo);

  } catch (error) {
    console.error('Error al eliminar miembro:', error);
    alert('Error al eliminar miembro.');
  }
};


  // Iniciales
  const getInitials = (name) => {
    if (!name) return '';
    const parts = name.trim().split(' ');
    const initials = parts.map((p) => p.charAt(0).toUpperCase()).join('');
    return initials.substring(0, 2);
  };

  // Editar nombre
  const handleEditName = () => {
    if (userData?.name) {
      setNewName(userData.name);
    } else {
      setNewName('');
    }
    setIsEditingName(true);
  };

  const handleSaveName = async () => {
    try {
      if (!currentUser?.uid) return;
      const userDocRef = doc(db, 'users', currentUser.uid);
      await updateDoc(userDocRef, { name: newName });
      setUserData({ ...userData, name: newName });
      setIsEditingName(false);
      alert('Nombre actualizado.');
    } catch (error) {
      console.error('Error al actualizar nombre:', error);
      alert('Error al actualizar nombre.');
    }
  };

  const handleCancelEditName = () => {
    setNewName(userData?.name || '');
    setIsEditingName(false);
  };

  return (
    <div className="profile-page">
      <div className="profile-page-container">

        {/* Bloque de consumos */}
        <div className="profile-section-container">
          <div className="consumos-container">
            <h1 className="payments-title">Mis consumos</h1>

            {/* Conversaciones gratuitas */}
            <div className="conversations-counter" id="conversations-counter">
              <h2 className="conversations-title">Conversaciones gratuitas disponibles</h2>
              <p className="conversations-info">
                <span className="conversations-count">
                  {remainingFreeConversations}
                </span>
              </p>
              <p className="conversations-info-2">
                Has utilizado {totalThreadsUsed} conversaciones.
              </p>
            </div>

            {/* Conversaciones pagas */}
            <div className="conversations-counter">
              <h2 className="conversations-title">Conversaciones pagas disponibles</h2>
              <p className="conversations-count">
                {purchasedThreadsAvailable}
              </p>
            </div>

            {/* Vencimiento de Suscripción */}
            <div className="subscription-counter" id="subscription-counter">
              <h2 className="subscription-title">Vencimiento de Suscripción</h2>
              <p className="subscription-date">
                {formatAccessExpiresAt(userData?.accessExpiresAt)}
              </p>
            </div>

            {/* Sección de Mi Equipo */}
            <div className="team-section">
              {(fictitiousTeamMembers.length > 0 || accessCodeTeamMembers.length > 0) && (
                <>
                  <h2 className="team-title">Mi Equipo</h2>

                  {/* Miembros Ficticios */}
                  {fictitiousTeamMembers.length > 0 && (
                    <div className="fictitious-team-section">
                      <h3>Miembros</h3>
                      <ul className="team-list">
                        {fictitiousTeamMembers.map((member, index) => (
                          <li key={`fict-${index}`} className="team-member">
                            <div className="member-details">
                              <p className="name-user">
                                &#8226; {member.nombre} {member.apellido}
                              </p>
                              {member.email && (
                                <p className="name-user">
                                  {member.email}
                                </p>
                              )}
                              <p className="name-user">
                                {member.cargo}
                              </p>
                            </div>
                            <button
                              className="delete-member-button"
                              onClick={() => handleDeleteFictitiousMember(index)}
                              title="Eliminar miembro"
                            >
                              <FontAwesomeIcon icon={faTrashAlt} />
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}

                  {/* Miembros Reales MKT */}
                  {accessCodeTeamMembers.length > 0 && (
                    <div className="real-team-section">
                      <h3>Miembros MKT</h3>
                      <ul className="team-list">
                        {accessCodeTeamMembers.map((member) => (
                          <li key={`real-${member.id}`} className="team-member">
                            <div className="member-details">
                              <p className="name-user">&#8226; {member.name}</p>
                              <p className="name-user">{member.email}</p>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </>
              )}

              <button
                className="add-member-button"
                id="add-member-button"
                onClick={() => setIsAddingFictitiousMember(true)}
              >
                Añadir Miembro
              </button>
            </div>

            {/* Formulario para añadir miembro */}
            {isAddingFictitiousMember && (
              <div className="add-member-form">
                <h3>Añadir Nuevo Miembro</h3>

                <input
                  type="text"
                  placeholder="Nombre"
                  value={newMemberFirstName}
                  onChange={(e) => setNewMemberFirstName(e.target.value)}
                  className="input-field"
                />
                <input
                  type="text"
                  placeholder="Apellido"
                  value={newMemberLastName}
                  onChange={(e) => setNewMemberLastName(e.target.value)}
                  className="input-field"
                />
                <input
                  type="text"
                  placeholder="Email (opcional)"
                  value={newMemberEmail}
                  onChange={(e) => setNewMemberEmail(e.target.value)}
                  className="input-field"
                />
                <input
                  type="text"
                  placeholder="Cargo"
                  value={newMemberRole}
                  onChange={(e) => setNewMemberRole(e.target.value)}
                  className="input-field"
                />

                <div className="form-buttons">
                  <button onClick={handleAddFictitiousMember} className="save-button">
                    Guardar
                  </button>
                  <button
                    onClick={() => setIsAddingFictitiousMember(false)}
                    className="cancel-button"
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* user-info */}
        <div className="profile-section-container">
          {isLoading ? (
            <div className="spinner-payments"></div>
          ) : (
            <div className="payments-container">
              {userData && (
                <div className="user-info">
                  <div className="profile-picture">
                    <div className="initials-circle">
                      <p>{getInitials(userData.name)}</p>
                    </div>
                  </div>

                  <div className="user-details">
                    {isEditingName ? (
                      <div className="name-editor">
                        <input
                          type="text"
                          value={newName}
                          onChange={(e) => setNewName(e.target.value)}
                          className="new-name-input"
                        />
                        <div className="edit-name-buttons-row">
                          <button onClick={handleSaveName} className="save-newname-button">
                            Guardar
                          </button>
                          <button onClick={handleCancelEditName} className="cancel-edit-button">
                            Cancelar
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="name-display">
                        <p className="name-user">{userData.name}</p>
                      </div>
                    )}
                    <p className="email-user">{userData.email}</p>
                    <div className="edit-name-display">
                      <button onClick={handleEditName} className="edit-name-button">
                        Editar Nombre
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Profile;
