import React, { useEffect, useState } from 'react';
import { getAuth, applyActionCode, checkActionCode, verifyPasswordResetCode, confirmPasswordReset } from 'firebase/auth';
import './Auth.css'; // Usa tus estilos personalizados
import { useSearchParams, useNavigate } from 'react-router-dom';

const HandleAction = () => {
  const [searchParams] = useSearchParams();
  const [message, setMessage] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [actionMode, setActionMode] = useState('');
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const mode = searchParams.get('mode');
    const actionCode = searchParams.get('oobCode');
    const continueUrl = searchParams.get('continueUrl');

    setActionMode(mode);

    if (!mode || !actionCode) {
      setMessage('Solicitud inválida.');
      return;
    }

    switch (mode) {
      case 'resetPassword':
        verifyPasswordResetCode(auth, actionCode)
          .then((email) => {
            setEmail(email);
            setMessage('Por favor, ingrese su contraseña.');
          })
          .catch(() => {
            setMessage('El enlace para establecer la contraseña no es válido o ha expirado.');
          });
        break;

      case 'verifyEmail':
        applyActionCode(auth, actionCode)
          .then(() => {
            setMessage('Tu correo ha sido verificado con éxito.');
            setTimeout(() => navigate('/login'), 3000); // Redirige después de 3 segundos
          })
          .catch(() => {
            setMessage('El enlace para verificar el correo no es válido o ha expirado.');
          });
        break;

      default:
        setMessage('Solicitud no reconocida.');
    }
  }, [searchParams, auth, navigate]);

  const handlePasswordReset = (e) => {
    e.preventDefault();
    const actionCode = searchParams.get('oobCode');
    confirmPasswordReset(auth, actionCode, newPassword)
      .then(() => {
        setMessage('Contraseña establecida con éxito. Ahora puedes iniciar sesión.');
        setTimeout(() => navigate('/login'), 3000); // Redirige después de 3 segundos
      })
      .catch(() => {
        setMessage('Hubo un error al establecer la contraseña.');
      });
  };

  return (
    <div className="auth-page">
      <div className="auth-container">
        <h2>Acción Requerida</h2>
        {message && <p>{message}</p>}

        {actionMode === 'resetPassword' && email && (
          <form onSubmit={handlePasswordReset} className="auth-form">
            <label htmlFor="newPassword" className="auth-label">
              Ingrese su contraseña<span className="required">*</span>
            </label>
            <input
              id="newPassword"
              className="auth-input"
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="Ingresa tu contraseña"
              required
            />
            <button type="submit" className="auth-button">
              Establecer Contraseña
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default HandleAction;
