import React, { useState, useEffect } from 'react';
import { 
  createKnowledgeBase, 
  uploadFilesToKnowledgeBase, 
  addFileToVectorStore, 
  getVectorStoreFiles, 
  getFileDetails, 
  deleteKnowledgeBase, 
  deleteFileFromVectorStore 
} from '../services/openaiService';
import { db } from '../firebase';
import { doc, setDoc, deleteDoc, serverTimestamp } from 'firebase/firestore';
import { useAuth } from '../context/AuthContext';

const CreateKnowledgeBase = ({ onKnowledgeBaseCreated, knowledgeBaseToEdit, teamMembers = [] }) => {
  const [name, setName] = useState('');
  
  // Estados para el texto del mensaje y su tipo
  const [status, setStatus] = useState('');
  const [statusType, setStatusType] = useState('info'); 

  const [uploadedFiles, setUploadedFiles] = useState([]); 
  const [associatedFiles, setAssociatedFiles] = useState([]); 
  const [isUploading, setIsUploading] = useState(false);
  const [filesReadyToAssociate, setFilesReadyToAssociate] = useState(false);

  // Estado para prevenir envíos múltiples
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Estado para guardar el error completo (si ocurre)
  const [detailedError, setDetailedError] = useState(null);

  const { currentUser } = useAuth();

  // Helper para asignar status y su tipo
  const setStatusMessage = (message, type = 'info') => {
    setStatus(message);
    setStatusType(type);
  };

  useEffect(() => {
    if (knowledgeBaseToEdit) {
      setName(knowledgeBaseToEdit.name);
      fetchAssociatedFiles();
    }
  }, [knowledgeBaseToEdit]);

  const fetchAssociatedFiles = async () => {
    if (knowledgeBaseToEdit && knowledgeBaseToEdit.id) {
      try {
        const files = await getVectorStoreFiles(knowledgeBaseToEdit.id);
        const fileDetailsPromises = files.map(file => getFileDetails(file.id));
        const fileDetails = await Promise.all(fileDetailsPromises);

        const formattedFiles = fileDetails.map(file => ({
          id: file.id,
          filename: file.filename || 'Name not available',
        }));
    
        setAssociatedFiles(formattedFiles);
      } catch (error) {
        console.error('Error fetching associated files:', error);
        setStatusMessage('Error fetching associated files: ' + error.message, 'error');
      }
    }
  };

  const handleDeleteKnowledgeBase = async () => {
    if (!knowledgeBaseToEdit || !knowledgeBaseToEdit.id) return;
    
    if (window.confirm('¿Estás seguro de que deseas eliminar esta base de conocimiento? Esta acción no se puede deshacer.')) {
      try {
        await deleteKnowledgeBase(knowledgeBaseToEdit.id);
        await deleteDoc(doc(db, 'bases', knowledgeBaseToEdit.id));
        alert('Base de conocimiento eliminada correctamente.');
        onKnowledgeBaseCreated(null);
      } catch (error) {
        console.error('Error al eliminar la base de conocimiento:', error);
        alert('No se pudo eliminar la base de conocimiento. Por favor, intenta nuevamente.');
      }
    }
  };
  
  const handleDeleteFile = async (fileId) => {
    if (!knowledgeBaseToEdit || !knowledgeBaseToEdit.id) return;
  
    if (window.confirm('¿Estás seguro de que deseas eliminar este archivo? Esta acción no se puede deshacer.')) {
      try {
        await deleteFileFromVectorStore(knowledgeBaseToEdit.id, fileId);
        setAssociatedFiles(prevFiles => prevFiles.filter((file) => file.id !== fileId));
        setStatusMessage('Archivo eliminado correctamente.', 'success');
      } catch (error) {
        console.error('Error borrando archivo:', error);
        setStatusMessage('Error borrando archivo. Por favor intente nuevamente', 'error');
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isSubmitting) {
      console.log('Envío ya en proceso. Acción prevenida.');
      return;
    }
    setIsSubmitting(true);

    if (!name) {
      setStatusMessage('Por favor, introduce un nombre para la base.', 'error');
      setIsSubmitting(false);
      return;
    }

    try {
      const allowedUsers = Array.isArray(teamMembers)
        ? teamMembers.map(member => member.id).concat(currentUser.uid)
        : [currentUser.uid];

      const knowledgeBase = await createKnowledgeBase(name);

      if (knowledgeBase) {
        const baseData = {
          id: knowledgeBase.id,
          name: name,
          allowedUsers,
          createdAt: serverTimestamp(),
        };
        await setDoc(doc(db, 'bases', knowledgeBase.id), baseData);

        setStatusMessage('Base de conocimiento creada correctamente.', 'success');
        onKnowledgeBaseCreated(knowledgeBase);
      } else {
        setStatusMessage('Error al crear la base de conocimiento.', 'error');
      }
    } catch (error) {
      console.error('Error al crear la base de conocimiento:', error);
      setStatusMessage('Error al crear la base de conocimiento: ' + error.message, 'error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleFileSelect = async (e) => {
    const selectedFiles = [...e.target.files];
    if (selectedFiles.length === 0) return;

    setIsUploading(true);
    setStatusMessage('Subiendo archivos...', 'info');

    try {
      const uploaded = await uploadFilesToKnowledgeBase(selectedFiles);

      if (uploaded && uploaded.length > 0) {
        setUploadedFiles(uploaded);
        setFilesReadyToAssociate(true);
        setStatusMessage(
          'Archivos subidos correctamente. Ahora presione "GUARDAR ARCHIVOS" para almacenarlos en la base de datos.',
          'success'
        );
      } else {
        setStatusMessage('Error subiendo archivos.', 'error');
      }
    } catch (error) {
      console.error('Error subiendo archivos:', error);
      setDetailedError(error);
      setStatusMessage('Error subiendo archivos: ' + (error.response?.data?.error?.message || error.message), 'error');
    } finally {
      setIsUploading(false);
    }
  };

  const handleFileAssociation = async () => {
    if (!knowledgeBaseToEdit || !knowledgeBaseToEdit.id || uploadedFiles.length === 0) {
      setStatusMessage('Seleccione una base de conocimiento.', 'error');
      return;
    }

    setIsUploading(true);
    setFilesReadyToAssociate(false);
    setStatusMessage('Guardando archivos en la base de conocimiento...', 'info');

    try {
      await addFileToVectorStore(knowledgeBaseToEdit.id, uploadedFiles);

      setAssociatedFiles(prevFiles => [
        ...prevFiles,
        ...uploadedFiles.map(file => ({ id: file.id, filename: file.filename || 'Nombre no disponible' }))
      ]);
      setUploadedFiles([]);
      setStatusMessage('Archivos añadidos correctamente a la base de conocimiento.', 'success');
    } catch (error) {
      console.error('Error guardando archivos:', error);
      setDetailedError(error);
      setStatusMessage(
        'Error guardando archivos: ' + (error.response?.data?.error?.message || error.message), 
        'error'
      );
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div>
      <h2 className="crear-asistente">
        {knowledgeBaseToEdit
          ? `Editar "${knowledgeBaseToEdit.name || 'Base de conocimiento'}"`
          : 'Crear Base de conocimiento'
        }
      </h2>

      {/* Form para crear la base si no existe */}
      {!knowledgeBaseToEdit && (
        <form onSubmit={handleSubmit} className="auth-form">
          <label className="auth-label" htmlFor="name">
            <p className='nombre-asistente'>
              Nombre de tu base <span className="required">*</span>
            </p>
          </label>
          <input
            id="name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Base para agente de soporte técnico"
            className="crear-base-input"
            required
          />
          <button type="submit" className="create-button" disabled={isSubmitting}>
            {isSubmitting ? 'Creando...' : 'Crear base de conocimiento'}
          </button>
        </form>
      )}

      {/* Si ya existe la base, permitimos subir archivos */}
      {knowledgeBaseToEdit && (
        <>
          <h3 className='subir-archivos'>Subir archivos a la base de conocimiento</h3>
          <p className="subir-archivos">
            Soporta solamente archivos: 
            <strong> c, cpp, cs, css, doc, docx, go, html, java, js, json, md, pdf, php, pptx, py, rb, sh, tex, ts, txt </strong>
            <br />
            <span className="file-size-text">( 50 MB máx. cada uno )</span>
          </p>

          {/* Mensaje de estado, la clase depende de si es error o no */}
          <p className={statusType === 'error' ? 'error-openai' : 'info-openai'}>
            {status}
          </p>


          <div
            className="upload-button"
            style={{ position: 'relative', display: 'inline-block', cursor: 'pointer' }}
          >
            <label htmlFor="file-upload" style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
              <span>Subir archivos</span>
              <i className="fa fa-cloud-upload upload-icon" aria-hidden="true"></i>
            </label>
            <input
              id="file-upload"
              type="file"
              multiple
              onChange={handleFileSelect}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                opacity: 0,
                cursor: 'pointer',
              }}
            />
          </div>
        </>
      )}

      {filesReadyToAssociate && (
        <button onClick={handleFileAssociation} className="auth-button">
          Guardar archivos
        </button>
      )}

      {associatedFiles.length > 0 && (
        <div>
          <h3 className='base-archivos'>Archivos en esta base:</h3>
          <ul className="archivos-lista">
            {associatedFiles.map((file) => (
              <li className="archivos-asociados" key={file.id}>
                <span className="file-text">{file.filename}</span>
                <i
                  className="fa fa-check check-icon"
                  aria-hidden="true"
                  onMouseEnter={(e) => e.currentTarget.classList.replace('fa-check', 'fa-times')}
                  onMouseLeave={(e) => e.currentTarget.classList.replace('fa-times', 'fa-check')}
                  onClick={() => handleDeleteFile(file.id)}
                  style={{ cursor: 'pointer', marginLeft: '10px' }}
                />
              </li>
            ))}
          </ul>
        </div>
      )}

      {knowledgeBaseToEdit && (
        <div className="delete-button-div">
          <button onClick={handleDeleteKnowledgeBase} className="delete-agent-button">
            Borrar base de conocimiento
          </button>
        </div>
      )}

      
    </div>
  );
};

export default CreateKnowledgeBase;
