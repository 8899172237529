// src/components/ThreadsViewer.js
import React, { useState, useEffect, useRef, useMemo, useContext } from 'react';
import './ThreadsViewer.css';
import whatsappLogo from '../assets/whatsapp-logo.png';
import paperClipButton from '../assets/paper-clip-button.png';
import ControlAgenteIA from '../assets/Control-Agente-IA.png';
import webChatIcon from '../assets/web-chat-icon.png';
import flechaBaja from '../assets/flecha-baja.png';

import { useAuth } from '../context/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import plusIconChat from '../assets/+-icon-chat.png';
import emojiIconChat from '../assets/emoji-icon-chat.png';
import clipIconChat from '../assets/clip-icon-chat.png';

import EmojiPicker from 'emoji-picker-react';

import { getAllAssistants } from '../services/openaiService';
import { faFile, faSliders } from '@fortawesome/free-solid-svg-icons';  // <--- FaFile
import seleccionarPlantillas from '../assets/seleccionar-plantillas.png';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faStar as faStarSolid,
  faCaretDown,
  faTrashAlt,
  faEllipsisV,
  faSearch,
  faTimes,
  faStore
} from '@fortawesome/free-solid-svg-icons';

import { faEnvelope, faPaperPlane } from '@fortawesome/free-solid-svg-icons';

import { FaFile, FaWhatsapp } from 'react-icons/fa';
import { faFilter } from '@fortawesome/free-solid-svg-icons'; // nuevo import

import backgroundChat from '../assets/background-chat.png';

import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons';

import { SelectedTeamMemberContext } from '../context/SelectedTeamMemberContext';

import { getAuth } from 'firebase/auth'; // Si usas Firebase auth

import { db } from '../firebase';
import {
  collection,
  query,
  onSnapshot,
  updateDoc,
  getDocs,
  doc,
  getDoc,
  deleteDoc,
  where,
} from 'firebase/firestore';

import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import TiendaNubeConnectForm from './TiendaNubeConnectForm';

// Configurar worker de PDF.js
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const ThreadsViewer = () => {
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [isOrderDropdownOpen, setIsOrderDropdownOpen] = useState(false);
  const [isFilterDropdownOpen, setIsFilterDropdownOpen] = useState(false);
  const [showTiendaNubeModal,  setShowTiendaNubeModal] = useState(false);
  const [filter, setFilter] = useState(null);           // Para filtrar (por ejemplo, 'favorites')
  const filterDropdownRef = useRef(null);

  const [filterByDate, setFilterByDate] = useState(null); // Para filtrar por fecha (ISO string, "YYYY-MM-DD")

  const [filterStartDate, setFilterStartDate] = useState(null);
const [filterEndDate, setFilterEndDate] = useState(null);


  const { selectedTeamMember } = useContext(SelectedTeamMemberContext);
  const [selectedTeamMemberId, setSelectedTeamMemberId] = useState(null);

  const [agents, setAgents] = useState([]);
  const [filteredAgents, setFilteredAgents] = useState([]);
  const [agentSearchTerm, setAgentSearchTerm] = useState('');
  const [threads, setThreads] = useState([]);
  const [selectedThread, setSelectedThread] = useState(null);
  const [loadingAgents, setLoadingAgents] = useState(true);
  const [loadingThreads, setLoadingThreads] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [newMessage, setNewMessage] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [showTemplateDropdown, setShowTemplateDropdown] = useState(false);
  

  const [templateExists, setTemplateExists] = useState(false);
  const [checkingTemplate, setCheckingTemplate] = useState(false);

  const [noAgents, setNoAgents] = useState(false);

  // ====== Switch local / global de Autorespuesta
  const [isAutoResponseEnabled, setIsAutoResponseEnabled] = useState(true);
  const [globalAutoResponseEnabled, setGlobalAutoResponseEnabled] = useState(true);

  const [scrollOnLoad, setScrollOnLoad] = useState(true);
  const [isUserNearBottom, setIsUserNearBottom] = useState(true);
  const [unreadThreadIds, setUnreadThreadIds] = useState([]);



// Si prefieres que la función reciba el objeto "thread" completo:
async function markThreadAsUnread(thread) {
  setUnreadThreadIds(prevIds => {
    if (prevIds.includes(thread.threadId)) return prevIds;
    return [...prevIds, thread.threadId];
  });

  try {
    const ref = doc(db, `threads_${thread.assistantId}`, thread.threadId);
    await updateDoc(ref, { readByOwner: false });
  } catch (error) {
    console.error('Error al marcar el thread como no leído:', error);
  }

  setIsMoveMenuOpen(null);
}


  const [assistantPassKey, setAssistantPassKey] = useState('');
  const [isSending, setIsSending] = useState(false);

  const [creatingTemplate, setCreatingTemplate] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const emojiPickerRef = useRef(null);

  const templateDropdownRef = useRef(null);
  const orderContainerRef = useRef(null);
  const [teamModalPosition, setTeamModalPosition] = useState({ top: 0, left: 0 });


  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPreviewIndex, setCurrentPreviewIndex] = useState(0);

  const [showOnlyUnread, setShowOnlyUnread] = useState(false);

  const [orderBy, setOrderBy] = useState('lastMessage');

  const { assistantId, threadId } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  // Manejo de conversaciones restantes
  const [totalThreads, setTotalThreads] = useState(0);
  const [purchasedThreads, setPurchasedThreads] = useState(0);
  const [remainingConversations, setRemainingConversations] = useState(200);
  const [isLimitReached, setIsLimitReached] = useState(false);
  const [showConversationLimitModal, setShowConversationLimitModal] = useState(false);

  // Modal para asignar miembros
  const [isTeamModalOpen, setIsTeamModalOpen] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);

  // CRM
  const [crmColumns, setCrmColumns] = useState([]);
  const [isMoveMenuOpen, setIsMoveMenuOpen] = useState(null);

  // Búsqueda en mensajes
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [currentSearchIndex, setCurrentSearchIndex] = useState(0);

  // Búsqueda global
  const [isGlobalSearchOpen, setIsGlobalSearchOpen] = useState(false);
  const [globalSearchTerm, setGlobalSearchTerm] = useState('');
  const [globalSearchResults, setGlobalSearchResults] = useState([]);
  const [isGlobalSearchLoading, setIsGlobalSearchLoading] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showMobileThreadList, setShowMobileThreadList] = useState(true);

  const [availableTemplates, setAvailableTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState('contacto');

  // =========== Refs
  const messageHistoryRef = useRef(null);
  const lastMessageRef = useRef(null);
  const fileInputRef = useRef(null);
  const loadingTimeoutRef = useRef(null);
  const orderFilterRef = useRef(null);
  const messageRefs = useRef([]);
  const prevThreadsRef = useRef([]);

  // Manejo de archivos
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [previewURLs, setPreviewURLs] = useState([]);

  // Justo en la parte de los useState:
// Por ejemplo, en tu componente
const [chosenTemplate, setChosenTemplate] = useState(null);

const [isCreatingDefaults, setIsCreatingDefaults] = useState(false);



  // ========== Lógica de chat “congelado” por 24h / awaitingReply
  const isAwaitingReply = selectedThread?.isWaitingUserReply;
  const [alertVisible, setAlertVisible] = useState(isAwaitingReply);  // Nuevo estado para controlar la alerta

  

  function isOlderThan24Hours(dateString) {
    if (!dateString) return false;
    const now = new Date();
    const msgDate = new Date(dateString);
    const diff = now - msgDate;
    return diff > 24 * 60 * 60 * 1000;
  }

  const lastMsg = selectedThread?.messageHistory?.[selectedThread.messageHistory.length - 1];
  const olderThan24h = lastMsg ? isOlderThan24Hours(lastMsg.createdAt) : false;

  // === Checar si ya hay 2 plantillas enviadas sin respuesta <24h
  const freezeFaFile = (selectedThread?.templateCountNoReply24h || 0) >= 2;

  // Bloqueo global del chat normal (texto) si olderThan24h o awaitingReply
  const isChatFrozen = olderThan24h || isAwaitingReply;

  // Estado para saber si YA existen las 3 plantillas "tt_..."
const [hasAllDefaultTemplates, setHasAllDefaultTemplates] = useState(false);

const [isTiendaNubeConnected, setIsTiendaNubeConnected] = useState(false);
const [tnProducts, setTnProducts] = useState([]); 
const [showProductDropdown, setShowProductDropdown] = useState(false);


// Agrega estos dos estados en ThreadsViewer
const [crmDropdownOpenThreadId, setCrmDropdownOpenThreadId] = useState(null);
const [hoveredCrmThreadId, setHoveredCrmThreadId] = useState(null);


useEffect(() => {
  function handleClickOutside(event) {
    // Si el contenedor existe y el click no está dentro de él:
    if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
      setShowEmojiPicker(false);
    }
  }

  // Escuchar mousedown (o click) en el documento
  document.addEventListener("click", handleClickOutside);

  return () => {
    // Limpieza: dejar de escuchar cuando se desmonte
    document.removeEventListener("click", handleClickOutside);
  };
}, [showEmojiPicker]);
useEffect(() => {
  function handleFilterClickOutside(e) {
    if (
      isFilterDropdownOpen &&
      filterDropdownRef.current &&
      !filterDropdownRef.current.contains(e.target)
    ) {
      setIsFilterDropdownOpen(false);
    }
  }
  document.addEventListener("click", handleFilterClickOutside);
  return () => {
    document.removeEventListener("click", handleFilterClickOutside);
  };
}, [isFilterDropdownOpen]);


useEffect(() => {
  function handleClickOutside(e) {
    if (
      isOrderDropdownOpen &&
      orderContainerRef.current && 
      !orderContainerRef.current.contains(e.target)
    ) {
      setIsOrderDropdownOpen(false);
    }
  }
  document.addEventListener('click', handleClickOutside);
  return () => {
    document.removeEventListener('click', handleClickOutside);
  };
}, [isOrderDropdownOpen]);


useEffect(() => {
  function handleClickOutside(event) {
    // Si la dropdown está visible y el clic no fue dentro de ella:
    if (
      showTemplateDropdown &&
      templateDropdownRef.current &&
      !templateDropdownRef.current.contains(event.target)
    ) {
      setShowTemplateDropdown(false);
    }
  }

  // Listener global en "click"
  document.addEventListener("click", handleClickOutside);
  return () => {
    document.removeEventListener("click", handleClickOutside);
  };
}, [showTemplateDropdown]);


useEffect(() => {
  if (!availableTemplates || availableTemplates.length === 0) {
    setHasAllDefaultTemplates(false);
    return;
  }

  // Queremos saber si están las 3:
  const needed = ['tt_contacto', 'tt_en_linea', 'tt_que_necesitas'];
  // every => revisa que TODAS estén
  const hasAll = needed.every(name =>
    availableTemplates.some(tpl => tpl.name === name)
  );

  setHasAllDefaultTemplates(hasAll);
}, [availableTemplates]);



  // UseEffect para manejar la desaparición de la alerta
useEffect(() => {
  if (isAwaitingReply === false) {
    setAlertVisible(false);  // Si isWaitingUserReply es false, ocultamos la alerta
  } else {
    setAlertVisible(true);   // Si isWaitingUserReply es true, mostramos la alerta
  }
}, [isAwaitingReply]);

  // ============== EFFECTS & LISTENERS ==============

  // Ajuste de tamaño (mobile)
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (windowWidth <= 1000) {
      setShowMobileThreadList(true);
      setSelectedThread(null);
    }
  }, [windowWidth]);


  // Listener de usuario => globalAutoResponseEnabled, totalThreads, etc.
  useEffect(() => {
    if (!currentUser) return;
    const userDocRef = doc(db, 'users', currentUser.uid);
    const unsubscribeUser = onSnapshot(userDocRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const userData = docSnapshot.data();
        const updatedTotalThreads = userData.totalThreads || 0;
        const updatedPurchasedThreads = userData.purchasedThreads || 0;
        setTotalThreads(updatedTotalThreads);
        setPurchasedThreads(updatedPurchasedThreads);
        const remaining = Math.max(0, 200 + updatedPurchasedThreads - updatedTotalThreads);
        setRemainingConversations(remaining);

        if (remaining <= 0) {
          setIsLimitReached(true);
          setIsAutoResponseEnabled(false);
          setShowConversationLimitModal(true);
        } else {
          setIsLimitReached(false);
        }

        if (typeof userData.globalAutoResponseEnabled === 'boolean') {
          setGlobalAutoResponseEnabled(userData.globalAutoResponseEnabled);
        } else {
          setGlobalAutoResponseEnabled(true);
        }
      }
    });
    return () => unsubscribeUser();
  }, [currentUser]);

  // Cargar miembros de equipo
  useEffect(() => {
    if (!currentUser) return;
    const fetchTeamMembers = async () => {
      try {
        const userRef = doc(db, 'users', currentUser.uid);
        const userDocSnap = await getDoc(userRef);
        if (!userDocSnap.exists()) return;

        const userData = userDocSnap.data();
        const equipoMembers = userData.equipo || [];
        let mktMembers = [];

        if (userData.accessCode === 'MKT') {
          const mktQuery = query(
            collection(db, 'users'),
            where('accessCode', '==', 'MKT')
          );
          const mktSnap = await getDocs(mktQuery);
          mktMembers = mktSnap.docs
            .filter((snap) => snap.exists())
            .map((snap) => {
              const data = snap.data();
              if (data.nombre && data.apellido) {
                return {
                  id: snap.id,
                  type: 'real',
                  nombre: data.nombre,
                  apellido: data.apellido,
                  cargo: data.cargo || '',
                };
              } else if (data.name) {
                const nameParts = data.name.trim().split(' ');
                const nombre = nameParts[0];
                const apellido = nameParts.slice(1).join(' ') || '';
                return {
                  id: snap.id,
                  type: 'real',
                  nombre,
                  apellido,
                  cargo: data.cargo || '',
                };
              }
              return {
                id: snap.id,
                type: 'real',
                nombre: '',
                apellido: '',
                cargo: '',
              };
            });
        }

        const combinedTeamMembers = [
          ...equipoMembers.map((member, index) => ({
            id: `fictitious-${index}`,
            type: 'fictitious',
            nombre: member.nombre,
            apellido: member.apellido,
            cargo: member.cargo,
          })),
          ...mktMembers,
        ];

        setTeamMembers(combinedTeamMembers);
      } catch (error) {
        console.error('Error al cargar miembros del equipo:', error);
      }
    };
    fetchTeamMembers();
  }, [currentUser]);

  // Cargar columnas CRM
  useEffect(() => {
    if (!currentUser) return;
    const crmQuery = query(
      collection(db, 'crm'),
      where('authorizedUsers', 'array-contains', currentUser.uid)
    );
    const unsubscribe = onSnapshot(crmQuery, (snapshot) => {
      const columns = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCrmColumns(columns);
    }, (error) => {
      console.error('Error en listener de CRM:', error);
    });
    return () => unsubscribe();
  }, [currentUser]);

  // Obtener Agents + redirección
  useEffect(() => {
    const fetchAgentsAndHandleNavigation = async () => {
      if (!currentUser) {
        setLoadingAgents(false);
        return;
      }
      try {
        const assistantsData = await getAllAssistants(currentUser.uid);
        setAgents(assistantsData);

        if (assistantsData.length === 0) {
          setNoAgents(true);
          setLoadingAgents(false);
          return;
        }
        setNoAgents(false);

        if (!assistantId) {
          // Navegar al primero
          navigate(`/messages/${assistantsData[0].id}`);
        } else {
          const agent = assistantsData.find((a) => a.id === assistantId);
          if (agent) {
            setSelectedAgent(agent);
          } else {
            console.error('assistantId inválido o no pertenece al usuario actual');
            setSelectedAgent(null);
            setSelectedThread(null);
            navigate('/messages');
          }
        }
      } catch (error) {
        console.error('Error al obtener agentes:', error);
      }
      setLoadingAgents(false);
    };
    fetchAgentsAndHandleNavigation();
  }, [assistantId, currentUser, navigate]);

  

  // Cargar threads según TeamMember o Agent
  const fetchThreadsForAllAssistantsForMember = async (owner) => {
    if (!currentUser) return;
    setLoadingThreads(true);
    loadingTimeoutRef.current = setTimeout(() => setShowLoading(true), 300);

    try {
      const assistantsData = await getAllAssistants(currentUser.uid);
      let allThreads = [];
      for (const assistant of assistantsData) {
        const ownerName = `${owner.nombre} ${owner.apellido}`.trim();
        const threadsCollection = collection(db, `threads_${assistant.id}`);
        const q = query(threadsCollection, where('conversationowner', '==', ownerName));
        const querySnapshot = await getDocs(q);
        const threadsData = querySnapshot.docs.map((doc) => ({
          threadId: doc.id,
          ...doc.data(),
          assistantId: assistant.id,
        }));
        allThreads = allThreads.concat(threadsData);
      }
      // Ordenar
      const sortedThreads = allThreads.slice().sort((a, b) => {
        const aLast = new Date(a.messageHistory[a.messageHistory.length - 1]?.createdAt);
        const bLast = new Date(b.messageHistory[b.messageHistory.length - 1]?.createdAt);
        return bLast - aLast;
      });
      setThreads(sortedThreads);
    } catch (error) {
      console.error('Error al obtener threads:', error);
    }
    setLoadingThreads(false);
    clearTimeout(loadingTimeoutRef.current);
    setShowLoading(false);
  };

  const fetchThreadsByAssistantId = async (assistantId, owner = null) => {
    setLoadingThreads(true);
    loadingTimeoutRef.current = setTimeout(() => setShowLoading(true), 300);

    try {
      const threadsCollection = collection(db, `threads_${assistantId}`);
      let q;
      if (owner) {
        const ownerName = `${owner.nombre} ${owner.apellido}`.trim();
        q = query(threadsCollection, where('conversationowner', '==', ownerName));
      } else {
        q = query(threadsCollection);
      }
      const querySnapshot = await getDocs(q);
      const threadsData = querySnapshot.docs.map((doc) => ({
        threadId: doc.id,
        ...doc.data(),
        assistantId,
      }));
      // Ordenar
      const sortedThreads = threadsData.slice().sort((a, b) => {
        const aLast = new Date(a.messageHistory[a.messageHistory.length - 1]?.createdAt);
        const bLast = new Date(b.messageHistory[b.messageHistory.length - 1]?.createdAt);
        return bLast - aLast;
      });
      setThreads(sortedThreads);
    } catch (error) {
      console.error('Error al obtener los threads:', error);
    }
    setLoadingThreads(false);
    clearTimeout(loadingTimeoutRef.current);
    setShowLoading(false);
  };

  // Cuando cambia selectedAgent o selectedTeamMember => cargar threads
  useEffect(() => {
    if (selectedTeamMember) {
      fetchThreadsForAllAssistantsForMember(selectedTeamMember);
      if (!threadId) setSelectedThread(null);
    } else if (selectedAgent) {
      fetchThreadsByAssistantId(selectedAgent.id, selectedTeamMember);
      if (!threadId) setSelectedThread(null);
    } else {
      setThreads([]);
      setSelectedThread(null);
    }
  }, [selectedAgent, selectedTeamMember, threadId]);

  // Seleccionar thread si hay threadId
  useEffect(() => {
    if (threadId && threads.length > 0) {
      const thread = threads.find((t) => t.threadId === threadId);
      if (thread) {
        handleSelectThread(thread);
      } else {
        // Sin match
        if (selectedTeamMember) {
          navigate(`/messages`);
        } else {
          navigate(`/messages/${assistantId}`);
        }
      }
    }
  }, [threadId, threads, assistantId, selectedTeamMember, navigate]);

  useEffect(() => {
    if (!currentUser) return;
  
    let unsubscribeList = [];
  
    // Para un agente seleccionado:
    const subscribeToSelectedAgentThreads = (agent) => {
      const threadsCollection = collection(db, `threads_${agent.id}`);
      const q = query(threadsCollection);
      const unsubscribe = onSnapshot(
        q,
        (snapshot) => {
          // Extraemos los threads desde Firestore
          const threadsFromFirestore = snapshot.docs.map((docSnap) => ({
            threadId: docSnap.id,
            ...docSnap.data(),
            assistantId: agent.id,
          }));
          setThreads(threadsFromFirestore);
  
          // Calculamos los IDs de threads no leídos (readByOwner === false o no definido)
          const unreadIds = threadsFromFirestore
            .filter((t) => t.readByOwner === false)
            .map((t) => t.threadId);
          setUnreadThreadIds(unreadIds);
        },
        (error) => {
          console.error(`Error en threads_${agent.id}:`, error);
        }
      );
      unsubscribeList.push(unsubscribe);
    };
  
    // Para cuando se selecciona un miembro de equipo (se suscribe a los threads de todos los agentes)
    const subscribeToAllAssistantsThreads = async (owner) => {
      try {
        const assistantsData = await getAllAssistants(currentUser.uid);
        const ownerName = `${owner.nombre} ${owner.apellido}`.trim();
        assistantsData.forEach((assistant) => {
          const threadsCollection = collection(db, `threads_${assistant.id}`);
          const q = query(threadsCollection, where('conversationowner', '==', ownerName));
          const unsub = onSnapshot(
            q,
            (snapshot) => {
              const threadsFromFirestore = snapshot.docs.map((docSnap) => ({
                threadId: docSnap.id,
                ...docSnap.data(),
                assistantId: assistant.id,
              }));
              // Combinar con los threads ya existentes que NO pertenezcan a este agente
              setThreads((prev) => {
                const otherThreads = prev.filter((t) => t.assistantId !== assistant.id);
                const combined = [...otherThreads, ...threadsFromFirestore];
                // Opcional: recalcular también los no leídos de todos los threads
                const unreadIds = combined
                  .filter((t) => t.readByOwner === false)
                  .map((t) => t.threadId);
                setUnreadThreadIds(unreadIds);
                return combined;
              });
            },
            (error) => {
              console.error(`Error en threads_${assistant.id}:`, error);
            }
          );
          unsubscribeList.push(unsub);
        });
      } catch (error) {
        console.error('Error al suscribirse a todos los threads:', error);
      }
    };
  
    if (selectedTeamMember) {
      subscribeToAllAssistantsThreads(selectedTeamMember);
    } else if (selectedAgent) {
      subscribeToSelectedAgentThreads(selectedAgent);
    } else {
      setThreads([]);
    }
  
    return () => {
      unsubscribeList.forEach((unsub) => unsub());
    };
  }, [selectedAgent, selectedTeamMember, currentUser]);
  

  // ============ RESETEAR templateCountNoReply24h AL DETECTAR RESPUESTA O +24H =============
  useEffect(() => {
    if (!selectedThread) return;
    // 1) Si el último mensaje es role=user => el usuario respondió => reset a 0
    const lastMsg = selectedThread.messageHistory[selectedThread.messageHistory.length - 1];
    if (lastMsg?.role === 'user') {
      // Si templateCountNoReply24h > 0 => lo reseteamos
      if ((selectedThread.templateCountNoReply24h || 0) > 0) {
        resetTemplateCountNoReply(selectedThread);
      }
      return;
    }
    // 2) Si ya pasó 1 día desde la última plantilla => reset
    // (necesitamos un campo en Firestore, p.ej. lastTemplateAt)
    if (selectedThread.lastTemplateAt) {
      const now = Date.now();
      const lastTpl = new Date(selectedThread.lastTemplateAt).getTime();
      const diff = now - lastTpl;
      if (diff > 24 * 60 * 60 * 1000) {
        // han pasado más de 24h de la última plantilla => reset
        if ((selectedThread.templateCountNoReply24h || 0) > 0) {
          resetTemplateCountNoReply(selectedThread);
        }
      }
    }
  }, [selectedThread?.messageHistory]); // se recalcula cuando llegan mensajes

  // Función para resetear el campo a 0
  const resetTemplateCountNoReply = async (thread) => {
    try {
      const ref = doc(db, `threads_${thread.assistantId}`, thread.threadId);
      await updateDoc(ref, {
        templateCountNoReply24h: 0,
      });
    } catch (err) {
      console.error('Error reseteando templateCountNoReply24h:', err);
    }
  };

  // =============== MANEJADORES DE ACCIONES ===============

  // Al hacer click en un thread
  const handleThreadClick = (thread) => {
    navigate(`/messages/${thread.assistantId}/${thread.threadId}`);
    setSelectedThread(thread);
    if (windowWidth <= 1000) setShowMobileThreadList(false);
  };

  // Al seleccionar un thread (desde fetch / navigation)
// Esta función se llama cuando el usuario hace clic en un thread (handleThreadClick o desde el useEffect de navegación)
const handleSelectThread = async (thread) => {
  setSelectedThread(thread);
  setIsAutoResponseEnabled(thread.autoResponseEnabled ?? true);
  setScrollOnLoad(true);
  setIsUserNearBottom(true);

  // Remover el thread de la lista local de no leídos
  setUnreadThreadIds(prevIds => prevIds.filter(id => id !== thread.threadId));

  // Actualizar en Firestore que el thread está leído (readByOwner = true)
  try {
    const ref = doc(db, `threads_${thread.assistantId}`, thread.threadId);
    await updateDoc(ref, { readByOwner: true });
  } catch (error) {
    console.error('Error marcando el thread como leído:', error);
  }
};

  // Manejo de asignar TeamMember
  const handleSelectTeamMember = async (member) => {
    if (!selectedThread || !selectedAgent) {
      alert('No se ha seleccionado un thread o agente.');
      return;
    }
    if (!member.id) {
      alert('Error interno: el miembro no tiene un ID válido.');
      return;
    }
    const conversationOwnerId = member.id;
    const conversationOwnerName = `${member.nombre} ${member.apellido}`.trim();
    try {
      const threadRef = doc(db, `threads_${selectedAgent.id}`, selectedThread.threadId);
      await updateDoc(threadRef, {
        conversationownerId: conversationOwnerId,
        conversationowner: conversationOwnerName,
      });
      setSelectedThread({
        ...selectedThread,
        conversationownerId: conversationOwnerId,
        conversationowner: conversationOwnerName,
      });
      alert(`Conversación asignada a ${conversationOwnerName}.`);
    } catch (error) {
      console.error('Error asignando conversationowner:', error);
      alert('Error al asignar conversación.');
    } finally {
      setIsTeamModalOpen(false);
    }
  };

  // Quitar propietario
  const removeOwner = async (threadId, assistantId) => {
    try {
      const threadRef = doc(db, `threads_${assistantId}`, threadId);
      await updateDoc(threadRef, { conversationowner: null });
      alert('Propietario removido exitosamente');
    } catch (error) {
      console.error('Error al quitar propietario:', error);
      alert('Error al quitar propietario');
    }
  };

  // Manejo de ordenar
  const handleOrderChange = (e) => {
    setOrderBy(e.target.value);
  };

  // 1) Dentro de tu componente:
const displayThreads = useMemo(() => {
  // Si hay búsqueda global y hay resultados, usamos ese array;
  // si no, usamos threads normales.
  if (globalSearchTerm.trim() && !isGlobalSearchLoading) {
    return globalSearchResults;
  } 
  return threads;
}, [globalSearchTerm, globalSearchResults, threads, isGlobalSearchLoading]);

// 2) Después, un useMemo para aplicar Filtros + Orden a displayThreads.
const filteredAndOrderedThreads = useMemo(() => {
  let data = [...displayThreads];

  // Filtro por “favoritos”
  if (filter === 'favorites') {
    data = data.filter((t) => t.isFavorite);
  }

  // Filtro por rango de fechas
  if (filterStartDate && filterEndDate) {
    data = data.filter((t) => {
      const lastMsg = t.messageHistory[t.messageHistory.length - 1];
      if (!lastMsg || !lastMsg.createdAt) return false;
      const threadDate = new Date(lastMsg.createdAt);
      const start = new Date(filterStartDate);
      const end = new Date(filterEndDate);
      // Que el end incluya todo el día:
      end.setHours(23, 59, 59, 999);
      return threadDate >= start && threadDate <= end;
    });
  }

  // Orden
  if (orderBy === 'name') {
    data.sort((a, b) => (a.senderName || '').localeCompare(b.senderName || ''));
  } else if (orderBy === 'date') {
    // Ordena por fecha de creación, si existiese un campo "createdAt" en el thread
    data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  } else if (orderBy === 'lastMessage') {
    data.sort((a, b) => {
      const aLast = new Date(a.messageHistory[a.messageHistory.length - 1]?.createdAt);
      const bLast = new Date(b.messageHistory[b.messageHistory.length - 1]?.createdAt);
      return bLast - aLast;
    });
  }

  return data;
}, [
  displayThreads,
  filter,
  filterStartDate,
  filterEndDate,
  orderBy,
]);



  // Filtrar threads
  const filteredThreads = useMemo(() => {
    let sorted = [...threads];
  
    // ...otros filtros (búsqueda, pendientes, etc.)
  
    // Filtro por "Destacado"
    if (filter === 'favorites') {
      sorted = sorted.filter((t) => t.isFavorite);
    }
  
    // Filtrar por rango de fechas si se han seleccionado ambas fechas
    if (filterStartDate && filterEndDate) {
      sorted = sorted.filter((t) => {
        // Usamos la fecha del último mensaje, que es la que se muestra en thread-date-info
        const lastMsg = t.messageHistory[t.messageHistory.length - 1];
        if (!lastMsg || !lastMsg.createdAt) return false; // o true, según cómo quieras manejar threads sin mensajes
        const threadDate = new Date(lastMsg.createdAt);
        const start = new Date(filterStartDate);
        const end = new Date(filterEndDate);
        // Aseguramos que la fecha final incluya todo el día:
        end.setHours(23, 59, 59, 999);
        return threadDate >= start && threadDate <= end;
      });
    }
    
    // Aplicar el ordenamiento según orderBy
    if (orderBy === 'name') {
      sorted.sort((a, b) => (a.senderName || '').localeCompare(b.senderName || ''));
    } else if (orderBy === 'date') {
      sorted.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    } else if (orderBy === 'lastMessage') {
      sorted.sort((a, b) => {
        const aLast = new Date(a.messageHistory[a.messageHistory.length - 1]?.createdAt);
        const bLast = new Date(b.messageHistory[b.messageHistory.length - 1]?.createdAt);
        return bLast - aLast;
      });
    }
  
    // (Lógica adicional para detectar mensajes no leídos, etc.)
    // ...
  
    return sorted;
  }, [threads, searchTerm, orderBy, showOnlyUnread, unreadThreadIds, selectedThread, filter, filterStartDate, filterEndDate]);



  // Borrar chat
  const handleDeleteThread = async (threadId) => {
    const thread = threads.find((t) => t.threadId === threadId);
    if (!thread) return;
    const confirmDelete = window.confirm('¿Seguro de eliminar este chat?');
    if (!confirmDelete) return;
    try {
      await deleteDoc(doc(db, `threads_${thread.assistantId}`, threadId));
      alert('Chat eliminado.');
      if (selectedThread?.threadId === threadId) {
        setSelectedThread(null);
      }
    } catch (error) {
      console.error('Error al eliminar chat:', error);
      alert('Error al eliminar chat.');
    }
  };

  // Toggle favorito
  const toggleFavorite = async (threadId) => {
    if (!selectedAgent && !selectedTeamMember) return;
    const thread = threads.find((t) => t.threadId === threadId);
    if (!thread) return;
    const ref = doc(db, `threads_${thread.assistantId}`, threadId);
    try {
      const snap = await getDoc(ref);
      if (snap.exists()) {
        const currentIsFavorite = snap.data().isFavorite || false;
        await updateDoc(ref, { isFavorite: !currentIsFavorite });
      }
    } catch (error) {
      console.error('Error al cambiar favorito:', error);
    }
  };

  // Manejo de “Mover a columna CRM”
  const handleMoveToColumn = async (thread, targetColumnId) => {
    if (!currentUser) return;
    try {
      const { messageHistory, ...threadWithoutMessages } = thread;
      const threadToMove = {
        ...threadWithoutMessages,
        createdAt: thread.createdAt || new Date().toISOString(),
      };
      const columnRef = doc(db, 'crm', targetColumnId);
      const columnSnap = await getDoc(columnRef);
      if (!columnSnap.exists()) {
        throw new Error('Columna no encontrada');
      }
      const columnData = columnSnap.data();
      const updatedThreads = [...(columnData.threads || [])];
      const threadIndex = updatedThreads.findIndex((t) => t.threadId === threadToMove.threadId);
      if (threadIndex === -1) {
updatedThreads.unshift(threadToMove);
      } else {
        updatedThreads[threadIndex] = threadToMove;
      }
      await updateDoc(columnRef, {
        threads: updatedThreads,
        lastUpdatedBy: currentUser.uid,
      });
      alert(`Chat movido a ${columnData.name}`);
    } catch (error) {
      console.error('Error al mover thread:', error);
      alert('Error al mover chat.');
    } finally {
      setIsMoveMenuOpen(null);
    }
  };

  const renderMoveDropdown = (thread) => {
    if (!isMoveMenuOpen || isMoveMenuOpen !== thread.threadId) return null;
    return (
      <div className="order-dropdown-messages">
        <div className="move-dropdown-content">
          {crmColumns.map((column) => (
            <div
              key={column.id}
              className="move-option"
              onClick={() => handleMoveToColumn(thread, column.id)}
            >
              {column.name}
            </div>
          ))}
        </div>
      </div>
    );
  };

  
  // Manejo de archivos
  const handleFileIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles(files);
    const newPreviews = files.map((file) => {
      if (file.type.startsWith('image/')) {
        return { type: 'image', url: URL.createObjectURL(file), name: file.name };
      }
      if (file.type.startsWith('video/')) {
        return { type: 'video', url: URL.createObjectURL(file), name: file.name };
      }
      return { type: 'document', url: URL.createObjectURL(file), name: file.name };
    });
    setPreviewURLs(newPreviews);
  };

  const openPreviewModal = (index) => {
    setCurrentPreviewIndex(index);
    setIsModalOpen(true);
  };
  const closePreviewModal = () => {
    setIsModalOpen(false);
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
    const updatedPreviews = [...previewURLs];
    updatedPreviews.splice(index, 1);
    setPreviewURLs(updatedPreviews);
  };

  // Manejador del input de búsqueda local
  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  // Manejador de Búsqueda Global
  const handleGlobalSearch = async () => {
    if (!globalSearchTerm.trim()) {
      setGlobalSearchResults([]);
      return;
    }
    setIsGlobalSearchLoading(true);
    try {
      const searchTermLower = globalSearchTerm.toLowerCase();
      const matching = threads.filter((th) =>
        th.messageHistory.some((m) => m.content.toLowerCase().includes(searchTermLower))
      );
      setGlobalSearchResults(matching);
    } catch (error) {
      console.error('Error en búsqueda global:', error);
    } finally {
      setIsGlobalSearchLoading(false);
    }
  };

  // Búsqueda dentro del thread
  const handleMessageSearch = () => {
    if (!searchQuery.trim()) return;
    const lowerCaseQuery = searchQuery.toLowerCase();
    const results = [];
    selectedThread.messageHistory.forEach((message, i) => {
      if (message.content.toLowerCase().includes(lowerCaseQuery)) {
        results.push(i);
      }
    });
    if (results.length > 0) {
      setSearchResults(results);
      setCurrentSearchIndex(0);
      const firstIndex = results[0];
      if (messageRefs.current[firstIndex]?.current) {
        messageRefs.current[firstIndex].current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    } else {
      alert('No se encontró el término de búsqueda');
      setSearchResults([]);
      setCurrentSearchIndex(0);
    }
  };

  const goToNextSearchResult = () => {
    if (searchResults.length === 0) return;
    const nextIndex = (currentSearchIndex + 1) % searchResults.length;
    setCurrentSearchIndex(nextIndex);
    const msgIndex = searchResults[nextIndex];
    if (messageRefs.current[msgIndex]?.current) {
      messageRefs.current[msgIndex].current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const goToPreviousSearchResult = () => {
    if (searchResults.length === 0) return;
    const prevIndex = (currentSearchIndex - 1 + searchResults.length) % searchResults.length;
    setCurrentSearchIndex(prevIndex);
    const msgIndex = searchResults[prevIndex];
    if (messageRefs.current[msgIndex]?.current) {
      messageRefs.current[msgIndex].current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  // Scroll automático al final
  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [selectedThread?.messageHistory]);

  useEffect(() => {
    function handleClickOutsideDropdowns(e) {
      // Si el click NO ocurrió dentro de un elemento con la clase "order-dropdown-messages"
      // ni dentro de uno con la clase "move-dropdown-content", entonces cierra ambos dropdowns.
      if (
        !e.target.closest('.order-dropdown-messages') &&
        !e.target.closest('.move-dropdown-content')
      ) {
        setIsOrderDropdownOpen(false);
        setCrmDropdownOpenThreadId(null);
        setHoveredCrmThreadId(null);
      }
    }
    document.addEventListener('click', handleClickOutsideDropdowns);
    return () => {
      document.removeEventListener('click', handleClickOutsideDropdowns);
    };
  }, []);
  
  

  // =================== PLANTILLAS ====================

  const checkIfTemplateExists = async () => {
    if (!selectedThread?.assistantId) return;
    setCheckingTemplate(true);
    try {
      const response = await fetch('https://backend.thethingapp.com/check-template', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ assistantId: selectedThread.assistantId }),
      });
      const data = await response.json();
      if (data.success) {
        setTemplateExists(data.exists);
      } else {
        console.error('Error al verificar plantilla:', data.error);
      }
    } catch (error) {
      console.error('Error en checkIfTemplateExists:', error);
    } finally {
      setCheckingTemplate(false);
    }
  };

  const createTemplate = async () => {
    if (!selectedThread?.assistantId) return;
    if (!window.confirm('¿Crear la plantilla "contacto"?')) return;
    setCreatingTemplate(true);
    try {
      const response = await fetch('https://backend.thethingapp.com/create-template', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ assistantId: selectedThread.assistantId }),
      });
      const data = await response.json();
      if (data.success) {
        alert('Plantilla "Contacto" creada con éxito.');
        setTemplateExists(true);
      } else {
        console.error('Error al crear plantilla:', data.error);
        alert('Error al crear plantilla');
      }
    } catch (error) {
      console.error('Error en createTemplate:', error);
      alert('Hubo un error');
    } finally {
      setCreatingTemplate(false);
    }
  };

  // Ejemplo: Llamada repetida a tu endpoint “create-template” con nombre y body distinto.
const createDefaultTemplates = async () => {
  if (!selectedThread?.assistantId) {
    alert('No hay assistantId');
    return;
  }

  setIsCreatingDefaults(true); // empieza el spinner

  const defaultTemplates = [
    { name: 'tt_contacto', bodyText: '¡Hola!☺️ ¿Cómo estás?' },
    { name: 'tt_en_linea', bodyText: '¡Hola! Estamos en línea☺️' },
    { name: 'tt_que_necesitas', bodyText: '¡Hola! ¿Qué necesitas hoy?' },
  ];

  setCreatingTemplate(true);
  

  try {
    for (const tpl of defaultTemplates) {
      await fetch('https://backend.thethingapp.com/create-template', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          assistantId: selectedThread.assistantId,
          name: tpl.name,     // por ej. "tt_en_linea"
          bodyText: tpl.bodyText,  // el texto que deseas
        }),
      });
      // No capturamos la respuesta a detalle, asumiendo que tu backend retorna success/error
    }
    alert('Se han creado las plantillas predeterminadas.');
    // Finalmente, recarga la lista de plantillas para que aparezcan en el dropdown
    fetchAllTemplates();
  } catch (err) {
    console.error('Error al crear plantillas predeterminadas:', err);
    alert('Error al crear plantillas predeterminadas');
  } finally {
    setIsCreatingDefaults(false); // deja de mostrar spinner
  }
};

// Mapeo de nombre => texto
const defaultBodyMap = {
  tt_contacto: '¡Hola!☺️ ¿Cómo estás?',
  tt_en_linea: '¡Hola! Estamos en línea☺️',
  tt_que_necesitas: '¡Hola! ¿Qué necesitas hoy?',
};


  // Listar plantillas
  const fetchAllTemplates = async () => {
    if (!selectedThread?.assistantId) return;
    try {
      const response = await fetch('https://backend.thethingapp.com/list-templates', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ assistantId: selectedThread.assistantId }),
      });
      const data = await response.json();
      if (data.success && Array.isArray(data.templates)) {
        setAvailableTemplates(data.templates);
      } else {
        console.error('Error al obtener plantillas:', data.error);
      }
    } catch (error) {
      console.error('Error en fetchAllTemplates:', error);
    }
  };

  useEffect(() => {
    if (selectedThread) {
      checkIfTemplateExists();
      fetchAllTemplates();
    }
  }, [selectedThread]);

  // ============ Contador de plantillas sin respuesta <24h ============

  // 1) Cada vez que enviamos una plantilla, incrementamos si no ha respondido
  //   y guardamos la fecha de esa plantilla (lastTemplateAt).
  const incrementTemplateCountNoReply = async () => {
    if (!selectedThread) return;
    try {
      const threadRef = doc(db, `threads_${selectedThread.assistantId}`, selectedThread.threadId);
      const snap = await getDoc(threadRef);
      if (!snap.exists()) return;

      const data = snap.data();
      let currentCount = data.templateCountNoReply24h || 0;
      // Solo incrementamos si el usuario no acaba de responder
      // (Para simplificar, no revisamos más condiciones, asumimos que no ha respondido).
      currentCount += 1;
      await updateDoc(threadRef, {
        templateCountNoReply24h: currentCount,
        lastTemplateAt: new Date().toISOString(),
      });
    } catch (error) {
      console.error('Error incrementando templateCountNoReply24h:', error);
    }
  };

  // 2) Enviar la plantilla
  const handleSendTemplate = async (tplName = selectedTemplate) => {
    if (!selectedThread?.threadId || !selectedThread.phoneNumber) {
      alert('Falta información');
      return;
    }
    if (!selectedAgent || !selectedAgent.phone_number_id) {
      alert('Asistente no disponible');
      return;
    }
    // Formato E.164
    const formattedPhone = selectedThread.phoneNumber.startsWith('+')
      ? selectedThread.phoneNumber
      : `+${selectedThread.phoneNumber}`;

    try {
      setIsSending(true);
      const sendResponse = await fetch('https://backend.thethingapp.com/send-template', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          phoneNumber: formattedPhone,
          assistantId: selectedThread.assistantId,
          threadId: selectedThread.threadId,
          templateName: tplName,
          languageCode: 'es',
        }),
      });
      const sendData = await sendResponse.json();
      if (!sendData.success) {
        console.error('Error al enviar plantilla:', sendData.error);
        alert('Error al enviar la plantilla');
        return;
      }
      await incrementTemplateCountNoReply();

    } catch (error) {
      console.error('Error en handleSendTemplate:', error);
      alert('Hubo un error al enviar la plantilla.');
    } finally {
      setIsSending(false);
      setNewMessage('');
    }
  };

  // =========== Enviar mensaje normal =============
  const getMessageType = (mimeType) => {
    if (mimeType.startsWith('image/')) return 'image';
    if (mimeType.startsWith('video/')) return 'video';
    if (mimeType.startsWith('audio/')) return 'audio';
    return 'document';
  };

  const MAX_FILE_SIZE = {
    image: 5,
    video: 16,
    audio: 16,
    document: 100,
  };

  const agregarMensajeAlHistorial = (role, contenido) => {
    if (!selectedThread) return;
    const nuevoMensaje = {
      role,
      content: contenido,
      createdAt: new Date().toISOString(),
      messageType: 'text',
    };
    setSelectedThread((prev) => ({
      ...prev,
      messageHistory: [...prev.messageHistory, nuevoMensaje],
    }));
  };

  const handleSendMessage = async () => {
    if (remainingConversations <= 0) {
      setIsLimitReached(true);
      setShowConversationLimitModal(true);
      return;
    }
    // Si HAY una plantilla elegida => envía esa plantilla en lugar del mensaje normal
  if (chosenTemplate) {
    // Llama a tu lógica de envío de plantilla
    await handleSendTemplate(chosenTemplate);
    
    // Limpia el estado para no volverla a enviar accidentalmente
    setChosenTemplate(null);
    // Salimos de la función
    return;
  }
    const messageToSend = newMessage.trim();
    setNewMessage('');
    setIsSending(true);

    try {
      if (!selectedThread) return;

      if (!messageToSend && selectedFiles.length === 0) {
        setIsSending(false);
        return;
      }
      if (selectedThread.phoneNumber) {
        // WhatsApp
        if (selectedFiles.length > 0) {
          // Envío de media
          for (const file of selectedFiles) {
            const type = getMessageType(file.type);
            const sizeMB = file.size / (1024 * 1024);
            if (sizeMB > MAX_FILE_SIZE[type]) {
              agregarMensajeAlHistorial('system', `El archivo "${file.name}" excede ${MAX_FILE_SIZE[type]} MB.`);
              setIsSending(false);
              return;
            }
          }
          const formData = new FormData();
          formData.append('assistantId', selectedThread.assistantId);
          formData.append('phoneNumber', selectedThread.phoneNumber);
          if (messageToSend) {
            formData.append('caption', messageToSend);
          }
          selectedFiles.forEach((f) => formData.append('files', f));
          const resp = await fetch('https://backend.thethingapp.com/send-media', {
            method: 'POST',
            body: formData,
          });
          const data = await resp.json();
          if (!data.success) {
            console.error('Error al enviar media:', data.error);
            agregarMensajeAlHistorial('system', 'Error al enviar archivo.');
          }
          setSelectedFiles([]);
          setPreviewURLs([]);
          setNewMessage('');
        } else {
          // Mensaje de texto
          const resp = await fetch('https://backend.thethingapp.com/send-message', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              phoneNumber: selectedThread.phoneNumber,
              message: messageToSend,
              assistantId: selectedThread.assistantId,
            }),
          });
          const data = await resp.json();
          if (!data.success) {
            console.error('Error al enviar mensaje:', data.error);
            agregarMensajeAlHistorial('system', 'Error al enviar mensaje.');
          }
        }
      } else {
        // Chat Web
        const resp = await fetch('https://backend.thethingapp.com/api/save-web-chat-thread', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            passKey: assistantPassKey,
            assistantId: selectedThread.assistantId,
            threadId: selectedThread.threadId,
            messages: [{ role: 'assistant', content: messageToSend }],
          }),
        });
        const data = await resp.json();
        if (!data.success) {
          console.error('Error al guardar mensaje manual:', data.error);
          agregarMensajeAlHistorial('system', 'Error al guardar el mensaje.');
        }
      }
    } catch (error) {
      console.error('Error al enviar mensaje:', error);
      agregarMensajeAlHistorial('system', 'Hubo un error.');
    } finally {
      setIsSending(false);
    }
  };

  // Toggle switch auto response
  const finalAutoResponseEnabled = globalAutoResponseEnabled && isAutoResponseEnabled;

  const toggleAutoResponse = async () => {
// Si la IA Global está OFF, mostramos un aviso o evitamos el toggle
if (!globalAutoResponseEnabled) {
  alert('La respuesta automática GLOBAL está desactivada.');
  return;
}

    if (remainingConversations <= 0) {
      alert('No te quedan conversaciones.');
      return;
    }
    const newVal = !isAutoResponseEnabled;
    setIsAutoResponseEnabled(newVal);
    if (selectedThread) {
      try {
        const resp = await fetch('https://backend.thethingapp.com/update-thread-status', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            threadId: selectedThread.threadId,
            autoResponseEnabled: newVal,
            assistantId: selectedThread.assistantId,
          }),
        });
        const data = await resp.json();
        if (!data.success) {
          console.error('Error al actualizar autoResponse:', data.error);
        }
      } catch (error) {
        console.error('Error al conectar con backend:', error);
      }
    }
  };

  // Auxiliares
  const formatDate = (dateString) => {
    const options = { hour: '2-digit', minute: '2-digit', day: '2-digit', month: '2-digit' };
    return new Date(dateString).toLocaleString('es-ES', options).replace(',', '');
  };

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return '';
    // Ajusta según tu país
    const countryCode = phoneNumber.slice(0, 2);
    const areaCode = phoneNumber.slice(2, 3);
    const cityCode = phoneNumber.slice(3, 5);
    const firstPart = phoneNumber.slice(5, 9);
    const secondPart = phoneNumber.slice(9);
    return `+${countryCode} ${areaCode} ${cityCode} ${firstPart} ${secondPart}`;
  };

  const getPastelColor = (text) => {
    let hash = 0;
    for (let i = 0; i < text.length; i++) {
      hash = text.charCodeAt(i) + ((hash << 5) - hash);
      hash &= hash;
    }
    const hue = Math.abs(hash) % 360;
    const saturation = 40 + (Math.abs(hash) % 21);
    const lightness = 80 + (Math.abs(hash) % 6);
    const c = (1 - Math.abs((2 * lightness) / 100 - 1)) * (saturation / 100);
    const x = c * (1 - Math.abs(((hue / 60) % 2) - 1));
    const m = lightness / 100 - c / 2;
    let r, g, b;
    if (hue >= 0 && hue < 60) { r = c; g = x; b = 0; }
    else if (hue >= 60 && hue < 120) { r = x; g = c; b = 0; }
    else if (hue >= 120 && hue < 180) { r = 0; g = c; b = x; }
    else if (hue >= 180 && hue < 240) { r = 0; g = x; b = c; }
    else if (hue >= 240 && hue < 300) { r = x; g = 0; b = c; }
    else { r = c; g = 0; b = x; }
    const R = Math.round((r + m) * 255);
    const G = Math.round((g + m) * 255);
    const B = Math.round((b + m) * 255);
    return `rgba(${R}, ${G}, ${B}, 0.7)`;
  };

  const getInitials = (name) => {
    const parts = name.trim().split(' ');
    if (parts.length === 1) return parts[0].charAt(0).toUpperCase();
    return (parts[0].charAt(0) + parts[parts.length - 1].charAt(0)).toUpperCase();
  };

  const formatMessageContent = (content, highlight = null) => {
    let formatted = content;
    if (highlight) {
      const regex = new RegExp(`(${highlight})`, 'gi');
      formatted = formatted.replace(regex, '<span class="highlight">$1</span>');
    }
    // Reemplazar markdown
    formatted = formatted.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    formatted = formatted.replace(/\*(.*?)\*/g, '<strong>$1</strong>');
    // etc...
    const urlRegex = /(https?:\/\/[^\s)]+)([.,;:)\?!]+)?/g;
    formatted = formatted.replace(urlRegex, (match, p1, p2) => {
      return `<a href="${p1}" target="_blank" rel="noopener noreferrer">${p1}</a>${p2 || ''}`;
    });
    formatted = formatted.replace(/\n/g, '<br>');
    return formatted;
  };

  // El input queda bloqueado si:
// - olderThan24h (pasaron 24hs) o
// - isAwaitingReply (awaitingReply) o
// - se envió exactamente 1 plantilla en <24hs
// (lógica: en esos casos, solo se pueden enviar plantillas)
const canWriteFreeText = !olderThan24h &&
!isAwaitingReply &&
selectedThread?.templateCountNoReply24h !== 1;

const canClickSendButton = !freezeFaFile && !isLimitReached;
// freezeFaFile => (templateCountNoReply24h >= 2) = Bloqueo total 
// isLimitReached => sin conversaciones
// Y si olderThan24h o templateCountNoReply24h === 1 => SÍ podemos seguir enviando plantillas
// => No lo difumines en esos casos


const shouldBlurInput = freezeFaFile;

const isInputDisabled = freezeFaFile || !canWriteFreeText;

function renderThreadOptionsDropdown(thread) {
  if (thread.threadId !== isMoveMenuOpen) return null;

  return (
    <div className="order-dropdown-messages">
      {/* Opción 1: Marcar como no leído */}
      <div
  className="option"
  onClick={(e) => {
    e.stopPropagation();
    markThreadAsUnread(thread); 
  }}
>
  Marcar como no leído
</div>


      {/* Opción 2: Eliminar chat */}
      <div
  className="option"
  onClick={(e) => {
    e.stopPropagation();
    handleDeleteThread(thread.threadId);
  }}
>
  {/* Ícono + texto */}
  <FontAwesomeIcon icon={faTrashAlt} className="trash-icon" />
  <span style={{ marginLeft: '6px' }}>Eliminar chat</span>
</div>


      {/* Opción 3: Enviar al CRM */}
      <div
        className="option has-submenu"
        onClick={(e) => {
          e.stopPropagation();
          setCrmDropdownOpenThreadId(prev => (prev === thread.threadId ? null : thread.threadId));
        }}
        onMouseEnter={() => setHoveredCrmThreadId(thread.threadId)}
        onMouseLeave={() => setHoveredCrmThreadId(null)}
      >
        <span>Enviar al CRM</span>
        <div
          className="move-dropdown-content"
          style={{
            display:
              crmDropdownOpenThreadId === thread.threadId || hoveredCrmThreadId === thread.threadId
                ? 'block'
                : 'none'
          }}
        >
          {crmColumns.map((column) => (
            <div
              key={column.id}
              className="move-option"
              onClick={(e) => {
                e.stopPropagation();
                handleMoveToColumn(thread, column.id);
              }}
            >
              {column.name}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}




  
return (
  <div className="threads-viewer">
    {(loadingAgents || (loadingThreads && showLoading)) ? (
      <div className="loading" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',
        width: '100%', height: 'calc(100vh - 100px)', fontSize: 'xx-large' }}>
        Cargando...
      </div>
    ) : noAgents ? (
      <div className="no-agent-message" style={{ flex: 1, display: 'flex',
        justifyContent: 'center', alignItems: 'center', fontSize: 'large', textAlign: 'center', padding: '1em' }}>
        Para ver los mensajes debe crear un agente y conectarlo a su web o a su número de WhatsApp.
      </div>
    ) : (
      <>
        {/* LISTA DE THREADS */}
        <div className="threads-list" style={{
          display: windowWidth <= 1000 ? (showMobileThreadList ? 'flex' : 'none') : 'flex',
        }}>
          {/* Encabezado de la lista de hilos */}
          <div className="threads-list-header">
            {/* SECTION IZQUIERDA */}
            <div className="header-left">
              {isGlobalSearchOpen ? (
                /* Si la búsqueda global está abierta => mostramos el input y botones */
                <div className="global-search-container">
                  <input
                    type="text"
                    className="global-search-input"
                    placeholder="Buscar en todos los chats..."
                    value={globalSearchTerm}
                    onChange={(e) => setGlobalSearchTerm(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') handleGlobalSearch();
                    }}
                    aria-label="Buscar en todos los chats"
                  />
                  <button onClick={handleGlobalSearch} className="global-search-button">
                    Buscar
                  </button>
                  <button
                    className="global-search-close-button"
                    onClick={() => setIsGlobalSearchOpen(false)}
                    aria-label="Cerrar búsqueda global"
                  >
                    ×
                  </button>
                </div>
              ) : (
                /* Si NO está abierta la búsqueda => icono + toggle de Pendientes */
                <>
                  <div
                    className="global-search-icon-wrapper"
                    onClick={() => setIsGlobalSearchOpen(!isGlobalSearchOpen)}
                    title="Buscar en todos los chats"
                  >
                    <FontAwesomeIcon icon={faSearch} className="search-icon" />
                  </div>
                  <span>Pendientes</span>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={showOnlyUnread}
                      onChange={() => setShowOnlyUnread(!showOnlyUnread)}
                    />
                    <span className="slider pending-slider"></span>
                  </label>
                </>
              )}
            </div>

            {/* SECTION DERECHA: Filtros (Ordenar + Ícono Filtro) siempre visibles */}
            <div 
              className="header-right"
              style={{
                // Si es mobile (<=1000px) y está abierto el menú Order (isOrderDropdownOpen)
                // entonces "display: none", de lo contrario "display: flex"
                display: windowWidth <= 1000 && isGlobalSearchOpen ? 'none' : 'flex'
              }}
            >
              <div ref={orderContainerRef} className="order-filter-wrap">
                {/* Dropdown para "Ordenar" */}
                <div className="order-dropdown-wrapper">
                  <div
                    className="order-filter-messages"
                    onClick={() => {
                      setIsOrderDropdownOpen(!isOrderDropdownOpen);
                      setIsFilterDropdownOpen(false);
                    }}
                  >
                    <span className="order-filter-text">Ordenar</span>
                    <FontAwesomeIcon icon={faCaretDown} className="caret-icon" />
                  </div>
                  {isOrderDropdownOpen && (
                    <div className="order-dropdown-messages">
                      <div
                        onClick={() => {
                          setOrderBy('name');
                          setIsOrderDropdownOpen(false);
                        }}
                      >
                        Nombre
                      </div>
                      <div
                        onClick={() => {
                          setOrderBy('date');
                          setIsOrderDropdownOpen(false);
                        }}
                      >
                        Fecha de creación
                      </div>
                      <div
                        onClick={() => {
                          setOrderBy('lastMessage');
                          setIsOrderDropdownOpen(false);
                        }}
                      >
                        Fecha de último mensaje
                      </div>
                    </div>
                  )}
                </div>

                {/* Dropdown para "Filtrar" */}
                <div className="filter-dropdown-wrapper" ref={filterDropdownRef}>
                  <div
                    className="filter-icon-wrapper"
                    onClick={() => {
                      setIsFilterDropdownOpen(!isFilterDropdownOpen);
                      setIsOrderDropdownOpen(false);
                    }}
                  >
                    <FontAwesomeIcon icon={faSliders} className="filter-icon" />
                  </div>
                  {isFilterDropdownOpen && (
                    <div className="order-dropdown-messages">
                      <div
                        onClick={() => {
                          // Filtro de favoritos
                          if (filter === 'favorites') {
                            setFilter(null);
                          } else {
                            setFilter('favorites');
                          }
                          setIsFilterDropdownOpen(false);
                        }}
                        style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                      >
                        <span>Destacado</span>
                        {filter === 'favorites' && (
                          <span
                            className="remove-filter"
                            style={{ marginLeft: '0.5em', opacity: 0.5 }}
                          >
                            ×
                          </span>
                        )}
                      </div>
                      <span className="filter-date">Fecha:</span>
                      <div className="filter-by-date">
                        <label>Desde</label>
                        <div className="date-wrapper">
                          <input
                            type="date"
                            value={filterStartDate || ''}
                            onChange={(e) => setFilterStartDate(e.target.value)}
                            className="date-input"
                          />
                          {!filterStartDate && (
                            <span className="fake-placeholder">dd/mm/aaaa</span>
                          )}
                        </div>

                        <label>Hasta</label>
                        <div className="date-wrapper">
                          <input
                            type="date"
                            value={filterEndDate || ''}
                            onChange={(e) => setFilterEndDate(e.target.value)}
                            className="date-input"
                          />
                          {!filterEndDate && (
                            <span className="fake-placeholder">dd/mm/aaaa</span>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {(selectedTeamMember || selectedAgent) ? (
            <ul className="threads-list-items">
              {globalSearchTerm.trim() ? (
                isGlobalSearchLoading ? (
                  <li className="loading-global-search">Buscando...</li>
                ) : filteredAndOrderedThreads.length > 0 ? (
                  filteredAndOrderedThreads.map((thread) => (
                    <li key={thread.threadId} className="thread-item" onClick={() => handleThreadClick(thread)}>
                      <div
                        className="favorite-star"
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleFavorite(thread.threadId);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={thread.isFavorite ? faStarSolid : faStarRegular}
                          className="star-icon"
                          style={{
                            color: thread.isFavorite ? '#FFD700' : '#CCCCCC',
                            cursor: 'pointer',
                            fontSize: '1.2em',
                          }}
                        />
                      </div>
                      <div className="thread-info" style={{ display: 'flex', alignItems: 'start' }}>
                        {thread.phoneNumber ? (
                          <div
                            className="avatar-container"
                            style={{ backgroundColor: getPastelColor(thread.senderName || 'Chat Web') }}
                          >
                            <span className="avatar-initials">{getInitials(thread.senderName || 'Chat Web')}</span>
                            <FaWhatsapp className="whatsapp-icon" />
                          </div>
                        ) : (
                          <div className="web-chat-avatar-container">
                            <img src={webChatIcon} alt="Web Chat Icon" className="web-chat-icon" />
                          </div>
                        )}
                        <div className="thread-details-preview" style={{ flex: 1, marginLeft: '10px' }}>
                          <p className="thread-sender-name" style={{ margin: '0' }}>
                            {thread.senderName || 'Chat Web'}
                          </p>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <p
                              className="thread-last-message thread-preview-text"
                              style={{
                                margin: 0, overflow: 'hidden', whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis', maxWidth: '18em', color: '#AC9ED4',
                              }}
                            >
                              {thread.messageHistory[thread.messageHistory.length - 1]?.content || ''}
                            </p>
                            {unreadThreadIds.includes(thread.threadId) && (
                              <span className="unread-dot" style={{
                                marginLeft: '8px', backgroundColor: 'red',
                                borderRadius: '50%', width: '10px', height: '10px',
                              }}></span>
                            )}
                          </div>
                        </div>
                        <span className="thread-date-info" style={{ minWidth: '6em', textAlign: 'right',
                          marginLeft: '10px' }}>
                          {formatDate(thread.messageHistory[thread.messageHistory.length - 1]?.createdAt)}
                        </span>
                        <div
    className="options-thread"
    onClick={(e) => {
      e.stopPropagation();
      // Abre/cierra el dropdown
      setIsMoveMenuOpen(
        isMoveMenuOpen === thread.threadId ? null : thread.threadId
      );
    }}
  >
    <FontAwesomeIcon icon={faEllipsisV} className="options-icon" />
    {/* Renderizar el menú unificado */}
    {renderThreadOptionsDropdown(thread)}
  </div>
                      </div>
                    </li>
                  ))
                ) : (
                  <li className="no-search-results">
                    No se encontraron conversaciones con el término "{globalSearchTerm}".
                  </li>
                )
              ) : (
                filteredThreads.map((thread) => (
                  <li key={thread.threadId} className="thread-item" onClick={() => handleThreadClick(thread)}>
                    <div
                      className="favorite-star"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleFavorite(thread.threadId);
                      }}
                    >
                      <FontAwesomeIcon
                        icon={thread.isFavorite ? faStarSolid : faStarRegular}
                        className="star-icon"
                        style={{
                          color: thread.isFavorite ? '#FFD700' : '#CCCCCC',
                          cursor: 'pointer',
                          fontSize: '1.2em',
                        }}
                      />
                    </div>
                    <div className="thread-info" style={{ display: 'flex', alignItems: 'start' }}>
                      {thread.phoneNumber ? (
                        <div
                          className="avatar-container"
                          style={{ backgroundColor: getPastelColor(thread.senderName || 'Chat Web') }}
                        >
                          <span className="avatar-initials">{getInitials(thread.senderName || 'Chat Web')}</span>
                          <FaWhatsapp className="whatsapp-icon" />
                        </div>
                      ) : (
                        <div className="web-chat-avatar-container">
                          <img src={webChatIcon} alt="Web Chat Icon" className="web-chat-icon" />
                        </div>
                      )}
                      <div className="thread-details-preview" style={{ flex: 1, marginLeft: '10px' }}>
                        <p className="thread-sender-name" style={{ margin: '0' }}>
                          {thread.senderName || 'Chat Web'}
                        </p>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <p
                            className="thread-last-message thread-preview-text"
                            style={{
                              margin: 0, overflow: 'hidden', whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis', maxWidth: '18em', color: '#AC9ED4',
                            }}
                          >
                            {thread.messageHistory[thread.messageHistory.length - 1]?.content || ''}
                          </p>
                          {unreadThreadIds.includes(thread.threadId) && (
                            <span className="unread-dot" style={{
                              marginLeft: '8px', backgroundColor: 'red',
                              borderRadius: '50%', width: '10px', height: '10px',
                            }}></span>
                          )}
                        </div>
                      </div>
                      <span className="thread-date-info" style={{
                        minWidth: '6em', textAlign: 'right', marginLeft: '10px',
                      }}>
                        {formatDate(thread.messageHistory[thread.messageHistory.length - 1]?.createdAt)}
                      </span>
                      <div
    className="options-thread"
    onClick={(e) => {
      e.stopPropagation();
      // Abre/cierra el dropdown
      setIsMoveMenuOpen(
        isMoveMenuOpen === thread.threadId ? null : thread.threadId
      );
    }}
  >
    <FontAwesomeIcon icon={faEllipsisV} className="options-icon" />
    {/* Renderizar el menú unificado */}
    {renderThreadOptionsDropdown(thread)}
  </div>
                    </div>
                  </li>
                ))
              )}
            </ul>
          ) : (
            <div className="no-agent-message" style={{ flex: 1, display: 'flex',
              justifyContent: 'center', alignItems: 'center', fontSize: 'large', textAlign: 'center', padding: '1em' }}>
              <div className="spinner-container">
                <div className="spinner"></div>
              </div>
            </div>
          )}
        </div>

        {/* DETALLE DEL THREAD SELECCIONADO */}
        {(selectedTeamMember || selectedAgent) ? (
          selectedThread ? (
            <div
              className="thread-details"
              style={{
                backgroundImage: `url(${backgroundChat})`,
                overflow: 'hidden',
                backgroundColor: '#010321',
                backgroundSize: 'auto',
                backgroundRepeat: 'repeat',
                backgroundAttachment: 'fixed',
                display: windowWidth <= 1000
                  ? (showMobileThreadList ? 'none' : 'flex')
                  : 'flex',
              }}
            >
              <div className="thread-details-content">
                <div className="thread-info-header">
                  <div className="thread-info-sender">
                    {windowWidth <= 1000 && (
                      <button
                        className="mobile-back-button"
                        onClick={() => setShowMobileThreadList(true)}
                      >
                        <i className="fa fa-arrow-left mobile-arrow-icon-back" aria-hidden="true"></i>
                      </button>
                    )}
                    {(!isSearchOpen || windowWidth > 1000) && (
                      <>
                        {selectedThread.phoneNumber ? (
                          <div
                            className="avatar-header-container"
                            style={{
                              backgroundColor: getPastelColor(selectedThread.senderName || 'Chat Web'),
                            }}
                          >
                            <span className="avatar-header-initials">
                              {getInitials(selectedThread.senderName || 'Chat Web')}
                            </span>
                            <FaWhatsapp className="whatsapp-icon-header" />
                          </div>
                        ) : (
                          <div className="web-chat-avatar-header-container">
                            <img
                              src={webChatIcon}
                              alt="Web Chat Icon"
                              className="web-chat-icon-header"
                            />
                          </div>
                        )}
                        <div className="sender-details">
                          <span className="thread-sender-name">
                            {selectedThread.senderName || 'Chat Web'}
                          </span>
                          {!isSearchOpen && selectedThread.phoneNumber && (
                            <span className="thread-phone-number">
                              {formatPhoneNumber(selectedThread.phoneNumber)}
                            </span>
                          )}
                        </div>
                      </>
                    )}
                  </div>

                  <div className="add-contact">
                    {selectedThread.conversationowner && (
                      <div className="conversation-owner">
                        <strong>Tomó el chat:</strong>
                        <span className="owner-name-threads">
                          {selectedThread.conversationowner}
                        </span>
                      </div>
                    )}
                    {!isSearchOpen && (
                      <div className="auto-response-container">
                        <div className="control-container">
                          <div className="tooltip-left" title="Asignar a miembro">
                            <img
                              src={ControlAgenteIA}
                              alt="Control Agente IA"
                              className="control-agente-ia-icon"
                              onClick={() => setIsTeamModalOpen(true)}
                            />
                          </div>
                          <div
                            className="tooltip-switch"
                            data-tooltip={finalAutoResponseEnabled ? 'IA ON' : 'IA OFF'}
                          >
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={finalAutoResponseEnabled}
                                onChange={toggleAutoResponse}
                              />
                              <span className={`slider-threads ${finalAutoResponseEnabled ? 'on' : 'off'}`} />
                            </label>
                          </div>
                        </div>
                      </div>
                    )}

                    {!isSearchOpen && (
                      <div
                        className="search-icon-wrapper"
                        onClick={() => setIsSearchOpen(!isSearchOpen)}
                        title="Buscar mensajes"
                      >
                        <FontAwesomeIcon icon={faSearch} className="search-icon" />
                      </div>
                    )}
                    {isSearchOpen && (
                      <div className="message-search-container">
                        <input
                          type="text"
                          className="message-search-input"
                          placeholder="Buscar en mensajes..."
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                          onKeyDown={(e) => { if (e.key === 'Enter') handleMessageSearch(); }}
                        />
                        <button className="search-now-button" onClick={handleMessageSearch} title="Buscar">
                          <FontAwesomeIcon icon={faSearch} />
                        </button>
                        {searchResults.length > 0 && (
                          <div className="search-navigation">
                            <button onClick={goToPreviousSearchResult} className="search-nav-button" title="Anterior">
                              &larr;
                            </button>
                            <span className="search-counter">
                              {currentSearchIndex + 1} / {searchResults.length}
                            </span>
                            <button onClick={goToNextSearchResult} className="search-nav-button" title="Siguiente">
                              &rarr;
                            </button>
                          </div>
                        )}
                        <button
                          className="global-search-close-button"
                          onClick={() => setIsSearchOpen(false)}
                          aria-label="Cerrar búsqueda"
                        >
                          ×
                        </button>
                      </div>
                    )}

{selectedThread && !isSearchOpen && (
  <div
    className="options-thread-mobile"
    onClick={(e) => {
      e.stopPropagation();
      setIsMoveMenuOpen(
        selectedThread.threadId === isMoveMenuOpen ? null : selectedThread.threadId
      );
    }}
    style={{ marginLeft: '1em' }}
  >
    <FontAwesomeIcon icon={faEllipsisV} className="options-icon" />
    {/* Aquí llamas la misma función que en la lista */}
    {renderThreadOptionsDropdown(selectedThread)}
  </div>
)}

                  </div>
                </div>

                {/* conversation-owner móvil */}
                {windowWidth <= 1000 && selectedThread.conversationowner && (
                  <div className="conversation-owner-mobile">
                    <strong>Tomó el chat:</strong>
                    <span className="owner-name-threads">
                      {selectedThread.conversationowner}
                    </span>
                  </div>
                )}

                <ul className="message-history" ref={messageHistoryRef}>
                  {selectedThread.messageHistory.map((msg, index) => {
                    const isLast = index === selectedThread.messageHistory.length - 1;
                    return (
                      <li
                        key={index}
                        className={`message-item ${msg.role}`}
                        style={{ display: 'flex',
                          justifyContent: msg.role === 'user' ? 'flex-start' : 'flex-end',
                          marginBottom: '1em' }}
                        ref={isLast ? lastMessageRef : (messageRefs.current[index] || null)}
                      >
                        <div
                          className={`message-content ${msg.role}`}
                          style={{
                            // Si es audio => sin fondo, sin borderRadius, etc.
                            background: msg.messageType === 'audio' ? 'none' : '',
                            borderRadius: msg.messageType === 'audio' ? '0' : '20px',
                            padding: msg.messageType === 'audio' ? '0' : '1.3em 2em',
                            maxWidth: msg.messageType === 'audio' ? '70%' : '45%',
                            alignSelf: msg.role === 'user' ? 'flex-start' : 'flex-end',
                            width: 'fit-content',
                          }}
                        >
                          {(() => {
                            switch (msg.messageType) {
                              case 'image':
                                return (
                                  <div>
                                    <img
                                      src={msg.content}
                                      alt="Imagen recibida"
                                      style={{ maxWidth: '100%', borderRadius: '10px' }}
                                    />
                                    {msg.caption && (
                                      <p style={{ marginTop: '5px', fontStyle: 'italic' }}>{msg.caption}</p>
                                    )}
                                  </div>
                                );
                              case 'audio':
                                return (
                                  <audio controls style={{ width: '100%', minWidth: '300px' }}>
                                    <source src={msg.content} />
                                    Tu navegador no soporta el elemento de audio.
                                  </audio>
                                );
                              case 'video':
                                return (
                                  <video controls style={{ width: '100%', minWidth: '300px' }}>
                                    <source src={msg.content} />
                                    Tu navegador no soporta el elemento de video.
                                  </video>
                                );
                              case 'document':
                                if (msg.content.endsWith('.pdf')) {
                                  return (
                                    <div style={{ width: '100%', height: '500px', overflow: 'auto' }}>
                                      <Document file={msg.content}
                                        onLoadError={(e) => console.error('Error PDF:', e)}>
                                        <Page pageNumber={1} width={600} />
                                      </Document>
                                    </div>
                                  );
                                } else {
                                  const gViewerUrl =
                                    `https://docs.google.com/viewer?embedded=true&url=${encodeURIComponent(msg.content)}`;
                                  return (
                                    <iframe
                                      src={gViewerUrl}
                                      style={{ width: '100%', height: '500px', border: 'none' }}
                                      title="Vista previa documento"
                                    />
                                  );
                                }
                              case 'template':
                                if (!msg.templateContent) return <em>Plantilla (sin contenido)</em>;
                                const { header, body, footer, buttons } = msg.templateContent;
                                return (
                                  <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5em' }}>
                                    {header && <h4 style={{ margin: 0 }}>{header}</h4>}
                                    {body && <p style={{ margin: 0 }}>{body}</p>}
                                    {footer && <small style={{ color: '#888' }}>{footer}</small>}
                                    {Array.isArray(buttons) && buttons.length > 0 && (
                                      <div style={{ display: 'flex', gap: '0.5em', marginTop: '0.5em' }}>
                                        {buttons.map((btn, idx2) => (
                                          <button
                                            key={idx2}
                                            style={{
                                              padding: '0.5em 1em',
                                              borderRadius: '6px',
                                              border: '1px solid #ccc',
                                              cursor: 'pointer',
                                            }}
                                          >
                                            {btn.text || 'Botón'}
                                          </button>
                                        ))}
                                      </div>
                                    )}
                                  </div>
                                );
                              default:
                                return (
                                  <span
                                    className="message-text"
                                    dangerouslySetInnerHTML={{
                                      __html: formatMessageContent(
                                        msg.content,
                                        searchResults.includes(index) && index === searchResults[currentSearchIndex]
                                          ? searchQuery
                                          : null
                                      ),
                                    }}
                                  />
                                );
                            }
                          })()}
                          <span className="message-timestamp" style={{ marginTop: '5px', display: 'block' }}>
                            {formatDate(msg.createdAt)}
                          </span>
                        </div>
                      </li>
                    );
                  })}
                </ul>

                {/* Aviso si pasan 24 hs, etc. */}
                {selectedThread?.phoneNumber && olderThan24h && (
                  <div className="recontact-advice">
                    Para{' '}
                    <span style={{ fontWeight: 600 }}>recontactar</span>{' '}
                    a este usuario, necesitas enviar una{' '}
                    <span style={{ fontWeight: 600 }}>plantilla aprobada por Meta.</span>
                    <br />
                    <p className='meta-warning'>*No disponible si no has agregado un método de pago a tu Meta Business Manager*</p>

                    <strong>
                      Elige una plantilla 
                      <img 
                        src={flechaBaja}
                        alt="Flecha hacia abajo"
                        style={{ width: '12px', marginLeft: '4px', }}
                      />
                    </strong>
                  </div>
                )}

                {selectedThread?.phoneNumber && freezeFaFile && (
                  <div className="recontact-advice" style={{ lineHeight:'1.5em'}}>
                    <strong>¡Alerta!</strong> <br/>
                    Ya le has enviado a este usuario <strong>2 plantillas en 24 horas</strong> sin recibir respuesta.
                    <br/>
                    Hasta que el usuario responda o transcurran 24hs 
                    desde la última plantilla, <strong><u>no se puede volver a recontactar</u>.</strong>
                  </div>
                )}

                {selectedThread?.phoneNumber &&
                  (selectedThread.templateCountNoReply24h === 1) &&
                  !freezeFaFile &&
                  !olderThan24h && (
                    <div className="recontact-advice" style={{padding:'1.5em 1em'}}>
                      Has enviado una plantilla recientemente. 
                      Aún puedes enviar <strong>1 plantilla más</strong> a este contacto.
                    </div>
                  )}

                <div className="new-message-container">
                  {/* Modal Team */}
                  {isTeamModalOpen && (
                    <div className="team-modal-backdrop" onClick={() => setIsTeamModalOpen(false)}>
                      <div className="team-modal-messages" onClick={(e) => e.stopPropagation()}>
                        <h2 className="team-modal-title">Selecciona un Miembro del Equipo</h2>
                        <ul className="team-modal-list">
                          {teamMembers.map((member, index) => {
                            const isSelected = member.id === selectedTeamMemberId; 
                            return (
                              <li
                                key={member.id || index}
                                className="team-modal-item member-option"
                                onClick={() => {
                                  setSelectedTeamMemberId(member.id);
                                  handleSelectTeamMember(member);
                                }}
                              >
                                <div className="member-option-checkbox">
                                  {isSelected && <span>✓</span>}
                                </div>

                                <div className="team-member-info">
                                  <span className="team-member-name">
                                    {member.nombre} {member.apellido}
                                  </span>
                                  {member.cargo && (
                                    <span className="team-member-role">{member.cargo}</span>
                                  )}
                                </div>

                                {/* Botón para quitar propietario */}
                                {selectedThread?.conversationowner === `${member.nombre} ${member.apellido}` && (
                                  <button
                                    className="remove-owner-button"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      removeOwner(selectedThread.threadId, selectedThread.assistantId);
                                    }}
                                    title="Quitar propietario"
                                  >
                                    ×
                                  </button>
                                )}
                              </li>
                            );
                          })}
                        </ul>

                        <button className="team-modal-close-button" onClick={() => setIsTeamModalOpen(false)}>
                          Cerrar
                        </button>
                      </div>
                    </div>
                  )}

                  {/* Íconos de emojis, adjuntos */}
                  <div className="message-icons" style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      src={emojiIconChat}
                      alt="Emoji"
                      className="message-icon"
                      style={{
                        opacity: isChatFrozen ? 0.2 : 1,
                        pointerEvents: isChatFrozen ? 'none' : 'auto',
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (!isChatFrozen) setShowEmojiPicker(!showEmojiPicker);
                      }}
                    />
                    <img
                      src={clipIconChat}
                      alt="Adjuntar"
                      className="message-icon"
                      style={{
                        opacity: isChatFrozen ? 0.2 : 1,
                        pointerEvents: isChatFrozen ? 'none' : 'auto',
                      }}
                      onClick={() => {
                        if (!isChatFrozen) handleFileIconClick();
                      }}
                    />
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: 'none' }}
                      multiple
                      onChange={handleFileChange}
                    />
                  </div>

                  {showEmojiPicker && (
                    <div
                      ref={emojiPickerRef}
                      style={{ position: 'absolute', bottom: '60px', left: '20px', zIndex: 999 }}
                    >
                      <EmojiPicker
                        onEmojiClick={(emojiData) => {
                          setNewMessage((prev) => prev + emojiData.emoji);
                        }}
                      />
                    </div>
                  )}

                  {/* Previews */}
                  {previewURLs.length > 0 && (
                    <div className="file-preview-container">
                      {previewURLs.map((preview, idx) => (
                        <div
                          key={idx}
                          className="file-preview-item"
                          style={{
                            transform: `translate(${idx * 5}px, -${idx * 5}px)`,
                            zIndex: previewURLs.length - idx,
                          }}
                        >
                          {idx === 0 && (
                            <button
                              className="remove-file-button"
                              onClick={() => handleRemoveFile(idx)}
                              aria-label={`Eliminar ${preview.name}`}
                            >
                              ×
                            </button>
                          )}
                          {preview.type === 'image' && (
                            <img
                              src={preview.url}
                              alt={`Preview ${preview.name}`}
                              className="preview-image"
                              onClick={() => openPreviewModal(idx)}
                            />
                          )}
                          {preview.type === 'video' && (
                            <video
                              src={preview.url}
                              className="preview-video"
                              muted
                              onClick={() => openPreviewModal(idx)}
                            />
                          )}
                          {preview.type === 'document' && (
                            <div className="preview-document">
                              <p>{preview.name}</p>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  )}

                  {/* INPUT MENSAJE + ICONO PLANTILLAS */}
                  <div className="input-with-icon-container">
                    <input
                      type="text"
                      className="new-message-input"
                      placeholder="Escribe tu mensaje..."
                      value={newMessage}
                      onChange={(e) => setNewMessage(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') handleSendMessage();
                      }}
                      disabled={isInputDisabled}
                      style={{
                        opacity: isInputDisabled ? 0.3 : 1,
                        pointerEvents: isInputDisabled ? 'none' : 'auto',
                      }}
                    />

                    {/* Ícono FaFile para PLANTILLAS */}
                    {isCreatingDefaults ? (
                      <div className="spinner-plantillas" style={{ width: 26, height: 26, margin: '0 0' }} />
                    ) : (
                      <img
                        src={seleccionarPlantillas}
                        alt="Seleccionar Plantilla"
                        className="file-icon-inside-input"
                        style={{
                          opacity: freezeFaFile  ? 0.2 : 1,
                          pointerEvents: freezeFaFile ? 'none' : 'auto',
                          borderRight: '1px solid rgba(255, 255, 255, 0.3)',
                          paddingRight: '0.5em',
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (!freezeFaFile) {
                            setShowTemplateDropdown(!showTemplateDropdown);
                          }
                        }}
                      />
                    )}

                    {/* Dropdown de plantillas */}
                    {showTemplateDropdown && (
                      <div ref={templateDropdownRef} className="template-dropdown">

                        {/* Opción para usar plantillas predeterminadas */}
                        <div
                          className="template-option"
                          style={{
                            fontStyle: 'italic',
                            color: '#FFF',
                            fontSize: '0.8em',
                            filter: hasAllDefaultTemplates ? 'blur(1px)' : 'none',
                            cursor: hasAllDefaultTemplates ? 'not-allowed' : 'pointer',
                            pointerEvents: hasAllDefaultTemplates ? 'none' : 'auto',
                            opacity: hasAllDefaultTemplates ? 0.5 : 1
                          }}
                          onClick={() => {
                            if (hasAllDefaultTemplates) return;
                            const ok = window.confirm('¿Deseas crear las plantillas predeterminadas?');
                            if (!ok) return;
                            setShowTemplateDropdown(false);
                            createDefaultTemplates();
                          }}
                        >
                          Solicitar predeterminadas
                        </div>

                        <hr style={{ margin: '0.5em 0' }} />

                        {availableTemplates.map((tpl) => (
                          <div
                            key={tpl.name}
                            className="template-option"
                            onClick={() => {
                              if (chosenTemplate === tpl.name) {
                                // Des-seleccionar
                                setChosenTemplate(null);
                                setNewMessage('');
                                setShowTemplateDropdown(true);
                              } else {
                                // Seleccionar
                                setChosenTemplate(tpl.name);
                                if (defaultBodyMap[tpl.name]) {
                                  setNewMessage(defaultBodyMap[tpl.name]);
                                } else {
                                  setNewMessage('texto de tu plantilla');
                                }
                              }
                            }}
                          >
                            <div className="template-option-checkbox">
                              {chosenTemplate === tpl.name && <span>✓</span>}
                            </div>
                            <span>{tpl.name}</span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>

                  {/* Botón de enviar */}
                  <button
                    className="send-message-button"
                    onClick={handleSendMessage}
                    disabled={!canClickSendButton || isSending}
                    style={{
                      opacity: canClickSendButton ? 1 : 0.2,
                      pointerEvents: canClickSendButton ? 'auto' : 'none',
                    }}
                  >
                    {isSending ? (
                      <div className="spinner" aria-label="Enviando mensaje"></div>
                    ) : (
                      <img src={paperClipButton} alt="Enviar" className="send-button-image" />
                    )}
                  </button>

                </div>
              </div>
            </div>
          ) : (
            <div className="no-thread-selected" style={{ flex: 1 }} />
          )
        ) : (
          <div className="no-agent-selected" style={{ flex: 1 }} />
        )}
      </>
    )}

    {/* Aquí, al final, los modales que van *afuera* de .thread-details */}
    {showTiendaNubeModal && (
      <div className="modal-backdrop" onClick={() => setShowTiendaNubeModal(false)}>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <TiendaNubeConnectForm onClose={() => setShowTiendaNubeModal(false)} />
        </div>
      </div>
    )}

    {showConversationLimitModal && (
      <div className="conversation-limit-modal-backdrop">
        <div className="conversation-limit-modal">
          <button
            className="conversation-limit-close-button"
            onClick={() => setShowConversationLimitModal(false)}
          >
            ×
          </button>
          <h2 className="conversation-limit-title">Sin conversaciones disponibles</h2>
          <p className="conversation-limit-text">
            Has agotado tus conversaciones gratuitas. <br />
            Debes comprar más en{' '}
            <a
              href="https://thethingapp.com/precios"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://thethingapp.com/precios
            </a>. <br />
            No podrás enviar ni recibir nuevos mensajes.
          </p>
          <button
            className="conversation-limit-button"
            onClick={() => window.open('https://thethingapp.com/precios', '_blank', 'noopener,noreferrer')}
          >
            Comprar más conversaciones
          </button>
        </div>
      </div>
    )}

    {isModalOpen && (
      <div className="modal-backdrop" onClick={closePreviewModal}>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <button
            className="modal-close-button"
            onClick={closePreviewModal}
            aria-label="Cerrar modal"
          >
            ×
          </button>
          <div className="modal-images-container">
            {previewURLs.map((preview, idx) => (
              <div
                key={idx}
                className={`modal-image-item ${idx === currentPreviewIndex ? 'active' : ''}`}
              >
                {preview.type === 'image' && (
                  <img
                    src={preview.url}
                    alt={`Detalle ${preview.name}`}
                    className="modal-image"
                  />
                )}
                {preview.type === 'video' && (
                  <video src={preview.url} controls className="modal-video" />
                )}
                {preview.type === 'document' && (
                  <div className="modal-document">
                    <p>{preview.name}</p>
                  </div>
                )}
                <button
                  className="remove-file-button-modal"
                  onClick={() => handleRemoveFile(idx)}
                  aria-label={`Eliminar ${preview.name}`}
                >
                  ×
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    )}
  </div>
);

};

export default ThreadsViewer;
