import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useMemo
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './WideSidebar.css';
import { useAuth } from '../context/AuthContext';
import {
  collection,
  doc,
  onSnapshot,
  query,
  where,
  getDocs,
  writeBatch,
  updateDoc
} from 'firebase/firestore';
import { getAllAssistants } from '../services/openaiService';
import { SearchContext } from '../context/SearchContext';
import { SelectedTeamMemberContext } from '../context/SelectedTeamMemberContext';
import useTeamMembers from '../hooks/useTeamMembers'; // <- Aquí obtienes los usuarios reales MKT
import { db } from '../firebase';
import { useParams } from 'react-router-dom';

// Función auxiliar para comparar objetos superficialmente.
// Devuelve true si son "iguales" en keys/values, false si difieren.
function shallowEqualObj(objA, objB) {
  if (objA === objB) return true;
  const aKeys = Object.keys(objA);
  const bKeys = Object.keys(objB);
  if (aKeys.length !== bKeys.length) return false;
  for (let key of aKeys) {
    if (objA[key] !== objB[key]) return false;
  }
  return true;
}

const WideSidebar = ({ isVisible, crmColumns }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const { setSearchTerm } = useContext(SearchContext);
  const { selectedTeamMember, setSelectedTeamMember } = useContext(SelectedTeamMemberContext);

  // Detectar rutas
  const isThreadsPage = location.pathname.startsWith('/messages');
  const isChatPage = location.pathname.startsWith('/chat');
  const isCrmPage = location.pathname === '/crm';
  const isAnalyticsPage = location.pathname === '/analytics' || location.pathname === '/support';
  const isProfilePage = location.pathname === '/profile';

  // Detectar /messages/:assistantId
const messagesMatch = location.pathname.match(/^\/messages\/([^/]+)/);
const currentAssistantId = messagesMatch ? messagesMatch[1] : null;

const [assistantAiEnabled, setAssistantAiEnabled] = useState(true);

  // Detectar si estamos en /bubble/:assistantId
  const bubbleMatch = location.pathname.match(/^\/bubble\/([^/]+)/);
  const isBubblePage = !!bubbleMatch;
  const bubbleAssistantId = isBubblePage ? bubbleMatch[1] : null;

  // Estado local de búsqueda para los agentes
  const [agentSearchTerm, setAgentSearchTerm] = useState('');
  const [filteredAgents, setFilteredAgents] = useState([]);
  const [assistants, setAssistants] = useState([]);
  const [loading, setLoading] = useState(true);

  // Contador de threads por cada asistente (para "Mis agentes")
  const [assistantThreadCounts, setAssistantThreadCounts] = useState({});

  // Hook que retorna los miembros MKT reales
  const { teamMembers, teamMembersWithCounts } = useTeamMembers(currentUser, isThreadsPage);

  // Array con los miembros ficticios
  const [fictitiousTeamMembers, setFictitiousTeamMembers] = useState([]);

  // Contador total (real + ficticio)
  const [allTeamCounts, setAllTeamCounts] = useState({});

  // Para desuscribir listeners de threads (en “Mis agentes”)
  const unsubscribeAssistantsListenersRef = useRef([]);

  // Respuesta IA global
  const [globalAutoResponseEnabled, setGlobalAutoResponseEnabled] = useState(true);

  // -- ESTADO PARA TODOS LOS THREADS unificados (en tiempo real) --
  const [allThreads, setAllThreads] = useState([]); 

  useEffect(() => {
    // Si no hay assistantId en la URL, asume true
    if (!currentAssistantId) {
      setAssistantAiEnabled(true);
      return;
    }
    const docRef = doc(db, 'assistants', currentAssistantId);
    const unsubscribe = onSnapshot(docRef, (snap) => {
      if (snap.exists()) {
        const data = snap.data();
        // autoResponseEnabled es el campo que guardas en "assistants"
        setAssistantAiEnabled(data.autoResponseEnabled ?? true);
      } else {
        setAssistantAiEnabled(true);
      }
    });
    return () => unsubscribe();
  }, [currentAssistantId]);
  
  async function disableAiInAllThreads(assistantId) {
    const threadsRef = collection(db, `threads_${assistantId}`);
    const snapshot = await getDocs(threadsRef);
  
    // En lugar de window.firebase..., utiliza writeBatch(db)
    const batch = writeBatch(db);
  
    snapshot.forEach((docSnap) => {
      batch.update(docSnap.ref, { autoResponseEnabled: false });
    });
  
    await batch.commit();
  }
  
  async function enableAiInAllThreads(assistantId) {
    const threadsRef = collection(db, `threads_${assistantId}`);
    const snapshot = await getDocs(threadsRef);
  
    const batch = writeBatch(db);
  
    snapshot.forEach((docSnap) => {
      batch.update(docSnap.ref, { autoResponseEnabled: true });
    });
  
    await batch.commit();
  }
  const handleToggleAssistantAi = async () => {
    if (!currentAssistantId) {
      alert('No hay assistantId en la URL');
      return;
    }
    const newValue = !assistantAiEnabled;
    setAssistantAiEnabled(newValue);
  
    // 1) Actualizamos el doc(assistants/{currentAssistantId}) 
    await updateDoc(doc(db, 'assistants', currentAssistantId), {
      autoResponseEnabled: newValue,
    });
  
    // 2) Encender/Apagar todos los threads_{assistantId}
    if (!newValue) {
      await disableAiInAllThreads(currentAssistantId);
    } else {
      await enableAiInAllThreads(currentAssistantId);
    }
  };

  
  // ==============================
  // 1) Leer preferencia global IA
  // ==============================
  useEffect(() => {
    if (!currentUser) return;

    const userDocRef = doc(db, 'users', currentUser.uid);
    const unsubscribe = onSnapshot(userDocRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.data();
        if (typeof data.globalAutoResponseEnabled === 'boolean') {
          setGlobalAutoResponseEnabled(data.globalAutoResponseEnabled);
        } else {
          setGlobalAutoResponseEnabled(true);
        }
      }
    });
    return () => unsubscribe();
  }, [currentUser]);

  // Habilitar/deshabilitar la respuesta global
  const handleToggleGlobalAutoResponse = async () => {
    const newValue = !globalAutoResponseEnabled;
    setGlobalAutoResponseEnabled(newValue);
    if (!currentUser) return;
    const docRef = doc(db, 'users', currentUser.uid);
    try {
      await updateDoc(docRef, {
        globalAutoResponseEnabled: newValue,
      });
    } catch (error) {
      console.error('Error al actualizar globalAutoResponseEnabled:', error);
    }
  };

  // =============================================
  // 2) Obtener la lista de asistentes del usuario
  // =============================================
  useEffect(() => {
    const fetchAssistants = async () => {
      if (!currentUser) return;
      setLoading(true);
      try {
        const assistantsData = await getAllAssistants(currentUser.uid);
        setAssistants(assistantsData);
      } catch (error) {
        console.error('Error al obtener asistentes:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchAssistants();
  }, [currentUser]);

  // Al cambiar el texto de búsqueda, filtrar
  useEffect(() => {
    if (agentSearchTerm.trim() === '') {
      setFilteredAgents(assistants);
    } else {
      const term = agentSearchTerm.toLowerCase();
      setFilteredAgents(
        assistants.filter((agent) => agent.name.toLowerCase().includes(term))
      );
    }
  }, [assistants, agentSearchTerm]);

  // ============================
  // (A) Memorizar combinedTeamMembers
  // ============================
  const combinedTeamMembers = useMemo(() => {
    return [
      // Miembros reales (MKT) con su conteo from useTeamMembers
      ...teamMembersWithCounts.map((m) => ({
        ...m,
        isReal: true,
      })),
      // Miembros ficticios
      ...fictitiousTeamMembers.map((fict, idx) => ({
        id: `fict-${idx}`,
        nombre: fict.nombre,
        apellido: fict.apellido,
        cargo: fict.cargo || '',
        email: fict.email || '',
        isReal: false,
      })),
    ];
  }, [teamMembersWithCounts, fictitiousTeamMembers]);

  // ===================================================
  // 3) SUSCRIPCIÓN a TODOS los threads de TODOS los asistentes
  // ===================================================
  useEffect(() => {
    if (!isThreadsPage || !assistants.length) return;

    // Limpiar listeners anteriores
    unsubscribeAssistantsListenersRef.current.forEach((unsub) => unsub());
    unsubscribeAssistantsListenersRef.current = [];

    let aggregatedThreads = [];

    // Por cada asistente, suscribir a threads_{assistant.id}
    assistants.forEach((assistant) => {
      const ref = collection(db, `threads_${assistant.id}`);
      const unsub = onSnapshot(
        ref,
        (snapshot) => {
          // Al cambiar algo en los threads de ESTE asistente
          const assistantThreads = snapshot.docs.map((doc) => ({
            threadId: doc.id,
            ...doc.data(),
            assistantId: assistant.id,
          }));
          // 1) Remover los viejos threads de este assistant
          aggregatedThreads = aggregatedThreads.filter(
            (t) => t.assistantId !== assistant.id
          );
          // 2) Añadir los threads actuales
          aggregatedThreads = [...aggregatedThreads, ...assistantThreads];
          // 3) Actualizar state
          setAllThreads([...aggregatedThreads]);
        },
        (error) => {
          console.error(`Error en snapshot threads_${assistant.id}:`, error);
        }
      );
      unsubscribeAssistantsListenersRef.current.push(unsub);
    });

    return () => {
      unsubscribeAssistantsListenersRef.current.forEach((unsub) => unsub());
      unsubscribeAssistantsListenersRef.current = [];
    };
  }, [assistants, isThreadsPage]);

  // =======================================================
  // 4) Calcular en tiempo real:
  //    - Conteo de threads por Asistente (assistantThreadCounts)
  //    - Conteo de threads por cada Miembro (allTeamCounts)
  // =======================================================
  useEffect(() => {
    // (B) Evitar setState si no cambian realmente
    if (!isThreadsPage || !allThreads.length) {
      // Si no hay threads o no estamos en /messages, ponerlos vacíos
      // pero solo si de verdad han cambiado
      if (!shallowEqualObj(assistantThreadCounts, {})) {
        setAssistantThreadCounts({});
      }
      if (!shallowEqualObj(allTeamCounts, {})) {
        setAllTeamCounts({});
      }
      return;
    }

    // 4.1 Conteo por asistente
    const newAssistantThreadCounts = {};
    assistants.forEach((assistant) => {
      const count = allThreads.filter(
        (t) => t.assistantId === assistant.id
      ).length;
      newAssistantThreadCounts[assistant.id] = count;
    });

    // 4.2 Conteo por miembro (real + ficticio)
    const newAllTeamCounts = {};

    // Para cada thread
    allThreads.forEach((thread) => {
      const owner = thread.conversationowner;
      if (!owner) return; // si no hay asignado, no sumar

      // Buscamos si coincide con un real o un ficticio
      const matched = combinedTeamMembers.find((m) => {
        const full = `${m.nombre} ${m.apellido}`.trim();
        return full === owner.trim();
      });
      if (matched) {
        newAllTeamCounts[matched.id] = (newAllTeamCounts[matched.id] || 0) + 1;
      }
    });

    // Antes de setear, chequear si cambian
    if (!shallowEqualObj(newAssistantThreadCounts, assistantThreadCounts)) {
      setAssistantThreadCounts(newAssistantThreadCounts);
    }
    if (!shallowEqualObj(newAllTeamCounts, allTeamCounts)) {
      setAllTeamCounts(newAllTeamCounts);
    }
  }, [
    allThreads,
    assistants,
    combinedTeamMembers,
    isThreadsPage,
    assistantThreadCounts,
    allTeamCounts
  ]);

  // 5) Inyectar conversationCount en combinedTeamMembers
  const finalCombinedTeamMembers = useMemo(() => {
    return combinedTeamMembers.map((m) => ({
      ...m,
      conversationCount: allTeamCounts[m.id] || 0,
    }));
  }, [combinedTeamMembers, allTeamCounts]);

  // =================================
  // FUNCIONES DE NAVEGACIÓN
  // =================================
  const handleAgentSearchTermChange = (term) => {
    setAgentSearchTerm(term);
  };

  const handleSelectAgent = (agent) => {
    setSelectedTeamMember(null);
    navigate(`/messages/${agent.id}`);
  };

  const handleSelectTeamMemberClick = (member) => {
    setSelectedTeamMember(member);
    navigate(`/messages`); // sin assistantId
  };

  // ====================
  // RENDER principal
  // ====================
  return (
    <div className={`wide-sidebar ${isVisible ? 'visible' : 'hidden'}`}>
      {isBubblePage ? (
        <div className="wide-sidebar-content">
          <h2>Prueba tu agente</h2>
          <ul className="scrollable-list">
            <li
              className="list-item"
              onClick={() => navigate(`/chat/${bubbleAssistantId}`)}
            >
              Volver al entrenamiento
            </li>
          </ul>
        </div>
      ) : (
        <>
          {isThreadsPage ? (
            <div className="wide-sidebar-content">
              <h2 className="titulos-threads">Mis agentes</h2>
              <div className="search-container">
                <input
                  type="text"
                  className="search-input"
                  placeholder="Buscar"
                  value={agentSearchTerm}
                  onChange={(e) => handleAgentSearchTermChange(e.target.value)}
                />
              </div>
              <ul className="scrollable-list">
                {loading ? (
                  <li className="list-item">Cargando...</li>
                ) : filteredAgents.length > 0 ? (
                  filteredAgents.map((agent) => (
                    <li
                      key={agent.id}
                      className="list-item"
                      onClick={() => handleSelectAgent(agent)}
                    >
                      <div className="agent-info">
                        <p className="agent-name">{agent.name}</p>
                        <span className="thread-count">
                          {assistantThreadCounts[agent.id] || 0}
                        </span>
                      </div>
                    </li>
                  ))
                ) : (
                  <li className="list-item">No se encontraron agentes</li>
                )}

                {finalCombinedTeamMembers.length > 0 &&
                  finalCombinedTeamMembers.map((member) => (
                    <li
                      key={`member-${member.id}`}
                      className="list-item"
                      onClick={() => handleSelectTeamMemberClick(member)}
                    >
                      <div className="agent-info">
                        <p className="agent-name">
                          {member.nombre} {member.apellido}
                        </p>
                        <span className="thread-count">
                          {member.conversationCount || 0}
                        </span>
                      </div>
                    </li>
                  ))}
              </ul>

              {/* Switch global IA */}
                {currentAssistantId && (
  <div className="global-autoresponse-switch-container">
                <span className="global-autoresponse-label">Respuesta IA Global</span>
    <label className="switch-ia-global">
      <input
        type="checkbox"
        checked={assistantAiEnabled}
        onChange={handleToggleAssistantAi}
      />
      <span className="slider pending-slider"></span>
    </label>
  </div>
)}

              </div>

          ) : isChatPage ? (
            <div className="wide-sidebar-content">
              <h2>Entrenador <br /> de Agentes</h2>
              <ul className="scrollable-list">
                {loading ? (
                  <li className="list-item">Cargando...</li>
                ) : assistants.length > 0 ? (
                  assistants.map((assistant) => (
                    <li
                      key={assistant.id}
                      className="list-item"
                      onClick={() => navigate(`/chat/${assistant.id}`)}
                    >
                      {assistant.name}
                    </li>
                  ))
                ) : (
                  <li className="list-item">No hay agentes disponibles</li>
                )}
              </ul>
            </div>
          ) : isCrmPage ? (
            <div className="wide-sidebar-content">
              <h2>Panel de <br />seguimiento</h2>
              <div className="search-container">
                <input
                  type="text"
                  className="search-input"
                  placeholder="Buscar por nombre o teléfono"
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
          ) : isAnalyticsPage ? (
            <div className="wide-sidebar-content">
              <h2>Próximamente</h2>
            </div>
          ) : isProfilePage ? (
            <div className="wide-sidebar-content">
              <h2>Perfil</h2>
              <ul className="scrollable-list">
                <li
                  className="list-item"
                  onClick={() => {
                    document.getElementById('conversations-counter')
                      ?.scrollIntoView({ behavior: 'smooth' });
                  }}
                >
                  Conversaciones
                </li>
                <li
                  className="list-item"
                  onClick={() => {
                    document.getElementById('subscription-counter')
                      ?.scrollIntoView({ behavior: 'smooth' });
                  }}
                >
                  Suscripción
                </li>
                <li
                  className="list-item"
                  onClick={() => {
                    document.getElementById('add-member-button')
                      ?.scrollIntoView({ behavior: 'smooth' });
                  }}
                >
                  Equipo
                </li>
              </ul>
            </div>
          ) : (
            <div className="wide-sidebar-content">
              <h2>Comenzar <br /> The Thing</h2>
              <ul className="scrollable-list">
                <li className="list-item">Mis Agentes</li>
                <li className="list-item">Mis Bases de Datos</li>
                <li className="list-item">Tutorial</li>
              </ul>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default WideSidebar;
