// src/context/SubscriptionContext.js

import React, { createContext, useContext, useEffect, useState, useRef } from 'react';
import { useAuth } from './AuthContext';
import { doc, onSnapshot, Timestamp } from 'firebase/firestore';
import { db } from '../firebase';

const SubscriptionContext = createContext();

export const useSubscription = () => {
  return useContext(SubscriptionContext);
};

export const SubscriptionProvider = ({ children }) => {
  const { currentUser } = useAuth();

  // isExpired es el valor "real" que se usa para mostrar / ocultar el modal
  const [isExpired, setIsExpired] = useState(false);
  const [loading, setLoading] = useState(true);

  // Estado temporal para manejar la lógica de "posible expirado"
  // pero sin mostrar enseguida el cartel.
  const [tempExpired, setTempExpired] = useState(false);

  // Guardaremos aquí la referencia al setTimeout para poder limpiarlo
  const debounceRef = useRef(null);

  useEffect(() => {
    // Si no hay usuario logueado, no está expirada.
    if (!currentUser) {
      setIsExpired(false);
      setTempExpired(false);
      setLoading(false);
      return;
    }

    setLoading(true); 

    const userRef = doc(db, 'users', currentUser.uid);
    const unsubscribe = onSnapshot(
      userRef,
      (docSnapshot) => {
        if (!docSnapshot.exists()) {
          // En lugar de marcarlo ya como expirado, lo pasamos a tempExpired
          setTempExpired(true);
          setLoading(false);
          return;
        }

        const data = docSnapshot.data();
        const accessExpiresAt = data.accessExpiresAt;

        // OJO: Verifica que 'accessExpiresAt' sea de tipo Timestamp de Firestore
        // si es un Date nativo, conviene transformarlo a Timestamp o viceversa.
        // Ejemplo: si lo guardaste como Firestore Timestamp,
        // puedes hacer: const expiresDate = accessExpiresAt.toDate();
        // y comparar con new Date(), o usar la hora del servidor:
        // const nowServer = Timestamp.now();

        if (!accessExpiresAt) {
          // No hay campo => suponer expirado, pero primero a tempExpired
          setTempExpired(true);
        } else {
          // Compara con la hora actual (Timestamp.now())
          const nowServer = Timestamp.now();
          if (accessExpiresAt.seconds <= nowServer.seconds) {
            setTempExpired(true);
          } else {
            setTempExpired(false);
          }
        }

        setLoading(false);
      },
      (error) => {
        console.error('Error al obtener datos de suscripción:', error);
        // En caso de error, por seguridad lo marcamos "temporalmente" expirado,
        // pero aplicando el debounce.
        setTempExpired(true);
        setLoading(false);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [currentUser]);

  // Aquí aplicamos el debounce de 500ms:
  useEffect(() => {
    // Primero limpiamos cualquier timeout anterior
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }

    if (tempExpired) {
      // Si tenemos tempExpired = true, esperamos 500ms.
      // Si no cambia en ese tiempo, confirmamos isExpired = true
      debounceRef.current = setTimeout(() => {
        setIsExpired(true);
      }, 500);
    } else {
      // Si tempExpired = false, cancelamos el timeout y ponemos isExpired en false.
      setIsExpired(false);
    }

    // Limpieza si se desmonta el componente
    return () => {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }
    };
  }, [tempExpired]);

  return (
    <SubscriptionContext.Provider value={{ isExpired, loading }}>
      {children}
    </SubscriptionContext.Provider>
  );
};
