// src/components/MobileHeader.jsx
import React, { useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ThemeContext } from '../context/ThemeContext';
import { AssistantContext } from '../context/AssistantContext';
import { useAuth } from '../context/AuthContext';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';
import './HeaderMobile.css';
import bellIcon from '../assets/bell-icon.png';
import WideSidebarMobile from './WideSidebarMobile';
import MobileSidebar from './MobileSidebar';

const MobileHeader = ({ onToggleNotifications, assistants, teamMembers }) => {
  const { isLightMode } = useContext(ThemeContext);
  const { assistantName } = useContext(AssistantContext);
  const { currentUser } = useAuth();
  const location = useLocation();

  // (1) Estados para conversaciones y modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isWideSidebarMobileVisible, setIsWideSidebarMobileVisible] = useState(false);
  const [isMobileSidebarVisible, setIsMobileSidebarVisible] = useState(false);

  const [remainingFreeConversations, setRemainingFreeConversations] = useState(200);
  const [remainingPaidConversations, setRemainingPaidConversations] = useState(0);

  // (2) Nuevo estado para días restantes de suscripción
  const [daysLeftSub, setDaysLeftSub] = useState(0);

  // useEffect para suscribirse al doc de usuario
  useEffect(() => {
    if (!currentUser) {
      console.warn('Usuario no autenticado');
      return;
    }

    const userDocRef = doc(db, 'users', currentUser.uid);
    const unsubscribeUser = onSnapshot(
      userDocRef,
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          const userData = docSnapshot.data();
          const totalThreadsUsed = userData.totalThreads || 0;
          const purchasedThreadsAvailable = userData.purchasedThreads || 0;

          const baseFreeConversations = 200;
          const remainingFree = Math.max(0, baseFreeConversations - totalThreadsUsed);
          const remainingPaid = Math.max(0, purchasedThreadsAvailable);

          setRemainingFreeConversations(remainingFree);
          setRemainingPaidConversations(remainingPaid);

          // (3) Cálculo de los días restantes de suscripción
          if (userData.accessExpiresAt) {
            const expiresDate = userData.accessExpiresAt.toDate();
            const now = new Date();
            const diffMs = expiresDate - now;
            const diffDays = Math.ceil(diffMs / (1000 * 60 * 60 * 24));
            setDaysLeftSub(diffDays > 0 ? diffDays : 0);
          } else {
            setDaysLeftSub(0);
          }
        }
      },
      (error) => {
        console.error('Error en el listener del documento del usuario:', error);
      }
    );

    return () => {
      unsubscribeUser();
    };
  }, [currentUser]);

  // Determinar el "page title" al centro
  let currentAssistantName = 'PAGINA';
  if (location.pathname.startsWith('/messages')) {
    currentAssistantName = 'MIS CHATS';
  } else if (location.pathname.startsWith('/bubble')) {
    currentAssistantName = 'BURBUJA WEB';
  } else if (location.pathname === '/profile') {
    currentAssistantName = 'PERFIL';
  } else if (location.pathname === '/support') {
    currentAssistantName = 'TICKETS';
  } else if (location.pathname === '/crm') {
    currentAssistantName = 'PANEL';
  } else if (location.pathname === '/analytics') {
    currentAssistantName = 'ANALÍTICAS';
  } else {
    const chatPathMatch = location.pathname.match(/^\/chat\/([^/]+)/);
    if (chatPathMatch) {
      currentAssistantName = assistantName;
    } else if (location.pathname === '/') {
      currentAssistantName = 'MIS AGENTES';
    }
  }

  // Alternar modal de notificaciones
  const toggleModal = (e) => {
    e.stopPropagation();
    setIsModalOpen((prev) => !prev);
    if (onToggleNotifications) onToggleNotifications();
  };

  // Al hacer click en el centro => abre/cierra WideSidebarMobile
  const handleCenterClick = () => {
    setIsWideSidebarMobileVisible((prev) => !prev);
  };

  // Botón hamburguesa => abre/cierra MobileSidebar
  const handleHamburgerClick = () => {
    setIsMobileSidebarVisible((prev) => !prev);
  };

  return (
    <>
      <header className={`mobile-header ${isLightMode ? 'light-mode' : 'dark-mode'}`}>
        {/* Botón hamburguesa a la izquierda */}
        <div className={`mobile-header-left ${isMobileSidebarVisible ? 'active-sidebar' : ''}`}>
          <button
            className="hamburger-button"
            onClick={handleHamburgerClick}
            aria-label="Abrir menú"
          >
            <i className="fa fa-bars" aria-hidden="true"></i>
          </button>
        </div>

        {/* Centro: título y flecha */}
        <div className="mobile-header-center" onClick={handleCenterClick}>
          <i className="fa fa-arrow-down mobile-arrow-icon" aria-hidden="true"></i>
          <h1 className="mobile-header-title">{currentAssistantName}</h1>
        </div>

        {/* Derecha: campana notificaciones */}
        <div className="mobile-header-right">
          <button
            className="notification-button"
            onClick={toggleModal}
            aria-label="Abrir notificaciones"
          >
            <img src={bellIcon} alt="Notificaciones" className="bell-icon" />
          </button>

          {/* Modal de Notificaciones */}
          {isModalOpen && (
            <div className="notification-modal-backdrop" onClick={() => setIsModalOpen(false)}>
              <div className="notification-modal" onClick={(e) => e.stopPropagation()}>
                <h2 className="notification-title">Notificaciones</h2>
                <h3 className="notification-subtitle">Conversaciones Restantes:</h3>

                {(remainingFreeConversations + remainingPaidConversations) <= 0 ? (
                  <p className="notification-text">¡Te has quedado sin conversaciones!</p>
                ) : (
                  <p className="notification-text">
                    Te quedan {remainingFreeConversations + remainingPaidConversations} conversaciones en total.
                  </p>
                )}

                {/* (4) Mostrar días restantes en el modal */}
                <h3 className="notification-subtitle">Vencimiento de Suscripción:</h3>
                {daysLeftSub > 0 ? (
                  <p className="notification-text">
                    Te quedan {daysLeftSub} día{daysLeftSub > 1 && 's'} de suscripción
                  </p>
                ) : (
                  <p className="notification-text">
                    ¡Tu suscripción vence hoy o ha vencido!
                  </p>
                )}

              </div>
            </div>
          )}
        </div>
      </header>

      {/* WideSidebarMobile */}
      <WideSidebarMobile
        isVisible={isWideSidebarMobileVisible}
        assistants={assistants}
        teamMembers={teamMembers}
        onClose={() => setIsWideSidebarMobileVisible(false)}
      />

      {/* MobileSidebar */}
      <MobileSidebar
        isVisible={isMobileSidebarVisible}
        onClose={() => setIsMobileSidebarVisible(false)}
      />
    </>
  );
};

export default MobileHeader;
