// Login.js
import React, { useState } from 'react';
import { GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { auth, db } from '../firebase'; // Importar Firestore
import { doc, getDoc } from 'firebase/firestore'; // Importar getDoc para leer documentos
import './Auth.css';
import googleIcon from '../assets/google-icon.png';
import { Link, useNavigate } from 'react-router-dom'; // Importamos useNavigate
import emailIcon from '../assets/email-logo.png';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showEmailForm, setShowEmailForm] = useState(false); // Nuevo estado
  const navigate = useNavigate(); // Inicializamos el hook para la navegación

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Verificar si el usuario tiene API_KEY
      const userDocRef = doc(db, 'users', user.uid);
      const userDocSnap = await getDoc(userDocRef);
 
      if (userDocSnap.exists()) {
        const userData = userDocSnap.data(); 
        if (!userData.API_KEY) {
          // Redirigir a "SolicitarAcceso" si no tiene API_KEY
          navigate('/solicitar-acceso');
        } else {
          // Guardar el usuario en localStorage o manejar la API_KEY si existe
          localStorage.setItem('API_KEY', userData.API_KEY);
          navigate('/messages');
        }
      } else {
        throw new Error('El documento del usuario no existe.');
      }
    } catch (error) {
      // Si el error es por credenciales incorrectas, mostramos un mensaje claro
      if (error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password' || error.code === 'auth/invalid-credential') {
        setError('Error: El correo electrónico o la contraseña son incorrectos.');
      } else {
        // Otros errores (por ejemplo, problemas de red)
        setError('Error al iniciar sesión: ' + error.message);
      }
    }
  };

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // Verificar si el usuario ya está registrado en Firestore
      const userDocRef = doc(db, 'users', user.uid);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        if (!userData.API_KEY) {
          navigate('/solicitar-acceso');
        } else {
          localStorage.setItem('API_KEY', userData.API_KEY);
          navigate('/messages');
        }
      } else {
        setError('Error: El usuario no está registrado. Por favor, regístrate primero.');
      }
    } catch (error) {
      setError('Error al iniciar sesión con Google: ' + error.message);
    }
  };

  const handleShowEmailForm = () => { 
    setShowEmailForm(true);
  };

  return ( 
    <div className="auth-page">
      <div className="auth-container">
        <h2>Iniciar Sesión</h2>

        {!showEmailForm && (
          <>

            <button className="email-button" onClick={handleShowEmailForm}>
            <img src={emailIcon} alt="Email icon" className="email-icon" />
              Continuar con un Email
            </button>

            <p className="auth-text">No tienes una cuenta?</p>
            <button 
  className="auth2-button" 
  onClick={() => window.open('https://thethingapp.com/precios', '_blank', 'noopener,noreferrer')}
  style={{ fontSize: '16px', padding: '12px 40px' }}
>
  Registrate
</button>

          </>
        )}

        {showEmailForm && (
          <>
            <form className="auth-form" onSubmit={handleLogin}>
              <label htmlFor="email" className="auth-label">Email<span className="required">*</span></label>
              <input
                id="email"
                className="auth-input"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Ingresa tu correo electrónico"
                required
              />
              
              <label htmlFor="password" className="auth-label">Contraseña<span className="required">*</span></label>
              <input
                id="password"
                className="auth-input"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Ingresa tu contraseña"
                required
              />
              
              <button type="submit" className="auth-button">Ingresar</button>
            </form>

            <p><Link className="a" to="/reset-password">Olvidé mi contraseña</Link></p>

          </>
        )}



        {error && <p className="error">{error}</p>}
      </div>
    </div>
  );
};

export default Login;
