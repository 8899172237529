// src/components/WideSidebarMobile.jsx

import React, { useState, useEffect, useRef, useContext, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './WideSidebarMobile.css';
import { useAuth } from '../context/AuthContext';
import {
  collection,
  doc,
  onSnapshot,
  query,
  where,
  getDocs,
  updateDoc,
  writeBatch
} from 'firebase/firestore';
import { getAllAssistants } from '../services/openaiService';
import { SearchContext } from '../context/SearchContext';
import { SelectedTeamMemberContext } from '../context/SelectedTeamMemberContext';
import useTeamMembers from '../hooks/useTeamMembers'; // Custom Hook para teamMembers
import { db } from '../firebase';

function WideSidebarMobile({ isVisible, onClose }) {
  const location = useLocation();
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const { setSearchTerm } = useContext(SearchContext);
  const { selectedTeamMember, setSelectedTeamMember } = useContext(SelectedTeamMemberContext);

  // Detectar rutas
  const isThreadsPage = location.pathname.startsWith('/messages');
  const isChatPage = location.pathname.startsWith('/chat');
  const isCrmPage = location.pathname === '/crm';
  const isProfilePage = location.pathname === '/profile';
  const isAnalyticsPage =
    location.pathname === '/analytics' || location.pathname === '/support';

  // Detectar si la ruta es /bubble/assistantId
  const bubbleMatch = location.pathname.match(/^\/bubble\/([^/]+)/);
  const isBubblePage = !!bubbleMatch;
  const bubbleAssistantId = isBubblePage ? bubbleMatch[1] : null;

  // ─────────────────────────────────────────────────
  //  A) DETECTAR /messages/:assistantId
  // ─────────────────────────────────────────────────
  const messagesMatch = location.pathname.match(/^\/messages\/([^/]+)/);
  const currentAssistantId = messagesMatch ? messagesMatch[1] : null;

  // B) Estado local para "IA de este asistente"
  const [assistantAiEnabled, setAssistantAiEnabled] = useState(true);

  // C) Escuchar doc(assistants/{currentAssistantId}) para saber autoResponseEnabled
  useEffect(() => {
    if (!currentAssistantId) {
      setAssistantAiEnabled(true);
      return;
    }
    const assistantRef = doc(db, 'assistants', currentAssistantId);
    const unsub = onSnapshot(assistantRef, (snap) => {
      if (snap.exists()) {
        const data = snap.data();
        setAssistantAiEnabled(data.autoResponseEnabled ?? true);
      } else {
        setAssistantAiEnabled(true);
      }
    });
    return () => unsub();
  }, [currentAssistantId]);

  // D) Funciones para “apagar/encender IA” en *todos los threads* de un assistant
  async function disableAiInAllThreads(assistantId) {
    const threadsRef = collection(db, `threads_${assistantId}`);
    const snap = await getDocs(threadsRef);
    const batch = writeBatch(db);
    snap.forEach((docSnap) => {
      batch.update(docSnap.ref, { autoResponseEnabled: false });
    });
    await batch.commit();
  }

  async function enableAiInAllThreads(assistantId) {
    const threadsRef = collection(db, `threads_${assistantId}`);
    const snap = await getDocs(threadsRef);
    const batch = writeBatch(db);
    snap.forEach((docSnap) => {
      batch.update(docSnap.ref, { autoResponseEnabled: true });
    });
    await batch.commit();
  }

  // E) Al togglear el switch => update doc(assistants/{...}) y setear en threads
  const handleToggleAssistantAi = async () => {
    if (!currentAssistantId) {
      alert('No hay assistantId en la URL.');
      return;
    }
    const newVal = !assistantAiEnabled;
    setAssistantAiEnabled(newVal);

    // 1) Actualizar doc(assistant) con autoResponseEnabled
    await updateDoc(doc(db, 'assistants', currentAssistantId), {
      autoResponseEnabled: newVal
    });

    // 2) Encender/apagar en todos los threads
    if (!newVal) {
      await disableAiInAllThreads(currentAssistantId);
    } else {
      await enableAiInAllThreads(currentAssistantId);
    }
  };

  // ─────────────────────────────────────────────────
  // LÓGICA ORIGINAL
  // ─────────────────────────────────────────────────

  // Para la búsqueda y datos de asistentes
  const [agentSearchTerm, setAgentSearchTerm] = useState('');
  const [filteredAgents, setFilteredAgents] = useState([]);
  const [assistants, setAssistants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [assistantThreadCounts, setAssistantThreadCounts] = useState({});

  const { teamMembers, teamMembersWithCounts } = useTeamMembers(currentUser, isThreadsPage);
  const unsubscribeAssistantsListenersRef = useRef([]);

  // useEffect: cargar asistentes
  useEffect(() => {
    if (!currentUser) return;
    const loadAssistants = async () => {
      setLoading(true);
      try {
        const data = await getAllAssistants(currentUser.uid);
        setAssistants(data);
      } catch (err) {
        console.error('Error al obtener asistentes:', err);
      } finally {
        setLoading(false);
      }
    };
    loadAssistants();
  }, [currentUser]);

  // Filtrar
  useEffect(() => {
    if (!agentSearchTerm.trim()) {
      setFilteredAgents(assistants);
    } else {
      const term = agentSearchTerm.toLowerCase();
      setFilteredAgents(
        assistants.filter((a) => a.name.toLowerCase().includes(term))
      );
    }
  }, [assistants, agentSearchTerm]);

  // listener en tiempo real de threads
  useEffect(() => {
    if (!isThreadsPage || assistants.length === 0) return;

    // limpiar subs anteriores
    unsubscribeAssistantsListenersRef.current.forEach((unsub) => unsub());
    unsubscribeAssistantsListenersRef.current = [];

    assistants.forEach((asst) => {
      const threadsColl = collection(db, `threads_${asst.id}`);
      const unsub = onSnapshot(
        threadsColl,
        (qSnap) => {
          setAssistantThreadCounts((prev) => ({
            ...prev,
            [asst.id]: qSnap.size
          }));
        },
        (error) => {
          console.error('Listener en threads_', asst.id, error);
        }
      );
      unsubscribeAssistantsListenersRef.current.push(unsub);
    });

    return () => {
      unsubscribeAssistantsListenersRef.current.forEach((u) => u());
      unsubscribeAssistantsListenersRef.current = [];
    };
  }, [isThreadsPage, assistants]);

  // Handlers
  const handleAgentSearchChange = (term) => {
    setAgentSearchTerm(term);
  };

  const handleSelectAgent = (agent) => {
    setSelectedTeamMember(null);
    navigate(`/messages/${agent.id}`);
    onClose();
  };

  const handleSelectTeamMemberClick = (member) => {
    setSelectedTeamMember(member);
    navigate(`/messages`);
    onClose();
  };

  const handleChatAgentSelection = (assistantId) => {
    navigate(`/chat/${assistantId}`);
    onClose();
  };

  const scrollToSection = (id) => {
    document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
    onClose();
  };

  // Si es /bubble -> solo eso
  if (isBubblePage) {
    return (
      <div className={`wide-sidebar-mobile ${isVisible ? 'visible' : 'hidden'}`}>
        <div className="wide-sidebar-mobile-content">
          <h2>Agente Personalizado</h2>
          <ul className="scrollable-list">
            <li
              className="list-item-mobile"
              onClick={() => {
                navigate(`/chat/${bubbleAssistantId}`);
                onClose();
              }}
            >
              Volver al entrenamiento
            </li>
          </ul>
        </div>
      </div>
    );
  }

  return (
    <div className={`wide-sidebar-mobile ${isVisible ? 'visible' : 'hidden'}`}>
      {isThreadsPage ? (
        <div className="wide-sidebar-mobile-content">
          <h2 className="titulos-threads-mobile">Mis agentes</h2>

          {/* Campo de búsqueda */}
          <div className="search-container">
            <input
              type="text"
              className="search-input-mobile"
              placeholder="Buscar"
              value={agentSearchTerm}
              onChange={(e) => handleAgentSearchChange(e.target.value)}
            />
          </div>

          {/* Lista de Asistentes */}
          <ul className="scrollable-list">
            {loading ? (
              <li className="list-item-mobile">Cargando...</li>
            ) : filteredAgents.length > 0 ? (
              filteredAgents.map((agent) => (
                <li
                  key={agent.id}
                  className="list-item-mobile"
                  onClick={() => handleSelectAgent(agent)}
                >
                  <div className="agent-info">
                    <p className="agent-name">{agent.name}</p>
                    <span className="thread-count">
                      {assistantThreadCounts[agent.id] || 0}
                    </span>
                  </div>
                </li>
              ))
            ) : (
              <li className="list-item-mobile">No se encontraron agentes</li>
            )}

            {/* Lista de Miembros (equipo MKT) */}
            {teamMembersWithCounts && teamMembersWithCounts.length > 0 ? (
              teamMembersWithCounts.map((member) => (
                <li
                  key={`member-${member.id}`}
                  className="list-item-mobile"
                  onClick={() => handleSelectTeamMemberClick(member)}
                >
                  <div className="agent-info">
                    <p className="agent-name">
                      {`${member.nombre} ${member.apellido}`}
                    </p>
                    <span className="thread-count">
                      {member.conversationCount || 0}
                    </span>
                  </div>
                </li>
              ))
            ) : (
              <li className="list-item-mobile">
                No hay miembros con conversaciones
              </li>
            )}
          </ul>

          {/* SOLO se muestra si hay currentAssistantId en la URL */}
          {currentAssistantId && (
            <div className="global-autoresponse-switch-container-mobile">
              <span className="global-autoresponse-label-mobile">
               Autorespuesta IA Global
              </span>
              <label className="switch-ia-global-mobile">
                <input
                  type="checkbox"
                  checked={assistantAiEnabled}
                  onChange={handleToggleAssistantAi}
                />
                <span className="slider-mobile"></span>
              </label>
            </div>
          )}

        </div>
      ) : isChatPage ? (
        <div className="wide-sidebar-mobile-content">
          <h2>Entrenador de Agentes</h2>
          <ul className="scrollable-list">
            {loading ? (
              <li className="list-item-mobile">Cargando...</li>
            ) : assistants.length > 0 ? (
              assistants.map((assistant) => (
                <li
                  key={assistant.id}
                  className="list-item-mobile"
                  onClick={() => handleChatAgentSelection(assistant.id)}
                  role="button"
                  tabIndex={0}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleChatAgentSelection(assistant.id);
                    }
                  }}
                >
                  {assistant.name}
                </li>
              ))
            ) : (
              <li className="list-item-mobile">No hay agentes disponibles</li>
            )}
          </ul>
        </div>
      ) : isCrmPage ? (
        <div className="wide-sidebar-mobile-content">
          <h2>Panel de seguimiento</h2>
          <div className="search-container">
            <input
              type="text"
              className="search-input-mobile"
              placeholder="Buscar por nombre o teléfono"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
      ) : isAnalyticsPage ? (
        <div className="wide-sidebar-mobile-content">
          <h2>Próximamente</h2>
        </div>
      ) : isProfilePage ? (
        <div className="wide-sidebar-mobile-content">
          <h2>Perfil</h2>
          <ul className="scrollable-list">
            <li
              className="list-item-mobile"
              onClick={() => {
                document.getElementById('conversations-counter')
                  ?.scrollIntoView({ behavior: 'smooth' });
                onClose();
              }}
            >
              Conversaciones
            </li>
            <li
              className="list-item-mobile"
              onClick={() => {
                document.getElementById('subscription-counter')
                  ?.scrollIntoView({ behavior: 'smooth' });
                onClose();
              }}
            >
              Suscripción
            </li>
            <li
              className="list-item-mobile"
              onClick={() => {
                document.getElementById('add-member-button')
                  ?.scrollIntoView({ behavior: 'smooth' });
                onClose();
              }}
            >
              Equipo
            </li>
          </ul>
        </div>
      ) : (
        <div className="wide-sidebar-mobile-content">
          <h2>Comenzar The Thing</h2>
          <ul className="scrollable-list">
            <li
              className="list-item-mobile"
              onClick={() => scrollToSection('misagentes')}
            >
              MIS AGENTES
            </li>
            <li
              className="list-item-mobile"
              onClick={() => scrollToSection('basesdedatos')}
            >
              MIS BASES DE DATOS
            </li>
            <li
              className="list-item-mobile"
              onClick={() => scrollToSection('tutorial-section')}
            >
              TUTORIAL
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}

export default WideSidebarMobile;
