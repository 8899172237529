import React, { useState, useEffect, useRef, useContext, useMemo } from 'react';
import './CRM.css';
import { db } from '../firebase';
import { useAuth } from '../context/AuthContext';
import { getAllAssistants } from '../services/openaiService';
import {
  collection,
  query,
  onSnapshot,
  doc,
  getDoc,
  updateDoc,
  getDocs,
  where,
  runTransaction,
  setDoc,
  deleteDoc
} from 'firebase/firestore';

import WhatsAppIcon from '../assets/WhatsApp_icon.png';
import webChatIcon from '../assets/web-chat-icon.png';
import plusIcon from '../assets/+.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrashAlt,
  faPlus,
  faTasks,
  faComment,
  faEdit,
  faArrowUpRightFromSquare,
  faCaretDown,
  faList
} from '@fortawesome/free-solid-svg-icons';

import { SearchContext } from '../context/SearchContext';
import { useNavigate } from 'react-router-dom';
import WideSidebar from './WideSidebar';

import * as XLSX from 'xlsx';


// ============================
//  Predefined columns
// ============================
const predefinedColumns = [
  'Leads',
  'Interesados',
  'Spam',
  'No viables',
  'Descartados',
  'Contacto inicial',
  'Ganados',
  'Perdidos',
  'Cerrados',
  'Consulta agendada',
  'Propuesta enviada',
];

// Etiquetas disponibles
const availableLabels = [
  { name: 'Urgente', color: '#EF3F35' },
  { name: 'Prioritario', color: '#E8E870' },
  { name: 'Cliente', color: '#5FD3FF' },
  { name: 'Seguimiento', color: '#51ED48' },
];

// Helper para calcular total de "importe"
const calculateColumnTotal = (threads, threadsMap) => {
  return threads.reduce((total, threadRef) => {
    const threadKey = `${threadRef.assistantId}_${threadRef.threadId}`;
    const fullThread = threadsMap.get(threadKey) || threadRef;
    if (!fullThread.importe) return total;

    const numbers = fullThread.importe.replace(/[^\d]/g, '');
    const importeNum = parseInt(numbers);
    return isNaN(importeNum) ? total : total + importeNum;
  }, 0);
};

const CRM = ({ isWideSidebarVisible }) => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  // =============================
  // Estados generales del CRM
  // =============================
  const [assistants, setAssistants] = useState([]);
  const [threads, setThreads] = useState([]);
  const [columns, setColumns] = useState([]);

  // Modal para crear columna
  const [showCreateColumnModal, setShowCreateColumnModal] = useState(false);
  const [selectedPredefinedColumn, setSelectedPredefinedColumn] = useState(null);
  const [customColumnName, setCustomColumnName] = useState('');

  const [loading, setLoading] = useState(true);
  const [snapshotsReceived, setSnapshotsReceived] = useState(0);
  const [teamMembers, setTeamMembers] = useState([]);
  const [importe, setImporte] = useState('');

  // Para obtener el nombre del usuario desde DB
  const [userDisplayNameFromDB, setUserDisplayNameFromDB] = useState('usuario');

  // Para evitar actualizaciones conflictivas
  const isUpdatingRef = useRef(false);

  // Búsqueda (del contexto)
  const { searchTerm } = useContext(SearchContext);

  // Drag & Drop
  const dragItemRef = useRef(null);
  const isOperationInProgressRef = useRef(false);

  // Modal Thread (edición de un thread existente)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedThread, setSelectedThread] = useState(null);
  const [selectedLabels, setSelectedLabels] = useState([]);
  const [description, setDescription] = useState('');
  const [tasks, setTasks] = useState([]);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [isLabelDropdownOpen, setIsLabelDropdownOpen] = useState(false);
  const labelDropdownRef = useRef(null);
  const [newTaskDescription, setNewTaskDescription] = useState('');

  // Filtros
  const [selectedAssistantId, setSelectedAssistantId] = useState('all');
  const [isOrderDropdownOpen, setIsOrderDropdownOpen] = useState(false);
  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('');
  const [telefono, setTelefono] = useState('');

  // Advertencia de operaciones rápidas
  const [tooFastWarningVisible, setTooFastWarningVisible] = useState(false);

  // Drag & Drop extra
  const [draggingThread, setDraggingThread] = useState(null);
  const [dragOverThread, setDragOverThread] = useState(null);
  const [savingThreads, setSavingThreads] = useState(new Map());
  const [dragOverColumnId, setDragOverColumnId] = useState(null);

  // Limitar frecuencia
  const [lastOperationTime, setLastOperationTime] = useState(0);
  const [operationCount, setOperationCount] = useState(0);
  const OPERATION_COOLDOWN = 2000;
  const MAX_OPERATIONS = 5;
  const OPERATION_WINDOW = 10000;

  // Agregar estos estados
  const [userAccessCode, setUserAccessCode] = useState(null);
  const [mktUsers, setMktUsers] = useState([]);

  // =========================================================================
  //   useEffect para obtener el "name" real del usuario desde Firestore
  // =========================================================================
  useEffect(() => {
    if (!currentUser) return;
    
    const fetchUserDataAndMktUsers = async () => {
      try {
        // 1. Obtener datos del usuario actual
        const userRef = doc(db, 'users', currentUser.uid);
        const userSnap = await getDoc(userRef);
        
        if (userSnap.exists()) {
          const userData = userSnap.data();
          console.log('User Data:', userData); // Debug
          setUserDisplayNameFromDB(userData.name || 'usuario');
          setUserAccessCode(userData.accessCode);

          // 2. Si el usuario es MKT, obtener todos los usuarios MKT
          if (userData.accessCode === 'MKT') {
            const mktQuery = query(
              collection(db, 'users'),
              where('accessCode', '==', 'MKT')
            );
            const mktSnap = await getDocs(mktQuery);
            const mktUserIds = mktSnap.docs.map(doc => doc.id);
            console.log('MKT Users encontrados:', mktUserIds); // Debug
            setMktUsers(mktUserIds);
          }
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserDataAndMktUsers();
  }, [currentUser]);

  // 1) Calcular authorizedUsers (tanto en predefinida como en custom)
const getAuthorizedUsers = () => {
  // Por defecto, solo el usuario actual
  let authorizedUsers = [currentUser.uid];

  // Si el usuario tiene accessCode === 'MKT', incluimos todos los mktUsers + currentUser
  if (userAccessCode === 'MKT' && mktUsers.length > 0) {
    authorizedUsers = Array.from(new Set([...mktUsers, currentUser.uid]));
  }

  return authorizedUsers;
};


  // =======================
  // 1) Cargar Asistentes
  // =======================
  useEffect(() => {
    if (!currentUser) return;

    const fetchAssistants = async () => {
      try {
        const allAssistantsData = await getAllAssistants(currentUser.uid);
        const validAssistants = [];

        for (const assistant of allAssistantsData) {
          const assistantDocRef = doc(db, 'assistants', assistant.id);
          const assistantDocSnap = await getDoc(assistantDocRef);
          const assistantData = assistantDocSnap.data();

          // Solo si 'allowedUsers' contiene currentUser.uid
          if (assistantData?.allowedUsers?.includes(currentUser.uid)) {
            validAssistants.push({
              ...assistant,
              name: assistantData?.name || 'Asistente sin nombre',
            });
          }
        }

        setAssistants(validAssistants);
        if (validAssistants.length === 0) setLoading(false);
      } catch (error) {
        console.error('Error fetchAssistants:', error);
      }
    };

    fetchAssistants();
  }, [currentUser]);

  // ==========================================
  // 2) Suscripción a la colección "crm" del user
  // ==========================================
  useEffect(() => {
    if (!currentUser) return;

    const crmQuery = query(
      collection(db, 'crm'),
      where('authorizedUsers', 'array-contains', currentUser.uid)
    );

    const unsubscribe = onSnapshot(crmQuery, (snapshot) => {
      const newColumns = snapshot.docs.map(doc => {
        const data = doc.data();
        // Limpiar messageHistory de los threads
        const cleanThreads = (data.threads || []).map(thread => {
          const { messageHistory, ...cleanThread } = thread;
          return cleanThread;
        });

        return {
          id: doc.id,
          ...data,
          threads: cleanThreads
        };
      });

      setColumns(newColumns);
    });

    return () => unsubscribe();
  }, [currentUser]);

  // =============================================================
  // 3) Suscripción a threads de cada asistente (solo lectura)
  // =============================================================
  useEffect(() => {
    if (!assistants || assistants.length === 0) return;
    let mounted = true;

    const unsubscribes = assistants.map((assistant) => {
      const ref = collection(db, `threads_${assistant.id}`);
      const q = query(ref);

      return onSnapshot(q, (querySnapshot) => {
        if (!mounted) return;

        const newThreads = [];
        querySnapshot.forEach((docSnap) => {
          newThreads.push({
            ...docSnap.data(),
            threadId: docSnap.id,
            assistantId: assistant.id,
          });
        });

        setThreads((prev) => {
          // Quitamos los threads del assistant actual
          const filteredPrev = prev.filter((t) => t.assistantId !== assistant.id);

          const newStr = JSON.stringify(newThreads);
          const prevStr = JSON.stringify(filteredPrev);
          if (newStr === prevStr) return prev;

          return [...filteredPrev, ...newThreads];
        });

        if (mounted) {
          setSnapshotsReceived((prev) => {
            const newCount = prev + 1;
            if (newCount === assistants.length) {
              setLoading(false);
            }
            return newCount;
          });
        }
      });
    });

    return () => {
      mounted = false;
      unsubscribes.forEach((u) => u());
    };
  }, [assistants]);

  // =============================================================
  //   Cargar miembros del equipo y usuarios MKT (opcional)
  // =============================================================
  useEffect(() => {
    if (!currentUser) return;
    const fetchTeamMembersAndMktUsers = async () => {
      try {
        // 1. Obtener datos del usuario actual
        const userRef = doc(db, 'users', currentUser.uid);
        const userDocSnap = await getDoc(userRef);
        if (!userDocSnap.exists()) return;

        const userData = userDocSnap.data();
        const equipoMembers = userData.equipo || [];
        console.log('Miembros del Equipo Cargados:', equipoMembers); // Depuración

        // 2. Obtener usuarios MKT si el usuario actual tiene accessCode 'MKT'
        let mktMembers = [];
        if (userData.accessCode === 'MKT' && mktUsers.length > 0) {
          // Crear referencias para todos los usuarios MKT
          const mktUserRefs = mktUsers.map((uid) => doc(db, 'users', uid));
          // Obtener documentos de usuarios MKT
          const mktUserPromises = mktUserRefs.map((ref) => getDoc(ref));
          const mktUserSnapshots = await Promise.all(mktUserPromises);
          // Extraer datos de usuarios MKT
          mktMembers = mktUserSnapshots
            .filter((snap) => snap.exists())
            .map((snap) => {
              const data = snap.data();
              console.log(`Datos de MKT User ${snap.id}:`, data); // Depuración

              if (data.nombre && data.apellido) {
                // Si tiene 'nombre' y 'apellido'
                return {
                  nombre: data.nombre,
                  apellido: data.apellido,
                  cargo: data.cargo || '',
                };
              } else if (data.name) {
                // Si solo tiene 'name', parsear para obtener 'nombre' y 'apellido'
                const nameParts = data.name.trim().split(' ');
                const nombre = nameParts[0];
                const apellido = nameParts.slice(1).join(' ') || '';
                return {
                  nombre,
                  apellido,
                  cargo: data.cargo || '',
                };
              } else {
                // Si no tiene ninguno, retornar valores vacíos
                return {
                  nombre: '',
                  apellido: '',
                  cargo: '',
                };
              }
            });
          console.log('Miembros MKT Cargados:', mktMembers); // Depuración
        }

        // 3. Combinar ambos arrays
        const combinedMembers = [...equipoMembers, ...mktMembers];
        setTeamMembers(combinedMembers);
      } catch (error) {
        console.error('Error al cargar miembros del equipo y MKT:', error);
      }
    };
    fetchTeamMembersAndMktUsers();
  }, [currentUser, mktUsers]);

  // Cerrar dropdowns al hacer clic fuera
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        labelDropdownRef.current &&
        !labelDropdownRef.current.contains(event.target)
      ) {
        setIsLabelDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const orderFilterRef = useRef(null);
  useEffect(() => {
    const handleClickOutsideOrder = (event) => {
      if (
        orderFilterRef.current &&
        !orderFilterRef.current.contains(event.target)
      ) {
        setIsOrderDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutsideOrder);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideOrder);
    };
  }, []);

  // Ocultar la advertencia "tooFast"
  useEffect(() => {
    if (tooFastWarningVisible) {
      const timer = setTimeout(() => {
        setTooFastWarningVisible(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [tooFastWarningVisible]);

  // =========================================================
  // Funciones de Formateo y Memorización (Memos)
  // =========================================================
  const formatDate = (dateString) => {
    if (!dateString) return 'Fecha desconocida';
    const options = { year: '2-digit', month: 'numeric', day: '2-digit' };
    return new Date(dateString).toLocaleString('es-ES', options);
  };

  const getPastelColor = (text) => {
    let hash = 0;
    for (let i = 0; i < text.length; i++) {
      hash = text.charCodeAt(i) + ((hash << 5) - hash);
      hash &= hash;
    }
    const hue = Math.abs(hash) % 360;
    const saturation = 40 + (Math.abs(hash) % 21);
    const lightness = 80 + (Math.abs(hash) % 6);

    const c = (1 - Math.abs((2 * lightness) / 100 - 1)) * (saturation / 100);
    const x = c * (1 - Math.abs(((hue / 60) % 2) - 1));
    const m = lightness / 100 - c / 2;
    let r, g, b;
    if (hue < 60) {
      r = c; g = x; b = 0;
    } else if (hue < 120) {
      r = x; g = c; b = 0;
    } else if (hue < 180) {
      r = 0; g = c; b = x;
    } else if (hue < 240) {
      r = 0; g = x; b = c;
    } else if (hue < 300) {
      r = x; g = 0; b = c;
    } else {
      r = c; g = 0; b = x;
    }
    const R = Math.round((r + m) * 255);
    const G = Math.round((g + m) * 255);
    const B = Math.round((b + m) * 255);

    return `rgba(${R}, ${G}, ${B}, 0.7)`;
  };

  const getInitials = (name) => {
    if (!name) return '??';
    const parts = name.trim().split(' ');
    if (parts.length === 1) return parts[0].charAt(0).toUpperCase();
    return (
      parts[0].charAt(0).toUpperCase() +
      parts[parts.length - 1].charAt(0).toUpperCase()
    );
  };

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return 'Website';
    const countryCode = phoneNumber.slice(0, 2);
    const areaCode = phoneNumber.slice(2, 3);
    const cityCode = phoneNumber.slice(3, 5);
    const firstPart = phoneNumber.slice(5, 9);
    const secondPart = phoneNumber.slice(9);
    return `+${countryCode} ${areaCode} ${cityCode} ${firstPart} ${secondPart}`;
  };

  const formatNumber = (value) => {
    const numbers = value.replace(/[^\d]/g, '');
    return numbers
      ? parseInt(numbers).toLocaleString('es-AR').replace(/,/g, '.')
      : '';
  };

  // =========================================================
  //   Columnas reales y la Columna Virtual "Destacados"
  // =========================================================
  const filteredColumns = useMemo(() => {
    if (!searchTerm.trim() && selectedAssistantId === 'all') {
      return columns;
    }
    const term = searchTerm.toLowerCase();
    return columns
      .map((col) => {
        let filteredThreads = col.threads || [];

        if (selectedAssistantId !== 'all') {
          if (selectedAssistantId.startsWith('member_')) {
            const memberName = selectedAssistantId.replace('member_', '');
            filteredThreads = filteredThreads.filter((t) => {
              const ownerName = t.conversationowner?.trim().toLowerCase() || '';
              return ownerName.includes(memberName.toLowerCase());
            });
          } else {
            filteredThreads = filteredThreads.filter(
              (t) => t.assistantId === selectedAssistantId
            );
          }
        }

        if (searchTerm.trim()) {
          filteredThreads = filteredThreads.filter(
            (t) =>
              (t.senderName && t.senderName.toLowerCase().includes(term)) ||
              (t.phoneNumber && t.phoneNumber.toLowerCase().includes(term))
          );
        }

        return { ...col, threads: filteredThreads };
      })
      .filter((c) => c.threads.length > 0);
  }, [columns, searchTerm, selectedAssistantId]);

  // Columna virtual "Destacados"
  const destacadosColumn = useMemo(() => {
    // Filtramos los hilos marcados como favoritos
    let favoriteThreads = threads.filter((t) => t.isFavorite);
  
    if (selectedAssistantId !== 'all') {
      if (selectedAssistantId.startsWith('member_')) {
        const memberName = selectedAssistantId.replace('member_', '');
        favoriteThreads = favoriteThreads.filter((t) => {
          const ownerName = t.conversationowner?.trim().toLowerCase() || '';
          return ownerName.includes(memberName.toLowerCase());
        });
      } else {
        favoriteThreads = favoriteThreads.filter(
          (t) => t.assistantId === selectedAssistantId
        );
      }
    }
  
    // Ordenamos por fecha: más recientes arriba
    const sortedFavoriteThreads = [...favoriteThreads].sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );
  
    return {
      id: 'destacados',
      name: 'Destacados',
      threads: sortedFavoriteThreads.map((t) => ({
        ...t,
        senderName: t.senderName || 'Chat Web',
        phoneNumber: t.phoneNumber || '',
      })),
    };
  }, [threads, selectedAssistantId]);
  
  // Unir "destacados" y columns
  const allColumnsToRender = useMemo(() => {
    const fullDestacadosColumn = { ...destacadosColumn };
    const otherCols = [...filteredColumns];
    return [fullDestacadosColumn, ...otherCols];
  }, [destacadosColumn, filteredColumns]);

  // Mapa de threads
  const threadsMap = useMemo(() => {
    const map = new Map();
    threads.forEach((thread) => {
      const key = `${thread.assistantId}_${thread.threadId}`;
      map.set(key, thread);
    });
    return map;
  }, [threads]);

  // ======================================================
  //   Crear Columna (predefinida o custom)
  // ======================================================
  const handleCreateColumnClick = () => {
    setShowCreateColumnModal(true);
  };

  // ============================
// Crear columna predefinida
// ============================
const handleAddPredefinedColumn = async (columnName) => {
  if (!currentUser) return;

  try {
    const colId = columnName.toLowerCase().replace(/\s+/g, '_');
    const userNameNormalized = userDisplayNameFromDB.toLowerCase().replace(/\s+/g, '_');
    const docId = `${colId}_${userNameNormalized}`;

    // Obtenemos la lista de usuarios autorizados
    const authorizedUsers = getAuthorizedUsers();

    // Armamos el objeto de datos, omitiendo `accessCode` si está undefined
    const columnData = {
      name: columnName,
      createdBy: currentUser.uid,
      authorizedUsers,
      isShared: userAccessCode === 'MKT',
      threads: [],
      lastUpdatedBy: currentUser.uid,
      createdAt: new Date().toISOString(),
      ...(userAccessCode ? { accessCode: userAccessCode } : {}),  // Solo lo agregamos si NO es undefined
    };

    await setDoc(doc(db, 'crm', docId), columnData);

    setShowCreateColumnModal(false);
    setSelectedPredefinedColumn(null);
  } catch (error) {
    console.error('Error al crear columna:', error);
    alert('Error al crear la columna');
  }
};

// =============================
// Crear columna personalizada
// =============================
const handleAddCustomColumn = async () => {
  if (!customColumnName.trim() || !currentUser) return;

  try {
    const colId = customColumnName.toLowerCase().replace(/\s+/g, '_');
    const userNameNormalized = userDisplayNameFromDB.toLowerCase().replace(/\s+/g, '_');
    const docId = `${colId}_${userNameNormalized}`;

    // Igual que antes: obtener usuarios autorizados
    const authorizedUsers = getAuthorizedUsers();

    const columnData = {
      name: customColumnName.trim(),
      createdBy: currentUser.uid,
      authorizedUsers,
      isShared: userAccessCode === 'MKT',
      threads: [],
      lastUpdatedBy: currentUser.uid,
      createdAt: new Date().toISOString(),
      ...(userAccessCode ? { accessCode: userAccessCode } : {}),
    };

    await setDoc(doc(db, 'crm', docId), columnData);

    setShowCreateColumnModal(false);
    setSelectedPredefinedColumn(null);
    setCustomColumnName('');
  } catch (error) {
    console.error('Error al crear columna:', error);
    alert('Error al crear la columna');
  }
};


  // =========================================================
  //    Editar y Eliminar Columna
  // =========================================================
  const [showEditColumnModal, setShowEditColumnModal] = useState(false);
  const [columnToEdit, setColumnToEdit] = useState(null);
  const [editColumnName, setEditColumnName] = useState('');

  const handleColumnHeaderClick = (col) => {
    // No editamos "destacados"
    if (col.id === 'destacados') return;
    setColumnToEdit(col);
    setEditColumnName(col.name);
    setShowEditColumnModal(true);
  };

  const handleColumnNameChange = async () => {
    if (!editColumnName.trim() || !columnToEdit || !currentUser) return;
    try {
      isUpdatingRef.current = true;

      const colRef = doc(db, 'crm', columnToEdit.id);
      await updateDoc(colRef, {
        name: editColumnName.trim(),
        lastUpdatedBy: currentUser.uid,
      });

      setShowEditColumnModal(false);
      setColumnToEdit(null);
    } catch (error) {
      console.error('Error renombrando columna:', error);
      alert('No se pudo renombrar la columna');
    } finally {
      isUpdatingRef.current = false;
    }
  };

  const handleDeleteColumn = async () => {
    if (!columnToEdit || !currentUser) return;
    const confirmDel = window.confirm('¿Seguro quieres eliminar esta columna?');
    if (!confirmDel) return;

    try {
      isUpdatingRef.current = true;
      const colRef = doc(db, 'crm', columnToEdit.id);
      await deleteDoc(colRef);

      setShowEditColumnModal(false);
      setColumnToEdit(null);
    } catch (error) {
      console.error('Error al eliminar la columna:', error);
      alert('No se pudo eliminar la columna');
    } finally {
      isUpdatingRef.current = false;
    }
  };

  // =====================================================
  //  Eliminar Thread de una columna
  // =====================================================
  const handleDeleteThreadFromColumn = async (thread, columnId) => {
    if (!currentUser) return;

    const confirmDelete = window.confirm(
      `¿Eliminar el chat "${thread.senderName}" de esta columna"?`
    );
    if (!confirmDelete) return;

    try {
      isUpdatingRef.current = true;

      const colRef = doc(db, 'crm', columnId);
      await runTransaction(db, async (transaction) => {
        const snap = await transaction.get(colRef);
        if (!snap.exists()) return;

        let thrArr = snap.data().threads || [];
        thrArr = thrArr.filter((t) => t.threadId !== thread.threadId);

        transaction.update(colRef, {
          threads: thrArr,
          lastUpdatedBy: currentUser.uid,
        });
      });

      // Si es "destacados", marcamos isFavorite = false
      if (columnId === 'destacados') {
        const thrRef = doc(db, `threads_${thread.assistantId}`, thread.threadId);
        await updateDoc(thrRef, { isFavorite: false });
      }
    } catch (error) {
      console.error('Error al quitar thread:', error);
      alert('Error al quitar thread');
    } finally {
      isUpdatingRef.current = false;
    }
  };

  // =========================================================
  //   Drag & Drop
  // =========================================================
  const canPerformOperation = () => {
    const now = Date.now();
    if (now - lastOperationTime < OPERATION_COOLDOWN) {
      setTooFastWarningVisible(true);
      return false;
    }
    if (operationCount >= MAX_OPERATIONS) {
      alert('Demasiadas operaciones en poco tiempo. Espera un momento.');
      return false;
    }
    return true;
  };

  const registerOperation = () => {
    const now = Date.now();
    setLastOperationTime(now);
    setOperationCount((prev) => prev + 1);
    setTimeout(() => {
      setOperationCount((prev) => Math.max(0, prev - 1));
    }, OPERATION_WINDOW);
  };

  const handleDragStart = (e, thread, fromColumnId, index) => {
    dragItemRef.current = { thread, fromColumnId, index };
    setDraggingThread({ thread, fromColumnId, index });
    e.dataTransfer.effectAllowed = 'move';
  };

  const handleDragEnter = (e, targetThread, targetColumnId, targetIndex) => {
    e.preventDefault();
    if (!draggingThread) return;
    if (
      draggingThread.thread.threadId !== targetThread.threadId ||
      draggingThread.fromColumnId !== targetColumnId
    ) {
      setDragOverThread({ targetThread, targetColumnId, targetIndex });
    }
  };

  const handleColumnDragEnter = (e, columnId) => {
    e.preventDefault();
    if (draggingThread && draggingThread.fromColumnId !== columnId) {
      setDragOverColumnId(columnId);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleDrop = async (e, toColumnId) => {
    e.preventDefault();
    if (!canPerformOperation()) return;
    registerOperation();

    const dragged = draggingThread;
    setDraggingThread(null);
    setDragOverThread(null);
    setDragOverColumnId(null);

    if (!dragged || !toColumnId || !currentUser) return;

    const { thread, fromColumnId } = dragged;
    console.log(`handleDrop => mover thread ${thread.threadId} de "${fromColumnId}" a "${toColumnId}"`);

    // 1) Actualiza local
    setColumns((prevCols) => {
      const newCols = JSON.parse(JSON.stringify(prevCols));

      const fromIdx = newCols.findIndex((c) => c.id === fromColumnId);
      if (fromIdx >= 0) {
        newCols[fromIdx].threads = newCols[fromIdx].threads.filter(
          (t) => t.threadId !== thread.threadId
        );
      }

      const toIdx = newCols.findIndex((c) => c.id === toColumnId);
      if (toIdx >= 0) {
        newCols[toIdx].threads = newCols[toIdx].threads.filter(
          (t) => t.threadId !== thread.threadId
        );
        if (dragOverThread) {
          const targetIndex = newCols[toIdx].threads.findIndex(
            (t) => t.threadId === dragOverThread.targetThread.threadId
          );
          if (targetIndex !== -1) {
            newCols[toIdx].threads.splice(targetIndex, 0, thread);
          } else {
            newCols[toIdx].threads.push(thread);
          }
        } else {
          newCols[toIdx].threads.push(thread);
        }
      }
      return newCols;
    });

    setSavingThreads((prev) => {
      const newMap = new Map(prev);
      newMap.set(thread.threadId, Date.now());
      return newMap;
    });

    // 2) Actualizar en Firestore
    isUpdatingRef.current = true;
    try {
      if (toColumnId === 'destacados') {
        // Mover a "destacados" => isFavorite = true
        const thrRef = doc(db, `threads_${thread.assistantId}`, thread.threadId);
        await updateDoc(thrRef, { isFavorite: true });

        // Quitar del "from" si no es 'destacados'
        if (fromColumnId !== 'destacados') {
          const fromColRef = doc(db, 'crm', fromColumnId);
          const fromSnap = await getDoc(fromColRef);
          if (fromSnap.exists()) {
            let ft = fromSnap.data().threads || [];
            ft = ft.filter((t) => t.threadId !== thread.threadId);
            await updateDoc(fromColRef, {
              threads: ft,
              lastUpdatedBy: currentUser.uid,
            });
          }
        }
      } else {
        // Movimiento normal
        await runTransaction(db, async (transaction) => {
          const toColRef = doc(db, 'crm', toColumnId);
          const toSnap = await transaction.get(toColRef);
          if (!toSnap.exists()) {
            throw new Error('Columna destino no existe');
          }

          let updatedThreads = [...(toSnap.data().threads || [])];
          updatedThreads = updatedThreads.filter((t) => t.threadId !== thread.threadId);

          if (dragOverThread) {
            const targetIndex = updatedThreads.findIndex(
              (t) => t.threadId === dragOverThread.targetThread.threadId
            );
            if (targetIndex !== -1) {
              updatedThreads.splice(targetIndex, 0, thread);
            } else {
              updatedThreads.push(thread);
            }
          } else {
            updatedThreads.push(thread);
          }

          // Quitar de la columna anterior (si no es la misma y no es 'destacados')
          if (fromColumnId !== toColumnId && fromColumnId !== 'destacados') {
            const fromColRef = doc(db, 'crm', fromColumnId);
            const fromSnap = await transaction.get(fromColRef);
            if (fromSnap.exists()) {
              let ft = fromSnap.data().threads || [];
              ft = ft.filter((t) => t.threadId !== thread.threadId);
              transaction.update(fromColRef, {
                threads: ft,
                lastUpdatedBy: currentUser.uid,
              });
            }
          }

          transaction.update(toColRef, {
            threads: updatedThreads,
            lastUpdatedBy: currentUser.uid,
          });

          // Si venía de 'destacados', quitar isFavorite
          if (fromColumnId === 'destacados') {
            const thrRef = doc(db, `threads_${thread.assistantId}`, thread.threadId);
            transaction.update(thrRef, { isFavorite: false });
          }
        });
      }
      await delay(1000);
    } catch (err) {
      console.error('Error en handleDrop:', err);
      alert(`Error moviendo la conversación: ${err.message}`);
    } finally {
      isUpdatingRef.current = false;
      setSavingThreads((prev) => {
        const newMap = new Map(prev);
        newMap.delete(thread.threadId);
        return newMap;
      });
      isOperationInProgressRef.current = false;
    }
  };

  // =========================================================
  //  Modal de Edición de Thread (Tareas, Comentarios, etc.)
  // =========================================================
  const fetchComments = async (thread) => {
    try {
      const thrRef = doc(db, `threads_${thread.assistantId}`, thread.threadId);
      const thrSnap = await getDoc(thrRef);
      const thrData = thrSnap.data();
      setComments(thrData?.comments || []);
    } catch (error) {
      console.error('Error fetchComments:', error);
    }
  };

  const Placeholder = () => <div className="crm-card placeholder" />;

  const openModal = async (thread) => {
    setSelectedThread(thread);
    setSelectedLabels(thread.labels || []);
    setDescription(thread.description || '');
    setTasks(thread.tasks || []);
    setCompany(thread.company || '');
    setEmail(thread.email || '');
    setTelefono(thread.phoneNumber || '');
    setImporte(thread.importe || '');
    await fetchComments(thread);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedThread(null);
    setSelectedLabels([]);
    setDescription('');
    setTasks([]);
    setCompany('');
    setEmail('');
    setTelefono('');
    setNewTaskDescription('');
    setComments([]);
    setNewComment('');
  };

  const toggleLabel = async (label) => {
    if (!selectedThread) return;
    let updated;
    if (selectedLabels.includes(label)) {
      updated = selectedLabels.filter((l) => l !== label);
    } else {
      updated = [...selectedLabels, label];
    }
    setSelectedLabels(updated);

    try {
      const thrRef = doc(db, `threads_${selectedThread.assistantId}`, selectedThread.threadId);
      await updateDoc(thrRef, { labels: updated });
    } catch (error) {
      console.error('Error toggleLabel:', error);
      alert('Error al actualizar etiqueta');
    }
  };

  const addTask = async () => {
    if (!newTaskDescription.trim() || !selectedThread) return;
    const newTask = { description: newTaskDescription.trim(), completed: false };
    const updatedTasks = [...tasks, newTask];
    setTasks(updatedTasks);
    setNewTaskDescription('');
    try {
      const thrRef = doc(db, `threads_${selectedThread.assistantId}`, selectedThread.threadId);
      await updateDoc(thrRef, { tasks: updatedTasks });
    } catch (error) {
      console.error('Error addTask:', error);
      alert('Error al agregar la tarea');
    }
  };

  const toggleTaskCompletion = async (idx) => {
    if (!selectedThread) return;
    const updatedTasks = tasks.map((task, i) => {
      if (i === idx) return { ...task, completed: !task.completed };
      return task;
    });
    setTasks(updatedTasks);
    try {
      const thrRef = doc(db, `threads_${selectedThread.assistantId}`, selectedThread.threadId);
      await updateDoc(thrRef, { tasks: updatedTasks });
    } catch (error) {
      console.error('Error toggleTaskCompletion:', error);
      alert('Error al actualizar la tarea');
    }
  };

  const deleteTask = async (idx) => {
    if (!selectedThread) return;
    const updatedTasks = tasks.filter((_, i) => i !== idx);
    setTasks(updatedTasks);
    try {
      const thrRef = doc(db, `threads_${selectedThread.assistantId}`, selectedThread.threadId);
      await updateDoc(thrRef, { tasks: updatedTasks });
    } catch (error) {
      console.error('Error deleteTask:', error);
      alert('Error al eliminar la tarea');
    }
  };

  const addComment = async () => {
    if (!newComment.trim() || !selectedThread) return;
    const newCommentObj = {
      text: newComment.trim(),
      user: currentUser.displayName || 'Usuario Anónimo',
      createdAt: new Date().toISOString(),
    };
    const updatedComments = [...comments, newCommentObj];
    setComments(updatedComments);

    try {
      const thrRef = doc(db, `threads_${selectedThread.assistantId}`, selectedThread.threadId);
      await updateDoc(thrRef, { comments: updatedComments });
    } catch (error) {
      console.error('Error addComment:', error);
      alert('Error al agregar el comentario');
    }
    setNewComment('');
  };

  const deleteComment = async (idx) => {
    if (!selectedThread) return;
    const updatedComments = comments.filter((_, i) => i !== idx);
    setComments(updatedComments);

    try {
      const thrRef = doc(db, `threads_${selectedThread.assistantId}`, selectedThread.threadId);
      await updateDoc(thrRef, { comments: updatedComments });
    } catch (error) {
      console.error('Error deleteComment:', error);
      alert('Error al eliminar el comentario');
    }
  };

  const saveThreadDetails = async () => {
    if (!selectedThread) return;
  
    try {
      // CASO 1: Thread normal (sí existe un doc en threads_{assistantId})
      if (selectedThread.assistantId !== 'manual_lead') {
        const thrRef = doc(
          db,
          `threads_${selectedThread.assistantId}`,
          selectedThread.threadId
        );
        await updateDoc(thrRef, {
          description: description.trim(),
          company: company.trim(),
          email: email.trim(),
          phoneNumber: telefono.trim(),  // <-- usar "phoneNumber"
          importe: importe.trim(),
        });
      } 
      // CASO 2: Lead manual (NO existe doc en threads_manual_lead)
      else {
        // Recuperamos la columna a la que pertenece este lead
        const columnId = selectedThread.columnId;
        if (!columnId) {
          throw new Error('No se especificó la columna en "selectedThread.columnId"');
        }
  
        const colRef = doc(db, 'crm', columnId);
        await runTransaction(db, async (transaction) => {
          const snap = await transaction.get(colRef);
          if (!snap.exists()) {
            throw new Error('La columna de destino no existe');
          }
  
          let threadsArr = snap.data().threads || [];
  
          // Actualizamos SOLO el que coincide con threadId
          threadsArr = threadsArr.map((t) => {
            if (t.threadId === selectedThread.threadId) {
              return {
                ...t,
                description: description.trim(),
                company: company.trim(),
                email: email.trim(),
                phoneNumber: telefono.trim(), // <--- usa phoneNumber
                importe: importe.trim(),
              };
            }
            return t;
          });
  
          transaction.update(colRef, {
            threads: threadsArr,
            lastUpdatedBy: currentUser.uid,
          });
        });
      }
  
      // Cierra el modal
      closeModal();
    } catch (error) {
      console.error('Error saveThreadDetails:', error);
      alert('Error al guardar los cambios');
    }
  };
  
  // Advertencias
  const OperationWarning = ({ visible, message, type = 'warning' }) => {
    if (!visible) return null;
    return (
      <div className={`operation-warning ${type}`}>
        <div className="warning-icon">⚠</div>
        <div className="warning-message">{message}</div>
      </div>
    );
  };

  const handleMoveThread = async (threadToMove, fromColumnId, toColumnId) => {
    if (isOperationInProgressRef.current) return;
    
    try {
      isOperationInProgressRef.current = true;

      // Remover messageHistory si existe
      const { messageHistory, ...threadWithoutMessages } = threadToMove;

      await runTransaction(db, async (transaction) => {
        const fromColumnRef = doc(db, 'crm', fromColumnId);
        const toColumnRef = doc(db, 'crm', toColumnId);

        const fromColumnDoc = await transaction.get(fromColumnRef);
        const toColumnDoc = await transaction.get(toColumnRef);

        if (!fromColumnDoc.exists() || !toColumnDoc.exists()) {
          throw new Error('Column does not exist');
        }

        const fromThreads = fromColumnDoc.data().threads || [];
        const toThreads = toColumnDoc.data().threads || [];

        const updatedFromThreads = fromThreads.filter(
          t => t.threadId !== threadWithoutMessages.threadId
        );

        const updatedToThreads = [...toThreads, threadWithoutMessages];

        transaction.update(fromColumnRef, { threads: updatedFromThreads });
        transaction.update(toColumnRef, { threads: updatedToThreads });
      });

    } catch (error) {
      console.error('Error moving thread:', error);
    } finally {
      isOperationInProgressRef.current = false;
    }
  };

  const exportToExcel = () => {
    // Crear una nueva hoja de cálculo
    const workbook = XLSX.utils.book_new();
  
    // Preparar los datos a exportar
    const dataToExport = [];
  
    // Encabezados de la tabla
    const headers = [
      'Columna',
      'Asistente',
      'Nombre del Remitente',
      'Número de Teléfono',
      'Empresa',
      'Email',
      'Importe',
      'Propietario de la Conversación',
      'Etiquetas',
      'Comentarios',
      'Tareas',
      'Fecha de Creación'
    ];
  
    dataToExport.push(headers);
  
    // Iterar sobre todas las columnas visibles
    allColumnsToRender.forEach((col) => {
      col.threads.forEach((thread) => {
        const assistantName = assistants.find(a => a.id === thread.assistantId)?.name || 'N/A';
        const row = [
          col.name,
          assistantName,
          thread.senderName || 'Chat Web',
          formatPhoneNumber(thread.phoneNumber),
          thread.company || '',
          thread.email || '',
          thread.importe || '',
          thread.conversationowner || '',
          thread.labels ? thread.labels.join(', ') : '',
          Array.isArray(thread.comments) ? thread.comments.length : 0,
          Array.isArray(thread.tasks) ? thread.tasks.length : 0,
          formatDate(thread.createdAt)
        ];
        dataToExport.push(row);
      });
    });
  
    // Convertir los datos a una hoja de cálculo
    const worksheet = XLSX.utils.aoa_to_sheet(dataToExport);
  
    // Añadir la hoja al libro de trabajo
    XLSX.utils.book_append_sheet(workbook, worksheet, 'CRM_Data');
  
    // Generar el archivo Excel y descargarlo
    XLSX.writeFile(workbook, 'CRM_Data.xlsx');
  };

  // =========================================================
  //     NUEVO: Modal para crear lead manual
  // =========================================================
  const [showManualLeadModal, setShowManualLeadModal] = useState(false);

  // Estados para los campos del lead manual
  const [manualSenderName, setManualSenderName] = useState('');
  const [manualTelefono, setManualTelefono] = useState('');
  const [manualCompany, setManualCompany] = useState('');
  const [manualEmail, setManualEmail] = useState('');
  const [manualImporte, setManualImporte] = useState('');
  const [manualDescription, setManualDescription] = useState('');
  const [manualSelectedLabels, setManualSelectedLabels] = useState([]);
  const [manualTasks, setManualTasks] = useState([]);
  const [manualComments, setManualComments] = useState([]);
  const [newManualTask, setNewManualTask] = useState('');
  const [newManualComment, setNewManualComment] = useState('');
  const [isManualLabelDropdownOpen, setIsManualLabelDropdownOpen] = useState(false);
  
  // Columna a la que se agregará el nuevo lead manual
  const [targetColumnForManual, setTargetColumnForManual] = useState('');

  const openManualLeadModal = () => {
    // Reseteamos campos
    setManualSenderName('');
    setManualTelefono('');
    setManualCompany('');
    setManualEmail('');
    setManualImporte('');
    setManualDescription('');
    setManualSelectedLabels([]);
    setManualTasks([]);
    setManualComments([]);
    setNewManualTask('');
    setNewManualComment('');
    setTargetColumnForManual('');
    setShowManualLeadModal(true);
  };

  const closeManualLeadModal = () => {
    setShowManualLeadModal(false);
  };

  const toggleManualLabel = (label) => {
    let updated;
    if (manualSelectedLabels.includes(label)) {
      updated = manualSelectedLabels.filter((l) => l !== label);
    } else {
      updated = [...manualSelectedLabels, label];
    }
    setManualSelectedLabels(updated);
  };

  const addManualTask = () => {
    if (!newManualTask.trim()) return;
    const newTask = { description: newManualTask.trim(), completed: false };
    const updatedTasks = [...manualTasks, newTask];
    setManualTasks(updatedTasks);
    setNewManualTask('');
  };

  const toggleManualTaskCompletion = (idx) => {
    const updatedTasks = manualTasks.map((task, i) => {
      if (i === idx) return { ...task, completed: !task.completed };
      return task;
    });
    setManualTasks(updatedTasks);
  };

  const deleteManualTask = (idx) => {
    const updatedTasks = manualTasks.filter((_, i) => i !== idx);
    setManualTasks(updatedTasks);
  };

  const addManualComment = () => {
    if (!newManualComment.trim()) return;
    const newCommentObj = {
      text: newManualComment.trim(),
      user: currentUser.displayName || 'Usuario Anónimo',
      createdAt: new Date().toISOString(),
    };
    const updatedComments = [...manualComments, newCommentObj];
    setManualComments(updatedComments);
    setNewManualComment('');
  };

  const deleteManualComment = (idx) => {
    const updatedComments = manualComments.filter((_, i) => i !== idx);
    setManualComments(updatedComments);
  };

  // Crear thread manual y guardarlo en la columna destino
  const saveManualLead = async () => {
    if (!targetColumnForManual) {
      alert('Por favor, selecciona una columna de destino.');
      return;
    }

    try {
      const colRef = doc(db, 'crm', targetColumnForManual);
      // Creamos una especie de thread "manual"
      const newManualThread = {
        threadId: `manual_${Date.now()}`,  // un ID temporal
        assistantId: 'manual_lead',       // para diferenciarlo de un asistente real
        senderName: manualSenderName.trim() || 'Lead Manual',
        phoneNumber: manualTelefono.trim() || '',
        company: manualCompany.trim(),
        email: manualEmail.trim(),
        importe: manualImporte.trim(),
        description: manualDescription.trim(),
        labels: manualSelectedLabels,
        tasks: manualTasks,
        comments: manualComments,
        createdAt: new Date().toISOString(),
        // No conversationowner, no isFavorite
      };

      // Hacemos la transacción para insertar en la columna elegida
      await runTransaction(db, async (transaction) => {
        const snap = await transaction.get(colRef);
        if (!snap.exists()) {
          throw new Error('La columna de destino no existe');
        }
        let thrArr = snap.data().threads || [];
        thrArr.push(newManualThread);

        transaction.update(colRef, {
          threads: thrArr,
          lastUpdatedBy: currentUser.uid,
        });
      });

      closeManualLeadModal();
    } catch (error) {
      console.error('Error creando lead manual:', error);
      alert('Error al crear lead manual');
    }
  };

  // =========================================================
  //            Render principal del componente
  // =========================================================
  return (
    <div className="crm-container">
      {/* Sidebar ancha */}
      <WideSidebar
        isVisible={isWideSidebarVisible}
        selectedAssistantId={selectedAssistantId}
        setSelectedAssistantId={setSelectedAssistantId}
        assistants={assistants}
      />

      {/* Filtro "Ordenar por" */}
      <div className="order-filter" ref={orderFilterRef}>
        <span onClick={() => setIsOrderDropdownOpen(!isOrderDropdownOpen)}>
          Ordenar por <FontAwesomeIcon icon={faCaretDown} className="caret-icon" />
        </span>

        {/* NUEVO: Botón "+" para crear lead manual */}
        <div className="manual-lead-button-container" style={{ marginLeft: '1em' }}>
          <button className="manual-lead-button" onClick={openManualLeadModal}>
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>

        {/* Botón "Exportar a Excel" */}
        <div className="export-excel-container" style={{ marginLeft: '1em' }}>
          <button className="export-excel-button" onClick={exportToExcel}>
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="export-icon" />
            Exportar a Excel
          </button>
        </div>

        {isOrderDropdownOpen && (
          <div className="order-dropdown">
            <div
              onClick={() => {
                setSelectedAssistantId('all');
                setIsOrderDropdownOpen(false);
              }}
            >
              Todos
            </div>
            {assistants.length > 0 && (
              <>
                <div className="dropdown-section-title">Asistentes</div>
                {assistants.map((assistant) => (
                  <div
                    key={assistant.id}
                    onClick={() => {
                      setSelectedAssistantId(assistant.id);
                      setIsOrderDropdownOpen(false);
                    }}
                  >
                    {assistant.name}
                  </div>
                ))}
              </>
            )}
            {teamMembers.length > 0 && (
              <>
                <div className="dropdown-section-title">Miembros del Equipo</div>
                {teamMembers.map((member, i) => {
                  const fullName = `${member.nombre} ${member.apellido}`.trim();
                  const combo = `member_${fullName}`;
                  return (
                    <div
                      key={`member-${i}`}
                      onClick={() => {
                        setSelectedAssistantId(combo);
                        setIsOrderDropdownOpen(false);
                      }}
                    >
                      {fullName}
                    </div>
                  );
                })}
              </>
            )}
          </div>
        )}
      </div>

      {/* Advertencias de "demasiado rápido" */}
      {tooFastWarningVisible && (
        <div className="too-fast-warning">
          Estás realizando cambios muy rápido. Por favor, espera un momento.
        </div>
      )}
      <OperationWarning
        visible={tooFastWarningVisible}
        message="Estás realizando cambios muy rápido. Por favor, espera un momento."
        type="warning"
      />

      {/* Contenedor de Columnas */}
      <div className="crm-columns-container">
        {allColumnsToRender.length > 0 ? (
          allColumnsToRender.map((col) => (
            <div
              className="crm-column"
              key={col.id}
              onDragOver={handleDragOver}
              onDragEnter={(e) => handleColumnDragEnter(e, col.id)}
              onDrop={(e) => handleDrop(e, col.id)}
            >
              <div
                className="crm-column-header"
                onClick={() => col.id !== 'destacados' && handleColumnHeaderClick(col)}
              >
                {col.name}
                <span className="column-count">({col.threads.length})</span>
                <span className="column-total">
                  $
                  {calculateColumnTotal(col.threads, threadsMap)
                    .toLocaleString('es-AR')
                    .replace(/,/g, '.')}
                </span>
              </div>

              <div className="crm-column-content">
                {loading ? (
                  <div className="spinner-container">
                    <div className="spinner"></div>
                  </div>
                ) : (
                  <>
                    {col.threads && col.threads.length > 0 ? (
                      <>
                        {col.threads.map((threadRef, index) => {
                          const threadKey = `${threadRef.assistantId}_${threadRef.threadId}`;
                          const fullThread = threadsMap.get(threadKey) || threadRef;

                          const assistantInfo = assistants.find(
                            (a) => a.id === fullThread.assistantId
                          );

                          const isDragging =
                            draggingThread &&
                            draggingThread.thread.threadId === fullThread.threadId;
                          const isDragOver =
                            dragOverThread &&
                            dragOverThread.targetThread.threadId === fullThread.threadId;

                          return (
                            <React.Fragment key={`${col.id}-${fullThread.threadId}`}>
                              {isDragOver && !isDragging && <Placeholder />}

                              <div
                                className={`crm-card 
                                  ${isDragging ? 'dragging' : ''} 
                                  ${isDragOver ? 'drag-over' : ''} 
                                  ${savingThreads.has(fullThread.threadId) ? 'saving' : ''}`}
                                draggable
                                onDragStart={(e) =>
                                  handleDragStart(e, fullThread, col.id, index)
                                }
                                onDragEnter={(e) =>
                                  handleDragEnter(e, fullThread, col.id, index)
                                }
                                onClick={() => openModal({ ...fullThread, columnId: col.id })}
                                style={{ cursor: 'pointer', position: 'relative' }}
                              >
                                <div className="crm-card-date">
                                  {formatDate(fullThread.createdAt)}
                                </div>

                                <div className="crm-card-inside">
                                  <div className="crm-card-header">
                                    {fullThread.phoneNumber ? (
                                      <div
                                        className="crm-avatar-container"
                                        style={{
                                          backgroundColor: getPastelColor(
                                            fullThread.senderName || 'Chat Web'
                                          ),
                                        }}
                                      >
                                        <span className="crm-avatar-initials">
                                          {getInitials(fullThread.senderName || 'Chat Web')}
                                        </span>
                                        <img
                                          src={WhatsAppIcon}
                                          alt="WhatsApp"
                                          className="crm-whatsapp-icon"
                                        />
                                      </div>
                                    ) : (
                                      <div className="crm-webchat-avatar-container">
                                        <img
                                          src={webChatIcon}
                                          alt="Web Chat Icon"
                                          className="crm-web-chat-icon"
                                        />
                                      </div>
                                    )}
                                  </div>

                                  <div className="crm-card-body">
                                    <h4>{fullThread.senderName || 'Chat Web'}</h4>
                                    {fullThread.company && (
                                      <p className="company-name">{fullThread.company}</p>
                                    )}
                                    {assistantInfo && (
                                      <p
                                        style={{
                                          marginTop: '0.4em',
                                          fontStyle: 'italic',
                                          fontSize: '0.9em',
                                          color: '#ac9ed4',
                                          marginBottom: '0',
                                        }}
                                      >
                                        {assistantInfo.name}
                                      </p>
                                    )}
{fullThread.assistantId && fullThread.conversationowner && (
  <p className="owner-name">
    Tomó el chat: {fullThread.conversationowner}
  </p>
)}

                                    {fullThread.labels && fullThread.labels.length > 0 && (
                                      <div className="label-line">
                                        {fullThread.labels.map((label, idxLabel) => {
                                          const labelObj = availableLabels.find(
                                            (l) => l.name === label
                                          );
                                          return (
                                            <span
                                              key={idxLabel}
                                              className="label-indicator"
                                              style={{
                                                backgroundColor: labelObj
                                                  ? labelObj.color
                                                  : 'gray',
                                              }}
                                              title={label}
                                            />
                                          );
                                        })}
                                      </div>
                                    )}
                                    {fullThread.email && <p>{fullThread.email}</p>}
                                    {fullThread.importe && (
                                      <p className="importe-value">
                                        {fullThread.importe}
                                      </p>
                                    )}
                                  </div>
                                </div>

                                {/* Botón para Eliminar Thread de la columna */}
                                <div
                                  className="delete-thread-button"
                                  onClick={(ev) => {
                                    ev.stopPropagation();
                                    handleDeleteThreadFromColumn(fullThread, col.id);
                                  }}
                                  title="Eliminar chat de la columna"
                                >
                                  <FontAwesomeIcon icon={faTrashAlt} className="trash-icon" />
                                </div>

                                {/* Contador de comentarios */}
                                <div className="comments-counter">
                                  <span className="comments-count">
                                    {Array.isArray(fullThread.comments)
                                      ? fullThread.comments.length
                                      : 0}
                                  </span>
                                  <FontAwesomeIcon icon={faComment} className="comments-icon" />
                                </div>

                                {/* Contador de tareas */}
                                <div className="tasks-counter">
                                  <span className="tasks-count">
                                    {Array.isArray(fullThread.tasks)
                                      ? fullThread.tasks.length
                                      : 0}
                                  </span>
                                  <FontAwesomeIcon icon={faTasks} className="tasks-icon" />
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        })}

                        {/* placeholder final */}
                        {dragOverColumnId === col.id && !dragOverThread && (
                          <Placeholder key={`placeholder-${col.id}-end`} />
                        )}
                      </>
                    ) : (
                      <>
                        <p className="no-threads-text">No hay conversaciones aquí</p>
                        {dragOverColumnId === col.id && (
                          <Placeholder key={`placeholder-${col.id}-empty`} />
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          ))
        ) : (
          <p className="no-threads-text">No se encontraron resultados</p>
        )}

        {/* Botón para crear nueva columna manualmente */}
        <div className="crm-new-column" onClick={handleCreateColumnClick}>
          <img
            src={plusIcon}
            alt="Agregar sección"
            style={{ width: '6.5em', marginTop: '-3em', marginBottom: '0em' }}
          />
          <span>Nueva sección</span>
        </div>
      </div>

      {/* Modal para Crear Columna */}
      {showCreateColumnModal && (
        <div className="crm-modal-backdrop" onClick={() => setShowCreateColumnModal(false)}>
          <div className="crm-modal-column" onClick={(e) => e.stopPropagation()}>
            <h2>Crear Nueva Columna</h2>
            <button
              className="crm-close-modal"
              onClick={() => {
                setShowCreateColumnModal(false);
                setSelectedPredefinedColumn(null);
                setCustomColumnName('');
              }}
            >
              X
            </button>

            <div className="crm-column-options">
              {/* Botón "Seleccionar Predeterminadas" */}
              <button className="crm-preset-button">
                <FontAwesomeIcon icon={faList} className="button-icon" />
                Seleccionar Predeterminadas:
              </button>

              <div className="crm-predefined-list">
                {predefinedColumns.map((colName) => (
                  <button
                    key={colName}
                    className="crm-predefined-item"
                    onClick={() => handleAddPredefinedColumn(colName)}
                  >
                    <FontAwesomeIcon icon={faPlus} className="item-icon" />
                    {colName}
                  </button>
                ))}
              </div>

              <div className="crm-custom-column">
                <input
                  type="text"
                  placeholder="Nombre Personalizado"
                  value={customColumnName || ''}
                  onChange={(e) => setCustomColumnName(e.target.value)}
                  className="crm-custom-input"
                />
                <button onClick={handleAddCustomColumn} className="crm-add-custom-button">
                  Crear Personalizada
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal para Editar/Eliminar Columna */}
      {showEditColumnModal && columnToEdit && (
        <div className="crm-modal-backdrop" onClick={() => setShowEditColumnModal(false)}>
          <div className="crm-modal-column" onClick={(e) => e.stopPropagation()}>
            <h2>Editar Columna</h2>
            <button
              className="crm-close-modal"
              onClick={() => {
                setShowEditColumnModal(false);
                setColumnToEdit(null);
              }}
            >
              X
            </button>

            <div className="crm-column-options">
              <div className="crm-edit-section">
                <label htmlFor="editColumnName">Nuevo Nombre:</label>
                <input
                  type="text"
                  id="editColumnName"
                  placeholder="Nuevo nombre"
                  value={editColumnName}
                  onChange={(e) => setEditColumnName(e.target.value)}
                  className="crm-custom-input"
                />
                <button
                  onClick={handleColumnNameChange}
                  className="crm-rename-button"
                >
                  <FontAwesomeIcon icon={faEdit} className="button-icon" />
                  Guardar cambios
                </button>
              </div>

              <button
                onClick={handleDeleteColumn}
                className="crm-delete-column-button"
              >
                <FontAwesomeIcon icon={faTrashAlt} className="button-icon" />
                Eliminar Columna
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Modal de edición de Thread (tareas, comentarios, etc.) */}
      {isModalOpen && selectedThread && (
        <div className="crm-modal-backdrop" onClick={closeModal}>
          <div className="crm-modal" onClick={(e) => e.stopPropagation()}>
            <button className="crm-close-modal" onClick={closeModal}>
              X
            </button>
            <div className="modal-content-crm">
              {/* Info del Thread */}
              <div className="modal-thread-info">
                {selectedThread.phoneNumber ? (
                  <div
                    className="crm-avatar-container-large"
                    style={{
                      backgroundColor: getPastelColor(
                        selectedThread.senderName || 'Chat Web'
                      ),
                    }}
                  >
                    <span className="crm-avatar-initials-large">
                      {getInitials(selectedThread.senderName || 'Chat Web')}
                    </span>
                    <img
                      src={WhatsAppIcon}
                      alt="WhatsApp"
                      className="crm-whatsapp-icon-large"
                    />
                  </div>
                ) : (
                  <div className="crm-webchat-avatar-container">
                    <img
                      src={webChatIcon}
                      alt="Web Chat Icon"
                      className="crm-web-chat-icon-large"
                    />
                  </div>
                )}
                <div className="thread-details-modal">
                  <h2>{selectedThread.senderName || 'Chat Web'}</h2>
                  <p>{formatPhoneNumber(selectedThread.phoneNumber)}</p>
                </div>
              </div>

              {/* Botón "Ir al chat" */}
             {/* SOLO mostrar "Ir al chat" si NO es un lead manual */}
{selectedThread.assistantId !== 'manual_lead' && (
  <div className="go-to-chat-button-container">
    <button
      className="go-to-chat-button"
      onClick={() => {
        navigate(`/messages/${selectedThread.assistantId}/${selectedThread.threadId}`);
        closeModal();
      }}
    >
      <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="go-to-chat-icon" />
      {' '}
      Ir al chat
    </button>
  </div>
)}

              <div className="modal-labels">
                <h3>Etiquetas:</h3>
                <div
                  className="label-selector"
                  onClick={() => setIsLabelDropdownOpen(!isLabelDropdownOpen)}
                  ref={labelDropdownRef}
                >
                  <div className="selected-labels">
                    {selectedLabels.length > 0 ? (
                      selectedLabels.map((label) => {
                        const labelObj = availableLabels.find((l) => l.name === label);
                        return (
                          <span key={label} className="selected-label">
                            <span
                              className="label-color-box"
                              style={{ backgroundColor: labelObj ? labelObj.color : 'gray' }}
                            />
                            {label}
                          </span>
                        );
                      })
                    ) : (
                      <span className="placeholder">Selecciona etiquetas</span>
                    )}
                  </div>
                  <span className="dropdown-arrow">&#9662;</span>
                </div>
                {isLabelDropdownOpen && (
                  <div
                    className="label-dropdown"
                    onMouseDown={(e) => e.stopPropagation()}
                  >
                    {availableLabels.map((label) => (
                      <label
                        key={label.name}
                        className="dropdown-label-option"
                        onMouseDown={(e) => e.stopPropagation()}
                      >
                        <input
                          type="checkbox"
                          value={label.name}
                          checked={selectedLabels.includes(label.name)}
                          onChange={() => toggleLabel(label.name)}
                          className="custom-checkbox label-selector-checkbox"
                        />
                        <span className="custom-checkbox-display" />
                        <span
                          className="label-color-box"
                          style={{ backgroundColor: label.color }}
                        />
                        {label.name}
                      </label>
                    ))}
                  </div>
                )}
              </div>

              {/* Descripción */}
              <div className="modal-description">
                <h3>Descripción</h3>
                <textarea
                  value={description || ''}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Agrega una descripción..."
                  rows={4}
                />
              </div>

              {/* Campos adicionales */}
              <div className="modal-additional-fields">
                <div className="modal-field">
                  <label htmlFor="company">Empresa:</label>
                  <input
                    type="text"
                    id="company"
                    placeholder="Ingresa la empresa"
                    value={company || ''}
                    onChange={(e) => setCompany(e.target.value)}
                    className="modal-input"
                  />
                </div>
                <div className="modal-field">
                  <label htmlFor="email">Email:</label>
                  <input
                    type="email"
                    id="email"
                    placeholder="Ingresa el email"
                    value={email || ''}
                    onChange={(e) => setEmail(e.target.value)}
                    className="modal-input"
                  />
                </div>
                <div className="modal-field">
                  <label htmlFor="telefono">Teléfono:</label>
                  <input
                    type="text"
                    id="telefono"
                    placeholder="Ingresa el teléfono"
                    value={telefono || ''}
                    onChange={(e) => setTelefono(e.target.value)}
                    className="modal-input"
                  />
                </div>
                <div className="modal-field">
                  <label htmlFor="importe">Valor estimado:</label>
                  <input
                    type="text"
                    id="importe"
                    placeholder="Ingresa el valor"
                    value={importe}
                    onChange={(e) => {
                      const formatted = formatNumber(e.target.value);
                      if (formatted !== 'NaN') {
                        setImporte(`$${formatted}`);
                      }
                    }}
                    onFocus={(e) => {
                      const val = e.target.value.replace(/[$.,]/g, '');
                      setImporte(val);
                    }}
                    onBlur={(e) => {
                      if (e.target.value) {
                        const formatted = formatNumber(e.target.value);
                        setImporte(`$${formatted}`);
                      }
                    }}
                    className="modal-input"
                    inputMode="numeric"
                    pattern="[0-9]*"
                  />
                </div>
              </div>

              {/* Tareas */}
              <div className="modal-tasks">
                <h3>Tareas</h3>
                <ul className="tasks-list">
                  {tasks.map((task, i) => (
                    <li key={i} className="task-item">
                      <label className="task-label">
                        <input
                          type="checkbox"
                          checked={task.completed}
                          onChange={() => toggleTaskCompletion(i)}
                          className="custom-checkbox task-item-checkbox visually-hidden"
                        />
                        <span className="custom-checkbox-display" />
                        <span className={task.completed ? 'task-completed' : ''}>
                          {task.description}
                        </span>
                      </label>
                      <button
                        className="delete-task-button"
                        onClick={() => deleteTask(i)}
                        title="Eliminar tarea"
                      >
                        &times;
                      </button>
                    </li>
                  ))}
                </ul>
                <div className="add-task-container">
                  <input
                    type="text"
                    placeholder="Nueva tarea..."
                    value={newTaskDescription}
                    onChange={(e) => setNewTaskDescription(e.target.value)}
                    className="add-task-input"
                  />
                  <button onClick={addTask} className="add-task-button">
                    <FontAwesomeIcon icon={faPlus} />
                  </button>
                </div>
              </div>

              {/* Comentarios */}
              <div className="modal-comments">
                <h3>Comentarios</h3>
                <ul className="comments-list">
                  {comments.map((c, i) => (
                    <li key={i} className="comment-item">
                      <strong>{c.user}</strong>
                      <p>{c.text}</p>
                      <small>{formatDate(c.createdAt)}</small>
                      <button
                        className="delete-comment-button"
                        onClick={() => deleteComment(i)}
                        title="Eliminar comentario"
                      >
                        &times;
                      </button>
                    </li>
                  ))}
                </ul>
                <div className="add-comment-container">
                  <input
                    type="text"
                    placeholder="Nuevo comentario..."
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                    className="add-comment-input"
                  />
                  <button onClick={addComment} className="add-comment-button">
                    <FontAwesomeIcon icon={faPlus} />
                  </button>
                </div>
              </div>

              {/* Botón Guardar (para campos extra) */}
              <div className="modal-actions">
                <button onClick={saveThreadDetails} className="save-button">
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* =========================
           MODAL LEAD MANUAL
          ========================= */}
      {showManualLeadModal && (
        <div className="crm-modal-backdrop" onClick={closeManualLeadModal}>
          <div className="crm-modal" onClick={(e) => e.stopPropagation()}>
            <button className="crm-close-modal" onClick={closeManualLeadModal}>
              X
            </button>
            <div className="modal-content-crm">
              {/* Info "falsa" para lead manual (sin iconos de WhatsApp ni chat web) */}
              <div className="modal-thread-info">
                <div
                  className="crm-avatar-container-large"
                  style={{ backgroundColor: '#4f4d6b' }}
                >
                  <span className="crm-avatar-initials-large">
                    <FontAwesomeIcon icon={faPlus} />
                  </span>
                  {/* No ícono de WhatsApp ni de Web Chat */}
                </div>
                <div className="thread-details-modal">
                  <h2>{manualSenderName || 'Nuevo Lead Manual'}</h2>
                  <p>{manualTelefono || 'Teléfono...'}</p>
                </div>
              </div>

              {/* SIN botón "Ir al chat" */}
              {/* Etiquetas */}
              <div className="modal-labels">
                <h3>Etiquetas:</h3>
                <div
                  className="label-selector"
                  onClick={() => setIsManualLabelDropdownOpen(!isManualLabelDropdownOpen)}
                >
                  <div className="selected-labels">
                    {manualSelectedLabels.length > 0 ? (
                      manualSelectedLabels.map((label) => {
                        const labelObj = availableLabels.find((l) => l.name === label);
                        return (
                          <span key={label} className="selected-label">
                            <span
                              className="label-color-box"
                              style={{ backgroundColor: labelObj ? labelObj.color : 'gray' }}
                            />
                            {label}
                          </span>
                        );
                      })
                    ) : (
                      <span className="placeholder">Selecciona etiquetas</span>
                    )}
                  </div>
                  <span className="dropdown-arrow">&#9662;</span>
                </div>
                {isManualLabelDropdownOpen && (
                  <div
                    className="label-dropdown"
                    onMouseDown={(e) => e.stopPropagation()}
                  >
                    {availableLabels.map((label) => (
                      <label
                        key={label.name}
                        className="dropdown-label-option"
                        onMouseDown={(e) => e.stopPropagation()}
                      >
                        <input
                          type="checkbox"
                          value={label.name}
                          checked={manualSelectedLabels.includes(label.name)}
                          onChange={() => toggleManualLabel(label.name)}
                          className="custom-checkbox label-selector-checkbox"
                        />
                        <span className="custom-checkbox-display" />
                        <span
                          className="label-color-box"
                          style={{ backgroundColor: label.color }}
                        />
                        {label.name}
                      </label>
                    ))}
                  </div>
                )}
              </div>

              {/* Descripción */}
              <div className="modal-description">
                <h3>Descripción</h3>
                <textarea
                  value={manualDescription}
                  onChange={(e) => setManualDescription(e.target.value)}
                  placeholder="Agrega una descripción..."
                  rows={4}
                />
              </div>

              {/* Campos adicionales */}
              <div className="modal-additional-fields">
                <div className="modal-field">
                  <label>Nombre:</label>
                  <input
                    type="text"
                    placeholder="Nombre lead"
                    value={manualSenderName}
                    onChange={(e) => setManualSenderName(e.target.value)}
                    className="modal-input"
                  />
                </div>
                <div className="modal-field">
                  <label>Teléfono:</label>
                  <input
                    type="text"
                    placeholder="Ingresa el teléfono"
                    value={manualTelefono}
                    onChange={(e) => setManualTelefono(e.target.value)}
                    className="modal-input"
                  />
                </div>
                <div className="modal-field">
                  <label>Empresa:</label>
                  <input
                    type="text"
                    placeholder="Ingresa la empresa"
                    value={manualCompany}
                    onChange={(e) => setManualCompany(e.target.value)}
                    className="modal-input"
                  />
                </div>
                <div className="modal-field">
                  <label>Email:</label>
                  <input
                    type="email"
                    placeholder="Ingresa el email"
                    value={manualEmail}
                    onChange={(e) => setManualEmail(e.target.value)}
                    className="modal-input"
                  />
                </div>
                <div className="modal-field">
                  <label>Valor estimado:</label>
                  <input
                    type="text"
                    placeholder="Ingresa el valor"
                    value={manualImporte}
                    onChange={(e) => {
                      const formatted = formatNumber(e.target.value);
                      if (formatted !== 'NaN') {
                        setManualImporte(`$${formatted}`);
                      }
                    }}
                    onFocus={(e) => {
                      const val = e.target.value.replace(/[$.,]/g, '');
                      setManualImporte(val);
                    }}
                    onBlur={(e) => {
                      if (e.target.value) {
                        const formatted = formatNumber(e.target.value);
                        setManualImporte(`$${formatted}`);
                      }
                    }}
                    className="modal-input"
                    inputMode="numeric"
                    pattern="[0-9]*"
                  />
                </div>
                <div className="modal-field">
                  <label>Seleccionar columna:</label>
                  <select
                    className="modal-input"
                    value={targetColumnForManual}
                    onChange={(e) => setTargetColumnForManual(e.target.value)}
                  >
                    <option value="">-- Elige una columna --</option>
                    {columns.map((col) => (
                      <option key={col.id} value={col.id}>
                        {col.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {/* Tareas */}
              <div className="modal-tasks">
                <h3>Tareas</h3>
                <ul className="tasks-list">
                  {manualTasks.map((task, i) => (
                    <li key={i} className="task-item">
                      <label className="task-label">
                        <input
                          type="checkbox"
                          checked={task.completed}
                          onChange={() => toggleManualTaskCompletion(i)}
                          className="custom-checkbox task-item-checkbox visually-hidden"
                        />
                        <span className="custom-checkbox-display" />
                        <span className={task.completed ? 'task-completed' : ''}>
                          {task.description}
                        </span>
                      </label>
                      <button
                        className="delete-task-button"
                        onClick={() => deleteManualTask(i)}
                        title="Eliminar tarea"
                      >
                        &times;
                      </button>
                    </li>
                  ))}
                </ul>
                <div className="add-task-container">
                  <input
                    type="text"
                    placeholder="Nueva tarea..."
                    value={newManualTask}
                    onChange={(e) => setNewManualTask(e.target.value)}
                    className="add-task-input"
                  />
                  <button onClick={addManualTask} className="add-task-button">
                    <FontAwesomeIcon icon={faPlus} />
                  </button>
                </div>
              </div>

              {/* Comentarios */}
              <div className="modal-comments">
                <h3>Comentarios</h3>
                <ul className="comments-list">
                  {manualComments.map((c, i) => (
                    <li key={i} className="comment-item">
                      <strong>{c.user}</strong>
                      <p>{c.text}</p>
                      <small>{formatDate(c.createdAt)}</small>
                      <button
                        className="delete-comment-button"
                        onClick={() => deleteManualComment(i)}
                        title="Eliminar comentario"
                      >
                        &times;
                      </button>
                    </li>
                  ))}
                </ul>
                <div className="add-comment-container">
                  <input
                    type="text"
                    placeholder="Nuevo comentario..."
                    value={newManualComment}
                    onChange={(e) => setNewManualComment(e.target.value)}
                    className="add-comment-input"
                  />
                  <button onClick={addManualComment} className="add-comment-button">
                    <FontAwesomeIcon icon={faPlus} />
                  </button>
                </div>
              </div>

              {/* Botón Guardar (para campos extra) */}
              <div className="modal-actions">
                <button onClick={saveManualLead} className="save-button">
                  Guardar lead
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CRM;
